import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VehicleForm from "./VehicleForm";

const mapStateToProps = state => ({
  enums: state.enums,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleForm);
