import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  topContainer: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: "32px",
    [theme.breakpoints.down('sm')]: {
      padding: "8px",
    },
  },
  topContainerMobile: {
    // position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: "8px",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },
  topLeftBox: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "#3B3B3B",
    borderRadius: "25px 25px",
    padding: "32px"
  },
  topLeftBoxFixed: {
    width: '100%',
    position: "absolute",
    left: "0px",
    top: "0px",
    zIndex: 1000,
    // color: theme.palette.primary.contrastText,
    // backgroundColor: "#3B3B3B",
    borderRadius: "25px 25px",
    padding: "32px",
  },
  topRightBoxFixed: {
    position: "absolute",
    right: "32px",
    top: "32px",
    zIndex: 1000,
  },
  infectionTypeContainer: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    },
  },
  chip: {
    background: "transparent",
    color: "#fff",
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
    },
    
  },
  infected: {
    color: theme.palette.primary.main + " !important",
  },
  nonInfected: {
    color: theme.palette.secondary.main + " !important",
  },
  officialInfected: {
    color: "#FFD800 !important",
  },
  countTotal: {
    transition: "opacity 2s ease-in",
  }
}));