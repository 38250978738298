import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    height: 100,
    top: 0,
    left: 0,
    padding: 16
  },
  appBarMobile: {
    position: 'fixed',
    height: 150,
    top: 0,
    left: 0,
  },
  logo: {
    height: 50,
    margin: 24,
  },
  logoMobile: {
    height: 50,
    padding: 8,
  },
  navigationLinkContainer: {
    display: 'block',
    float: 'right',
    position: 'absolute',
    right: 0,
  },
  navigationLink: {
    '&:hover':{
      color: '#6a31dc',
      textDecoration: 'none'
    }, 
  },
  active: {
    borderBottom: `1px solid #6a31dc`,
  },
  navigationContainer: {
    maxWidth: 1200,
    margin: `0 auto`,
    position: 'relative',
  },
  title: {
    textTransform: 'uppercase',
  },
  skinnyText: {
    fontWeight: 100,
  }, 
}));