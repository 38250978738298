import React, { } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '48px',
    borderRadius:32
  },
}));

const PaperView = (props) => {
  const {children} = props;
  const classes = useStyles();
  
  return (
    <Paper className={classes.paper} square={false}>
      {children}
    </Paper>
  )
}

export default PaperView;

