import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import Business from '@material-ui/icons/Business';
import AirlineSeatReclineExtra from '@material-ui/icons/AirlineSeatReclineExtra';
import DriveEta from '@material-ui/icons/DriveEta';
import Room from '@material-ui/icons/Room';

import AuthService from 'services/http/AuthService'

import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonDefault from 'components/Buttons/ButtonDefault'
import BoxInfo from 'components/BoxInfo'
import CompanyInfo from 'components/CompanyInfo/CompanyInfo'
import appStoreLogo from "~/assets/app_store.png";
import googlePlayLogo from "~/assets/google_play.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    padding: 64,
    paddingBottom: 32,
    color: theme.palette.text.light,
  },
  titleContainer: {
    paddingBottom: 128,
  },
  subtitleContainer: {
    paddingLeft: 32,
  },
  countsContainer: {
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 96,
    color: theme.palette.text.light,
  },
}));

const Start = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  const {myAnalytics, users, myCompany} = props;
  
  const getCompanyStart = () => {
    return (
      <Fragment>
        <div className={classes.root}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item>
            <Typography variant="h2">{t('Tervetuloa OpenTaxi palveluun!')}</Typography>
          </Grid>
          
          
        </Grid>
        
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.subtitleContainer}
        >
          <Grid item>
            <Typography variant="h4">{t('Pikalinkit suosikki toimintoihin')}</Typography>
          </Grid>
        
        </Grid>
        
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={6} lg={3} style={{ padding: 20 }}>
            <BoxInfo icon={<Business />} text={t('Tarkasta ja täydennä yrityksesi tiedot')} link={"/dashboard/company/edit"} status={myCompany?.valid == true ? 1 : 2} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} style={{ padding: 20 }}>
            <BoxInfo icon={<AirlineSeatReclineExtra />} text={t('Hallitse kuljettajia')} link={"/dashboard/drivers"} status={myAnalytics?.drivers > 0 ? 1 : 2} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} style={{ padding: 20 }}>
            <BoxInfo icon={<DriveEta />} text={t('Hallitse ajoneuvoja')} link={"/dashboard/vehicles"} status={myAnalytics?.vehicles > 0 ? 1 : 2} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} style={{ padding: 20 }}>
            <BoxInfo icon={<Room />} text={t('Ajohistoria')} link={"/dashboard/orders/list"} status={0} />
          </Grid>
        
        </Grid>

        </div>

        <Grid
          className={classes.countsContainer}
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <Typography variant="h6" align="center">{t('Yritysprofiili avattu')}</Typography>
            <Typography variant="h2" align="center">{myCompany?.analytics?.company_info_opened_count?.count} {t('kertaa')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">{t('Hiiltä kompensoitu')}</Typography>
            <Typography variant="h2" align="center">{myCompany?.analytics?.coal_compensated?.count ? myCompany?.analytics?.coal_compensated?.count : 0}t</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">{t('Ajoa suoritettu')}</Typography>
            <Typography variant="h2" align="center">{myCompany?.analytics?.drive_count?.count ? myCompany?.analytics?.drive_count?.count : 0} {t('kertaa')}</Typography>
          </Grid>
        </Grid>
        
      
      </Fragment>
    )
  }

  const getDriverStart = () => {
    return (
      <Fragment>
        <div className={classes.root}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item>
            <Box paddingBottom="48px">
              <Typography variant="h2">{t('Tervetuloa OpenTaxi palveluun!')}</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box paddingBottom="16px">
              <Typography variant="h3" align="center" >{t('Kaikki kuljettajan palvelut ovat käytettävissä mobiilisovelluksessa.')}</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box paddingBottom="48px">
              <Typography variant="h3">{t('Lataa sovellus ja aloita palvelun käyttö tästä')}:</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item><a href="https://apps.apple.com/us/app/opentaxi-tilaa-taksi/id1501555628?ls=1" target="_blank"><img border="0" src={appStoreLogo} alt="app store logo" /></a></Grid>
              <Grid item><a href="https://play.google.com/store/apps/details?id=com.opentaxi" target="_blank"><img border="0" src={googlePlayLogo} alt="google play logo" /></a></Grid>
            </Grid>
          </Grid>
          
          
        </Grid>
        </div>

        <Grid
          className={classes.countsContainer}
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <Typography variant="h6" align="center">{t('Vahvistettu kuljettajatili')}</Typography>
            <Typography variant="h2" align="center">
              {users?.data?.me?.verified ? (
                <Fragment>
                {myCompany?.name}
                </Fragment>
              ) : (
                <Fragment>
                {t('Ei vielä vahvistettu')}
                </Fragment>
              )}
              </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">{t('Liitetty ajoneuvo')}</Typography>
            <Typography variant="h2" align="center">{users?.data?.me?.car?.license_number ? users?.data?.me?.car?.license_number : "-"}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">{t('Hiiltä kompensoitu')}</Typography>
            <Typography variant="h2" align="center">{myCompany?.analytics?.coal_compensated?.count ? myCompany?.analytics?.coal_compensated?.count : 0}t</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">{t('Ajoa suoritettu')}</Typography>
            <Typography variant="h2" align="center">{myCompany?.analytics?.drive_count?.count ? myCompany?.analytics?.drive_count?.count : 0} {t('kertaa')}</Typography>
          </Grid>
          
        </Grid>


      </Fragment>

    )
  }

  return (
    <Fragment>
      
      {users?.data?.me?.status == 100 ? (
        <Fragment>
          {getCompanyStart()}
        </Fragment>
      ) : (
        <Fragment>
          {getDriverStart()}
        </Fragment>
      )}
      
      <Paper>
        <Box padding={2}>
          <CompanyInfo company={myCompany} />
        </Box>
      </Paper>

    </Fragment>
  );
  
}

export default Start;