import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {getMyCompany} from '~/state/companies'
import PriceDialog from './PriceDialog'

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getMyCompany,
  }, dispatch)
}

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PriceDialog)
)
