import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import vehicleDefault from "~/assets/logo.png";
import kelaLogo from 'assets/kelaikoni.png';
import wheelchairLogo from 'assets/pyoratuoliIkoni.png';
import VehicleForm from 'components/VehicleForm/VehicleForm';
import ButtonSuccess from '~/components/Buttons/ButtonSuccess';
import {vehiclePassengers} from 'services/constants';
import { validateCompany } from 'services/companies'
import AlertDialog from 'components/AlertDialog'

import { getVehicles } from 'services/http/vehicles'

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 900,
    backgroundColor: theme.palette.table.thead.background,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },
  textGreen: {
    color: theme.palette.text.green
  },
  buttonLink: {
    textDecoration:'none'
  }

}))



const VehiclesList = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myUser, myCompany } = props;
  
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false)
  
  useEffect(() => {
    handleValidateCompany();
    _getVehicles();
  },[]);

  const handleValidateCompany = () => {
    if (validateCompany(myCompany) === false)
    {
      setAlertOpen(true)
    }
  }
  
  const _getVehicles = () => {
    setLoading(true);
    const request = {
        params: {
          // company__id: 1
          ordering:'license_number',
        }
    };
    
    getVehicles(request).then((response) => {
        setRows(response);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          <Typography variant="h4">
            {t('Autot')}
          </Typography>
          {myUser?.status < 100 ? (
            <Typography>
            {t('Vain yrityksen ylläpitäjä pystyy lisäämään ajoneuvoja')}
            </Typography>
          ) : null }
        </Grid>
        
        {myUser?.status == 100 ? (
          <Grid item>
            <Link
              className={classes.buttonLink}
              to={'/dashboard/vehicles/new'}
            >
              <ButtonSuccess>
                {t('Lisää auto')}
              </ButtonSuccess>
            </Link>
          </Grid>
        ) : null }
        
      </Grid>
    
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>&nbsp;</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Rekisteritunnus')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Kuljettaja')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Henkilömäärä')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        
        { loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan="5">
                <SpinnerCentered />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" align="left">
                <Grid container alignItems="center">
                  {row.image ? (
                    <Avatar alt="vehicle_image" src={row.image} className={classes.avatar} />
                  ) : (
                    <Avatar alt="vehicle_image" src={vehicleDefault} className={classes.avatar} />
                  )}

                  {row.kela_areas && row.kela_areas.length > 0
                    ?
                      <Avatar src={kelaLogo} className={classes.logo}/> 
                    :
                      null
                  }
                  {row.wheelchair
                    ?
                      <Avatar src={wheelchairLogo} className={classes.logo}/>
                    :
                      null
                  } 
                  
                </Grid>
              </TableCell>
              <TableCell align="left">
                <Button component={Link} to={"/dashboard/vehicles/"+row.id+"/edit"}>
                  <Typography color="primary" variant="subtitle2">{row.license_number}</Typography>
                </Button>
              </TableCell>
              <TableCell align="left">
                {row.driver ? (
                  <Typography variant="subtitle2">
                    {row.driver?.user?.first_name} {row.driver?.user?.last_name}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" className={classes.textGreen}>
                    {t('Auto vapaa')}
                  </Typography>
                )}
                
                </TableCell>
              <TableCell align="left">
              { row.max_passanger == null || row.max_passanger == 0 ? (
                <Typography variant="subtitle2">{t('Tavarakuljetus')}</Typography>
              ) : (
                <Fragment>
                {
                  vehiclePassengers.reduce((result, item) => {
                    if (item.key === row.max_passanger) { result = item.value }
                    return result
                  }, null)
                }
                </Fragment>
              )}
              </TableCell>
              <TableCell align="right">
                <Button component={Link} to={"/dashboard/vehicles/"+row.id+"/view"}>
                  <Typography color="primary" variant="subtitle2">{t('Avaa tiedot')}</Typography>
                </Button>
                
                {myUser?.status == 100 && (
                <Button component={Link} to={"/dashboard/vehicles/"+row.id+"/edit"}>
                  <Typography color="primary" variant="subtitle2">{t('Muokkaa')}</Typography>
                </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        )}
      </Table>

      <AlertDialog 
        titleText={t('Huom!')}
        contentText={t('Sinun täytyy lisätä yrityksesi maksutiedot ja hinnasto, jotta voit lisätä kuljettajia ja autoja.')}
        open={alertOpen}
        setOpen={setAlertOpen}
        showCancel={false}
        confirmText={t('OK')}
        handleConfirm={() => history.push("/dashboard/start")}
        isModal={true}
      />

    </Paper>
  )
}

export default VehiclesList;