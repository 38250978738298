import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Home from './Home'

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home);
