import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    flexGrow: 1,
    height: "100%",
    overflowX: 'auto',
    padding: 48,
  }
}));

const Support = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  return (
    <Fragment>
      <Paper className={classes.paper} elevation={0} square>
        <Box marginBottom={4}>
          <Typography variant="h4">{t('Tuki')}</Typography>
        </Box>
        
        <Grid container spacing={3}>
          <Grid item xs>
            <Box marginBottom={2}>
              <a href="https://opentaxi.fi/ohjeet" target="_blank">{t("Tutustu käyttöohjeisiin")}</a>
            </Box>
            <Box marginBottom={4}>
              <a href="https://opentaxi.fi/faq" target="_blank">{t("Yleisiä kysymyksiä palvelun käytöstä")}</a>
            </Box>
            <Box marginBottom={2}>
              <Typography variant="subtitle2">{t('Ota yhteyttä tukeen')}</Typography>
              <a href="mailto:support@opentaxi.fi">support@opentaxi.fi</a>
            </Box>
          </Grid>
        </Grid>
          
      </Paper>
    </Fragment>
  );
  
}

export default Support;