import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  button: {
    textDecoration: 'none',
    backgroundColor: theme.palette.success.main,
    border: `2px solid #fff`,
    color: '#fff',
    '&:hover':{
      backgroundColor: '#fff',
      border: `2px solid ${theme.palette.success.main}`,
      color: theme.palette.success.main,
    }, 
  },
  centerAlign: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));