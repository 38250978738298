import React from 'react';

import ButtonBase from './ButtonBase'
import styles from './ButtonDelete.css';

const ButtonDelete = (props) => {
  const {children, ...rest} = props;
  const classes = styles();
  
  return (
      <ButtonBase {...rest} className={classes.button} variant="contained" color="error">
        {children}
      </ButtonBase>
  )
}

export default ButtonDelete;
