import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VehiclePassengersSelect from "./VehiclePassengersSelect";

const mapStateToProps = state => ({
  enums: state.enums,
  myCompany: state.companies?.data?.me,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclePassengersSelect);
