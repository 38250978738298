import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Link as MuiLink} from '@material-ui/core/';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logo from 'assets/logo.png'
import styles from './navigation.css.js';

const ITEM_HEIGHT = 48;

const Navigation = (props) => {
  // Declare a new state variable, which we'll call "count"
  const smallScreen = useMediaQuery('(max-width:960px)');
  const hideTitle = useMediaQuery('(max-width:690px)');
  const [value, setValue] = useState(props.value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // props
  const { t, i18n } = useTranslation();
  const classes = styles(); 

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Grid container alignItems="center" className={classes.navigationContainer}>
              <img src={logo} alt="logo" className={classes.logo}/>
              {!hideTitle && value === 1
                ?
                  <Typography variant="h2" className={classes.title}>
                    <span className={classes.skinnyText}>Löydä Oma</span> Taksi
                  </Typography>
                :
                  null
              }

              {!smallScreen
                ?
                  <Tabs value={value} className={classes.navigationLinkContainer}>
                    <Tab 
                      label="Tilaa taksi"
                      component={Link} to="/taxi"
                      disabled={value === 1}
                      className={`${props.value === 1 ? classes.active : null } classes.navigationLink`}
                    />
                    <Tab label="Lue lisää palvelusta"
                      component={MuiLink}
                      href="http://opentaxi.fi/"
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={value === 0}
                      className={`${props.value === 0 ? classes.active : null } classes.navigationLink`}/>
                  </Tabs>
                :
                  <>
                    <IconButton
                      aria-label="More"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      className={classes.navigationLinkContainer}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: 200,
                        },
                      }}
                    >
                      <MenuItem
                        selected={value === 0}
                        onClick={handleClose}
                        component={Link}
                        to={'/taxi'}
                      >
                        <Typography color="primary">
                          {'Tilaa taksi'}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        selected={value === 1}
                        onClick={handleClose}
                        component={MuiLink}
                        href= "http://opentaxi.fi/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography color="primary">
                          {'Lue lisää palvelusta'}
                        </Typography>
                      </MenuItem>
                    </Menu>
                </>
              }

            </Grid>
          </Toolbar>
    </AppBar>
  )
}

export default Navigation;