import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import { getCities } from '~/services/http/locations'

const useStyles = makeStyles(theme => ({
  
}));


const CitySelect = (props) => {
  const classes = useStyles();
  const {name, value, updateValues} = props;
  const [cities, setCities] = useState();
  
  useEffect(() => {
    const request = {
      params: {
        ordering: "name",
      }
    }
    console.log("CitySelect request", request);
    
    getCities(request).then((response) => {
      let _cities = response.map(city => ({
        id: city.id,
        name: city.name,
      }));
      
      setCities(_cities);
    })      
  }, [])

  const handleChange = ({target}) => {
    updateValues({[target.name]: {id: target.value}});
  };
  
  return (
    <div className={classes.root}>
      { cities && value ? (
      <TextField
        id="company_city_id"
        select
        label="Kaupunki"
        defaultValue={value}
        value={value}
        name={props.name}
        onChange={handleChange}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="dense"
        variant="outlined"
        fullWidth
        allowEmpty
      >
        {cities.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      ) : null }
    </div>
  );
}

export default CitySelect;

/*

<Select
          
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
            label: '',
            variant:"outlined",
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
              
            },
          }}
          placeholder=""
          options={suggestions}
          components={components}
          value={value}
          onChange={handleChange}
        />
        */