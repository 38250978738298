import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import MySelectField from "components/Inputs/MySelectField"
import { getVehicles } from '~/services/http/vehicles'

const useStyles = makeStyles(theme => ({
  
}));

const VehicleSelect = (props) =>
{
  const { enums, value, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const [vehicles, setVehicles] = useState();
  
  useEffect(() => {
    getVehicles({}).then((response) => {
      setVehicles(
      [
        {id:-1,license_number:'Kaikki'},
        ...response
      ])
    })      
  }, [])
  
  useEffect(() => {
    console.log("value",value);     
  }, [value])
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  return (
    <Fragment>
    
      <MySelectField
        id="select_vehicle"
        name="car"
        value={value ? value : -1}
        onChange={handleSelectChange}
      >
        {vehicles ? vehicles.map(vehicle => (
          <MenuItem key={vehicle.id} value={vehicle.id}>
            {vehicle.license_number}
          </MenuItem>
        )) : null}
      </MySelectField>
    </Fragment>
  )
}

export default VehicleSelect;
