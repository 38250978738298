import React, { Fragment, useState, useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';


import MyTextField from "components/Inputs/MyTextField"
import VehicleTypeSelect from 'components/Inputs/VehicleTypeSelect'
import VehiclePassengersSelect from 'components/Inputs/VehiclePassengersSelect'
import VehicleColorSelect from 'components/Inputs/VehicleColorSelect'
import VehicleFuelTypeSelect from 'components/Inputs/VehicleFuelTypeSelect'
import VehicleTrunkTypeSelect from 'components/Inputs/VehicleTrunkTypeSelect'
import PriceListSelect from 'components/Inputs/PriceListSelect'

const useStyles = makeStyles(theme => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1
  },
  avatar: {
    margin:16,
    color:'#bbb',
    textAlign: 'center',
    width: 160,
    height: 160,
    backgroundColor: '#eee',
    borderWidth:'2px',
    borderStyle:'dashed',
    borderColor:'#bbb',
  },
  toggleButtonSelected: {
    color: '#fff' + ' !important',
    backgroundColor: theme.palette.primary.main + ' !important',
  },
  inputFile: {
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    width: 30
  },
  imageContainer: {
    cursor: 'pointer',
  },
  

}));

const VehicleForm = (props) =>
{
  // hooks
  const [loading, setLoading] = useState(false);
  const [validFields, setValidFields] = useState({
    receiverPhone:false,
    receiverEmail:false,
  });
  const [driverType, setDriverType] = useState(0);
  
  // props
  const { enums, values, updateValues, errors, validateForm } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  // image
  const inputHeroFile = useRef(null)
  const [heroImage, setHeroImage] = useState()
  const [heroImageFile, setHeroImageFile] = useState(null)
  const [heroImageBase64, setHeroImageBase64] = useState(null)
  
  useEffect(() => {
    
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  
  
  useEffect(() => {
    if (!heroImage)
    {
      setHeroImage(values.image);
    }
    
  }, [values.image])
  
  const handleToggle = (name) => (event, newType) => {
    updateValues({ [name]: newType })
  }
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: {id:target.value} })
  }
  
  const handleHeroImageClick = () => {
    inputHeroFile.current.click()
  }
  
  const handleHeroImageChange = ({target}) => {
    
    const file = target.files[0];
    const convert_base64 = false;
    
    if (!file) return;
    
    if(file.size > 5000000) {
      props.enqueueSnackbar(`${file.name} ${t(' koko on liian suuri (max 5 Mt)')}`, { variant: 'error' });
    }
    else {
      if (convert_base64 == true) {
        const reader = new FileReader();
        reader.onloadend = (item) => {
          const image_base64 = reader.result;
          setHeroImage(URL.createObjectURL(file))
          setHeroImageBase64(image_base64)
        }
        reader.readAsDataURL(file, "utf-8");
      } else {
        setHeroImage(URL.createObjectURL(file))
        setHeroImageFile(file)
        updateValues({ "imageFile": file })
      }
    }

  } 
  
  
  return (
    <Fragment>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={12} md={6}>
          
            <Grid container direction="row" spacing={1}>
            
              <Grid item xs={12}>
                <MyTextField
                  autoFocus
                  customLabel={t("Rekisterinumero")}
                  id="vehicle-license_number"
                  name="license_number"
                  onChange={handleFormChange}
                  required
                  value={values.license_number || ""}
                  disabled={values?.id && values?.license_number && values?.license_number.length > 0}
                  error={errors?.license_number}
                  helperText={errors?.license_number}
                  onBlur={validateForm}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Auton tyyppi")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <VehicleTypeSelect value={values.type} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Asiakaspaikkamäärä (kuljettajan lisäksi)")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <VehiclePassengersSelect value={values.max_passanger} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Auton väri")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <VehicleColorSelect value={values.color} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Polttoaine")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <VehicleFuelTypeSelect value={values.fuel_type} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Tavaratilan koko")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box marginBottom="16px">
                  <VehicleTrunkTypeSelect value={values.trunk_type} updateValues={updateValues} />
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t("Hinnasto")}
                </Typography>
              </Grid>
              
              <Grid item xs={12}>
                <Box marginBottom="16px">
                  <PriceListSelect value={values.price_list} updateValues={updateValues} />
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                {t('Pyörätuoli')}
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Box marginBottom="16px">
                  <ToggleButtonGroup
                    value={values?.wheelchair}
                    exclusive
                    onChange={handleToggle("wheelchair")}
                    aria-label="text alignment"
                  >
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={true} aria-label="left aligned">
                      {t('Kyllä')}
                    </ToggleButton>
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={false} aria-label="right aligned">
                      {t('Ei')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                {t('Rollaattori')}
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Box marginBottom="16px">
                  <ToggleButtonGroup
                    value={values?.rollator}
                    exclusive
                    onChange={handleToggle("rollator")}
                    aria-label="text alignment"
                  >
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={true} aria-label="left aligned">
                      {t('Kyllä')}
                    </ToggleButton>
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={false} aria-label="right aligned">
                      {t('Ei')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  {t('Eläimet sallittu')}
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Box marginBottom="16px">
                  <ToggleButtonGroup
                    value={values?.animals}
                    exclusive
                    onChange={handleToggle("animals")}
                    aria-label="text alignment"
                  >
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={true} aria-label="left aligned">
                      {t("Kyllä")}
                    </ToggleButton>
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={false} aria-label="right aligned">
                      {t("Ei")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                {t('Autossa teippauksia')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box marginBottom="16px">
                  <ToggleButtonGroup
                    value={values?.taped}
                    exclusive
                    onChange={handleToggle("taped")}
                    aria-label="text alignment"
                  >
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={true} aria-label="left aligned">
                      {t('Kyllä')}
                    </ToggleButton>
                    <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={false} aria-label="right aligned">
                      {t('Ei')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <MyTextField
                  customLabel={t("Hiilidioksidin päästöt (g/100km)")}
                  id="vehicle-carbon_dioxide_emission"
                  name="carbon_dioxide_emission"
                  onChange={handleFormChange}
                  value={values.carbon_dioxide_emission || "0"}
                  error={errors?.carbon_dioxide_emission}
                  helperText={errors?.carbon_dioxide_emission}
                  onBlur={validateForm}
                  type="number"
                />
                
              </Grid>
              
              <Grid item xs={12}>

                <MyTextField
                  customLabel={t("Lisätiedot / kuvaus")}
                  id="vehicle-info"
                  multiline
                  name="info"
                  onChange={handleFormChange}
                  rows="3"
                  rowsMax="4"
                  value={values.info || ""}
                />
                
              </Grid>

            
            </Grid>
          
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid item>
                <Grid
                  container justify="center" alignItems="center"
                >
                  <div onClick={handleHeroImageClick} className={classes.imageContainer}>
                    <Avatar src={heroImage} className={classes.avatar}>Kuvaa ei ole lisätty</Avatar>
                    <input className={classes.inputFile} ref={inputHeroFile} type="file" name="hero_image" accept="image/*" onChange={handleHeroImageChange} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
        
        </Grid>


    </Fragment>
  )
  
}

export default withSnackbar(VehicleForm);
