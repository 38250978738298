import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {getMyAnalytics} from '~/state/analytics'
import {getMyCompany} from '~/state/companies'
import {getUserMe} from '~/state/users'
import Dashboard from './Dashboard'


const mapStateToProps = state => ({
  myAnalytics: state.analytics.data?.me,
  companies: state.companies,
  myCompany: state.companies?.data?.me,
  users: state.users,
  myUser: state.users?.data?.me,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getMyAnalytics,
    getMyCompany,
    getUserMe,
  }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard);
