import React, { useState, useEffect } from 'react';
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthService from 'services/http/AuthService'
import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonPrimary from 'components/Buttons/ButtonPrimary'
import { sendPasswordResetToken } from 'services/http/users'

const auth = new AuthService();

const styles = theme => ({
  fieldGrid: {
    marginBottom: 12
  }
});

const PasswordResetToken = (props) => {

  const [values, setValues] = useState({
    email:''
  })
  
  const [loading, setLoading] = useState(true);
  const [responseText, setResponseText] = useState();
  const [errors, setErrors] = useState(null);

  // props
  const { classes, login, history, location, enqueueSnackbar } = props;
  const { search } = location;
  const { t } = useTranslation();
  
  useEffect(() => {
    if (search)
    {
      const parsed = queryString.parse(search);
      if (parsed.token)
      {
        handleSendToken(parsed.token);
      }
    }
  }, [search])
  
  const handleSendToken = (token) => {
    const request = {
      data: {
        token: token
      }
    };
    
    sendPasswordResetToken(request).then((response) => {
      setResponseText(t("Uusi salasana on lähetetty sähköpostiisi"))
      enqueueSnackbar(t("Onnistui"), { variant: "success" });
      setLoading(false)
    }, (error) => {
      setErrors(true);
      setResponseText(t("Salasanan nollaus epäonnistui"))
      enqueueSnackbar(t("Epäonnistui"), { variant: "error" });
      setLoading(false)
    });
  }
  

    return (
      <ViewContainer title={t('Vahvistetaan salasanan palautus')}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
          { loading ? (
            <CircularProgress />
          ) : (
            <Typography>{responseText}</Typography>
          )}
          </Grid>
        </Grid>
      </ViewContainer>


    );
  
}

export default withStyles(styles)(PasswordResetToken);