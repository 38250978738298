import http from 'services/http/httpInstance'
import { authHeader } from 'services/http/AuthService'

const url = '/users/'

export const httpGetUserMe = (company_id) =>
  http({
    url: url + 'me/',
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params: {
      company: company_id ? company_id : null,
      drivers: true,
    },
  })
  
export const getUsers = (request) =>
  http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpUpdateMyUser = (data, company_id) =>
  http({
    url: url + 'me/',
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data: data,
    params: {
      company: company_id ? company_id : null,
      // drivers: true,
    },
  })

export const sendPasswordResetConfirmation = (request) =>
  http({
    url: '/send_password_reset_confirmation/',
    method: 'POST',
    headers: {
      // ...authHeader(),
    },
    ...request,
  })
  
export const sendPasswordResetToken = (request) =>
  http({
    url: '/reset_password/',
    method: 'POST',
    headers: {
      // ...authHeader(),
    },
    ...request,
  })

export const sendActivateUserToken = (request) =>
  http({
    url: '/activate_account/',
    method: 'POST',
    headers: {
      // ...authHeader(),
    },
    ...request,
  })
  

