import React, { Fragment, useState, useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {vehiclePassengers} from 'services/constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
    display: 'block',
  },
  th: {
    paddingLeft: 0,
  },
  cell: {
    borderBottomWidth: 0,
  },
  normalTypo: {
    fontWeight: 300,
  },
  imageContainer: {
    
  },
  avatar: {
    width: '128px',
    height: '128px',
    textAlign: 'center',
  },
}));

const VehicleDetails = (props) => {
  // hooks
  const [loading, setLoading] = useState(false);
  
  // props
  const { enums, vehicle } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  // image
  const [heroImage, setHeroImage] = useState()
  
  useEffect(() => {
    
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  useEffect(() => {
    if (!heroImage)
    {
      setHeroImage(vehicle.image);
    }
    
  }, [vehicle.image])
  
  const getVehicleType = (typeInt) => {
    return enums?.data?.vehicles?.car?.type.reduce((result, item) => {
      if (item.key === typeInt) {
        result = item.value
      }
      return result
    }, null)
  }
  
  const getVehiclePassengers = (typeInt) => {
    return vehiclePassengers.reduce((result, item) => {
      if (item.key === typeInt) {
        result = item.value
      }
      return result
    }, null)
  }
  
  const getVehicleColor = (typeInt) => {
    return enums?.data?.vehicles?.car?.color.reduce((result, item) => {
      if (item.key === typeInt) {
        result = item.value
      }
      return result
    }, null)
  }
  
  const getVehicleFuelType = (typeInt) => {
    return enums?.data?.vehicles?.car?.fuel_type.reduce((result, item) => {
      if (item.key === typeInt) {
        result = item.value
      }
      return result
    }, null)
  }
  
  const getVehicleTrunkType = (typeInt) => {
    return enums?.data?.vehicles?.car?.trunk_type.reduce((result, item) => {
      if (item.key === typeInt) {
        result = item.value
      }
      return result
    }, null)
  }
  
  
  
  
  return (
    <Fragment>
      <Paper className={classes.root} elevation={0} square>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <Table className={classes.table} size="small" fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
              <TableBody>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t('Rekisterinumero')}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.license_number}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Auton tyyppi")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{getVehicleType(vehicle?.type)}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Asiakaspaikkamäärä (kuljettajan lisäksi)")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{getVehiclePassengers(vehicle?.max_passanger)}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Auton väri")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{getVehicleColor(vehicle?.color)}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Polttoaine")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{getVehicleFuelType(vehicle?.fuel_type)}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Tavaratilan koko")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{getVehicleTrunkType(vehicle?.trunk_type)}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Hinnasto")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.price_list ? t('Valittu') : t('Oletus')}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Pyörätuoli")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.wheelchair ? t('Kyllä') : t('Ei')}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Rollaattori")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.rollator ? t('Kyllä') : t('Ei')}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t('Eläimet sallittu')}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.animals ? t('Kyllä') : t('Ei')}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t('Autossa teippauksia')}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.taped ? t('Kyllä') : t('Ei')}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Hiilidioksidin päästöt (g/100km)")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.carbon_dioxide_emission}</Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                    <Typography variant="h4">{t("Lisätiedot / kuvaus")}:</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>{vehicle?.info}</Typography>
                  </TableCell>
                </TableRow>
                
              </TableBody>
            </Table>
            
        </Grid>
        
        <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
                <Grid item>
                    <Grid
                      container justify="center" alignItems="center"
                    >
                      <div className={classes.imageContainer}>
                        <Avatar src={heroImage} className={classes.avatar}>Kuvaa ei ole lisätty</Avatar>
                      </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        
        </Grid>

      </Paper>
    </Fragment>
  )
}

export default VehicleDetails;
