import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {getMyCompany} from "~/state/companies"
import CompanyPayment from './CompanyPayment'

const mapStateToProps = state => ({
  myCompany: state.companies?.data?.me,
  users: state.users,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getMyCompany
    
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyPayment)
)
