import React from 'react'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place'
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi'
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles(theme => ({
    icon: {
      width: props => props.size,
      height: props => props.size,
      fontSize: props => props.size / 2,
      transform: 'translate(-50%, -100%)',
      //position: relative,
      //bottom: props => props.size / 2,
      cursor: 'pointer',
      color: props => props?.color ? props?.color : theme.palette.primary.main,
      backgroundColor: props => props?.backgroundColor ? props?.backgroundColor : "#FFF",
      opacity: props => props?.opacity ? props?.opacity : 1,
      borderWidth: props => "1px",
      borderStyle: "solid",
      borderColor: props => props?.borderColor ? props?.borderColor : theme.palette.primary.main,
      borderRadius: "50%",
      
    },
    iconHover: {
        // width: 42,
        // height: 42,
        // transform: 'translate(-50%, -100%)',
        cursor: 'pointer',
    },
    title: {
        textAlign: 'center',
        // transform: 'translate(-100%, -100%)'
    },
}))

const MapPin = props => {
    const { title, hoverDisabled, lat, lng, size, weight, color, pinContent } = props
    const classes = useStyles(props)
    const iconStyle = () => {
        if (hoverDisabled) {
            return classes.icon
        } else {
            return props.$hover ? classes.iconHover : classes.icon
        }
    }
    
    return (
      <div>
          <Tooltip title={title || ""} placement="top" disableHoverListener={hoverDisabled}>
              <LocalTaxiIcon className={classes.icon} onClick={props.onClick}>{pinContent}</LocalTaxiIcon>
          </Tooltip>
      </div>
    )
}

export default MapPin