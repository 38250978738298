import http from '~/services/http/httpInstance'
import { authHeader } from '~/services/http/AuthService'

export const getCities = (request) => {
  console.log("http getCities")
  return http({
    ...request,
    url: '/cities/',
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}
  
export const getProvinces = (request) =>
  http({
    ...request,
    url: '/provinces/',
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
  
export const getCountries = (request) =>
  http({
    ...request,
    url: '/countries/',
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
 