import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import deburr from 'lodash/deburr';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import Chip from '@material-ui/core/Chip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import CheckCircle from '@material-ui/icons/CheckCircle';

import ButtonPrimary from '~/components/Buttons/ButtonPrimary'
import LinearIndeterminate from 'components/Loader/linearIndeterminate';
import Navigation from 'components/Navigation/navigation';
import TablePaginationActions from 'components/Tables/Pagination/TablePaginationActions';
import kelaLogo from 'assets/kelaikoni.png';
import wheelchairLogo from 'assets/pyoratuoliIkoni.png';
import styles from './Taxi.css';
import { taxiTypes, passangersTypes } from 'services/constants'

const Taxi = (props) => {
  const smallScreen = useMediaQuery('(max-width:420px)');
  const mobileTitle = useMediaQuery('(max-width:690px)');
  const [filters, setFilters] = useState({
    city: '',
    taxiType: 'all',
    passangersType: 'all',
  });
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 25,
    page: 0,
  });
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState(0);
  const [companiesData, setCompaniesData] = useState({});
  const [companiesDataLoaded, setCompaniesDataLoaded] = useState(false);
  const [companiesDataError, setCompaniesDataError] = useState(false);
  const [showNumber, setShowNumber] = useState("");
  const [showNumbers, setShowNumbers] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});

  const classes = styles();
  const { t } = useTranslation();

  const suggestions = [

  ];

  useEffect(() => {
    axios({
      method: 'get',
      baseURL: 'https://app.opentaxi.fi/api/',
      url: '/companies/',
      headers: { 'content-type': 'application/json' },
    })
    .then(response => {
      setCompaniesData(response.data);
      setCompaniesDataLoaded(true);

    })
    .catch(error => {
      setCompaniesDataError(true);
      setCompaniesDataError(true);
    });
  }, [])

  const handleChangeFilters = name => event => {
    if(filters[name] !== event.target.value) {
      setFilters({ ...filters, [name]: event.target.value });
    }
  };

  const handleChangePage = (event, page) => {
    
    setPagination({
      ...pagination,
      offset: page * pagination.limit,
      page: page
    });
  };

  const handleChangeRowsPerPage = event => {
    
    setPagination({
      ...pagination,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });

  };

  /*function renderInput(inputProps) {
    if(filters.city !== inputProps.inputProps.value) {
      setFilters({...filters, city: inputProps.inputProps.value})
    }
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    if(isSelected) {

    }

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  }

  renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
  };

  function getSuggestions(value, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
      ? []
      : suggestions.filter(suggestion => {
          const keep = count < 6 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }*/
  
  useEffect(() => {
    if(companiesDataLoaded) {
    let results = companiesData;

    let cities = results.map((item) => {
      if(item.main_city) {
        return item.main_city.name;
      }
    })

    let uniqueCities = [];

    cities.map((city) => {
      if(uniqueCities.indexOf(city) === -1) {
        uniqueCities.push(city);
      }
    });
    
    if(filters.city) {
      let cityRegExp = new RegExp("^" + filters.city + ".*", "i")     
      let cityFilteredRows = results.filter((item) => {
        let flag = false;
        if(item.main_city && item.main_city.name.match(cityRegExp)) {
          flag = true;
        }
        else if(item.secondary_cities && item.secondary_cities.length > 0) {
          item.secondary_cities.map((secondary_city) => {
            if(secondary_city.name.match(cityRegExp)) {
              flag = true;
            }
          })
        }
        return flag;
      })
      results = cityFilteredRows
    }

    let carTypeFilteredRows = results.filter(item => {
      if(filters.taxiType === 'kela') {
        if(item.kela_areas.length > 0) {
          return true
        }
        return false
      }
      else if(filters.taxiType === 'inva') {
        if(item.wheelchair_spaces > 0 && item.kela_areas.length === 0){
          return true
        }
        return false
      }
      else if(filters.taxiType === 'normal') {
        if(item.kela_areas.length === 0){
          return true
        }
        return false
      }
      else if(filters.taxiType === 'large') {
        if(item.employee_count > 0 && item.kela_areas.length === 0){
          return true
        }
        return false
      }
      else{
        return true
      }
    });
    results = carTypeFilteredRows;

    /*let passangersTypeFilteredRows = results.filter(item => item.passangersType === filters.passangersType)
    results = passangersTypeFilteredRows;*/

    let selectedRowsPage = [];

    for(let i = pagination.offset; i < (pagination.page + 1) * pagination.limit; i++) {
      if(results[i]) {
        selectedRowsPage.push(results[i]);
      }
    }

    let checkRows = selectedRowsPage.map((row, index) => {
      if(index < pagination.limit) {
        return row;
      }
      else {
        return 0;
      }
    });

    let filteredRows = checkRows.filter( item => item !== 0)

    setRows(filteredRows);
    setCount(results.length);
    }



  },[pagination, filters, companiesDataLoaded]);

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 0,
      offset: 0,
    });
  },[filters]);
  
  const postAxiosRequest = (type, companyId, info) => {
    axios({
      method: 'post',
      baseURL: 'https://app.opentaxi.fi/api/',
      url: '/activities/',
      data: {
        type: type,
        company: companyId,
        info: info
      },
    })
    .then(response => {
      if(type === 1){
        console.log("Opened phone number")
      }
      else if(type === 2){
        console.log("Opened company details")
      }
      else {
        console.log("Undefined")
      }
    })
    .catch(error => {

    });
  };

  const handleShowNumber = (companyObject) => {
    setShowNumber(companyObject.id);
    postAxiosRequest(1, companyObject.id, "Numero katsottu");
  };

  const handleShowMultipleNumbers = (companyObject) => {
    setShowNumbers(true)
    setCompanyDetails(companyObject);
    postAxiosRequest(1, companyObject.id, "Numero katsottu");
  };

  const handleViewDetails = (companyObject) => {
    setOpenDetails(true);
    setCompanyDetails(companyObject);
    postAxiosRequest(2, companyObject.id, "Tiedot katsottu")
  };
  
  return (
    <Grid container className={classes.pageContainer}>
      <Navigation value={1}/>
      {mobileTitle
        ?
          <Grid item xs={12} className={classes.searchTitleContainer}>
            <Typography variant="h2" className={classes.searchTitle} align="center">
              <span className={classes.skinnyText}>Löydä Oma</span> Taksi
            </Typography>
          </Grid>
        :
          null
      }
      
      <Grid item xs={12} className={classes.buttonContainer}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}> 
          <Grid item>
            <Box p="16px">
              <Typography>{t('Lisää yrityksesi tiedot ilmaiseksi')}</Typography>
            </Box>
          </Grid>
          <Grid item>
            
            <ButtonPrimary href="https://www.opentaxi.fi/laheta-yrityksesi-tiedot/">{t('Liity tästä')}</ButtonPrimary>
          </Grid>
        </Grid>
        
      </Grid>
      
      <Grid item xs={12} className={classes.searchContainer}>
        <Grid container>

          <Grid item xs={12} md={6} className={classes.inputContainer}>
            
            <TextField
              id="inputCity"
              label="Kaupunki"
              fullWidth
              className={classes.textField}
              value={filters.name}
              onChange={handleChangeFilters('city')}
              margin="normal"
              variant="outlined"
              helperText="Kirjoita kaupungin nimi"
            />
            {/* 
            <Downshift id="downshift-options">
              {({
                clearSelection,
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                selectedItem,
              }) => {
                const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                  onChange: event => {
                    if (event.target.value === '') {
                      clearSelection();
                    }
                  },
                  onFocus: openMenu,
                  placeholder: 'Kirjoita kaupungin nimi',
                });

              return (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    variant: 'outlined',
                    label: 'Kaupunki',
                    helperText: 'Kirjoita kaupungin nimi',
                    InputLabelProps: getLabelProps({ shrink: true, className: classes.coloredLabel}),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps,
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.citySuggestionsContainer} square>
                        {getSuggestions(inputValue, { showEmpty: true }).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem,
                          }),
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              );
              }}
            </Downshift>
            */}
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputContainer}>
            <TextField
              id="inputTaxiType"
              select
              label="Tyyppi"
              InputLabelProps={{
                className: classes.coloredLabel,
              }}
              fullWidth
              className={classes.textField}
              value={filters.taxiType}
              onChange={handleChangeFilters('taxiType')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Valitse taksin tyyppi"
              margin="normal"
              variant="outlined"
             >
              {taxiTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        {/*
          <Grid item xs={12} md={6} className={classes.inputContainer}>
            <TextField
              id="inputPassangers"
              select
              label="Henkilöt"
              InputLabelProps={{
                className: classes.coloredLabel,
              }}
              fullWidth
              className={classes.textField}
              value={filters.passangersType}
              onChange={handleChangeFilters('passangersType')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Valitse henkilömäärä"
              margin="normal"
              variant="outlined"
            >
              {passangersTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        */}
          <Grid item xs={12} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.firstColumn}></TableCell>
                  <TableCell className={classes.companyNameColumn}>Kuljetusyritys</TableCell>
                  <TableCell align="left" className={classes.cityColumn}>Kaupunki</TableCell>
                  <TableCell align="left" className={classes.cityColumn}>Muut alueet</TableCell>
                  <TableCell align="left" className={classes.phoneColumn}>Puhelin</TableCell>
                  {/*<TableCell align="left" className={classes.typeColumn}>Tyyppi</TableCell>*/}
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!companiesDataError
                  ?
                    <>
                      {companiesDataLoaded
                        ?
                          <>
                            {rows.length > 0 && rows.map( (row, index) => {
                              let filteredContactPhone = "";
                              let showMore = false;
                              if(row.contact_phone){
                                filteredContactPhone = row.contact_phone.replace(/\s/gi, '');
                                let endIndexOfFilteredContactPhone = filteredContactPhone.search(/[,(]/g);
                                if(endIndexOfFilteredContactPhone != -1){
                                  filteredContactPhone = filteredContactPhone.slice(0, endIndexOfFilteredContactPhone);
                                  showMore = row.contact_phone.replace(/\s/gi, '').search(/[,]/g) != -1;
                                }
                              }
                              return(
                                <TableRow key={row.id ? row.id : index}>
                                  <TableCell className={classes.firstColumn}>
                                    <Grid container alignItems="center">
                                      <CheckCircle fontSize="default" className={row.checked ?  classes.confirmed : classes.notConfirmed}/>
                                    
                                      {row.kela_areas && row.kela_areas.length > 0
                                        ?
                                          <Avatar src={kelaLogo} className={classes.logo}/> 
                                        :
                                          null
                                      }
                                      {row.wheelchair_spaces && row.wheelchair_spaces > 0
                                        ?
                                          <Avatar src={wheelchairLogo} className={classes.logo}/>
                                        :
                                          null
                                      } 
                                    </Grid>
                                  </TableCell>
                                  <TableCell component="th" scope="row"  className={`${classes.companyNameColumn} ${classes.link}`} onClick={() => {handleViewDetails(row)}}>                   
                                      {row.name && row.name
                                        ? 
                                          <Typography>
                                          {row.name}
                                          </Typography>
                                        : null
                                      }
                                    {
                                      row.kela_areas && row.kela_areas.length > 0
                                        ?
                                          <>
                                          <Typography color="textPrimary">
                                          {`kela, `}
                                          {row.wheelchair_spaces && row.wheelchair_spaces > 0
                                            ?
                                              "inva"
                                            :
                                              null
                                          }
                                          </Typography>
                                          </>
                                        :
                                          <>
                                            <Typography color="textPrimary">
                                            {row.wheelchair_spaces && row.wheelchair_spaces > 0
                                              ?
                                                "inva"
                                              :
                                                null
                                            }
                                            </Typography>
                                          </>
                                    }
                                  </TableCell>
                                  <TableCell align="left" className={classes.cityColumn}>
                                    {row.main_city && row.main_city.name ? row.main_city.name : null}
                                  </TableCell>
                                  <TableCell align="left" className={classes.cityColumn}>
                                    {row.secondary_cities && row.secondary_cities.length > 0
                                      ? 
                                        <>
                                          {row.secondary_cities.map((secondary_city, index) => {
                                            if(index > 0) {
                                              return(`, ${secondary_city.name}`);
                                            }
                                            return(secondary_city.name);
                                          })}
                                        </>
                                      : 
                                        null
                                    }
                                  </TableCell>
                                  <TableCell align="left" className={classes.phoneColumn}>
                                  {showNumber === row.id 
                                    ?
                                      filteredContactPhone
                                        ?
                                          <Link href={`tel:${filteredContactPhone}`}>
                                            {filteredContactPhone}
                                          </Link>

                                        : null
                                    :
                                      <>
                                        {`${row.contact_phone && row.contact_phone[0]}${row.contact_phone && row.contact_phone[1]}${row.contact_phone && row.contact_phone[2]}${row.contact_phone && row.contact_phone[3]}...`}
                                          <span onClick={() => {showMore ? handleShowMultipleNumbers(row) : handleShowNumber(row)}} className={classes.link}>{showMore ? 'Näytä lisää' : 'Näytä numero'}</span>
                                      </>
                                  }
                                
                                  </TableCell>
                                  {/*<TableCell align="left" className={classes.typeColumn}>
                                    {row.kela_areas && row.kela_areas.length > 0
                                      ?
                                        "Kela taksi"
                                      :
                                        "Normaali taksi"
                                    }
                                  </TableCell>*/}
                                  <TableCell align="right" className={classes.link} onClick={() => {handleViewDetails(row)}}>Avaa tiedot</TableCell>
                                </TableRow>
                              )
                            })}
                            {rows.length === 0
                              ?
                                <TableRow>
                                  <TableCell colSpan={7}>
                                    Hakuehtoja vastaavia tuloksia ei löytynyt
                                  </TableCell>
                                </TableRow>
                              :
                              null
                            }
                          </>
                        :
                          <TableRow>
                            <TableCell colSpan={7}>
                              <LinearIndeterminate />
                            </TableCell>
                          </TableRow>
                      }
                    </>
                  :
                    <TableRow>
                      <TableCell colSpan={5}>
                        Tietoja ei saatavilla
                      </TableCell>
                    </TableRow>
                }

              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <TablePagination
                component='div'
                className={smallScreen ? classes.tabPaginationDiv : null}
                classes={smallScreen ? {toolbar: classes.tablePaginationToolbarBreak} : null}
                rowsPerPageOptions={[5, 25, 50]}
                count={count || 0}
                rowsPerPage={pagination.limit}
                page={pagination.page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                caption={{root: {color:"red"}}}
                labelRowsPerPage='Rivejä per sivu'
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="caption">
              {t('Luettelon tiedot päivittyvät julkisista rekistereistä, emmekä vastaa tietojen mahdollisista virheistä.')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        onClose={() => {setOpenDetails(false); setCompanyDetails({})}}
        aria-labelledby="customized-dialog-title"
        open={openDetails}
        PaperProps={{ classes: {root: classes.dialogPaper } }}
      >
        <Grid container className={classes.dialogContainer}>
          <Grid item xs={12} className={classes.dialogTitle}>
            <Typography variant="h2" gutterBottom>
              {companyDetails.name}
            </Typography>
          </Grid>
          <div className={classes.logoContainer}>
            {companyDetails.kela_areas && companyDetails.kela_areas.length > 0
              ?
                <Avatar src={kelaLogo} className={classes.inlineLogo}/>
              :
                null
            }
            {companyDetails.wheelchair_spaces && companyDetails.wheelchair_spaces > 0
              ?
                <Avatar src={wheelchairLogo} className={classes.inlineLogo}/>
              :
                null
            }
          </div>
          <Grid item xs={12}>
            <Grid container>         
              <Grid item className={classes.checkedContainer}>
                <Grid container alignItems="center">
                  <CheckCircle fontSize="small" className={companyDetails.checked ? classes.confirmed : classes.notConfirmed}/>
                  <Typography display="inline" className={companyDetails.checked ? classes.confirmed : classes.notConfirmed}>
                    Yrityksen tiedot {companyDetails.checked ? "tarkastettu" : "tarkastamatta"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogContentLabelContainer}>
              <Typography className={classes.dialogContentLabel}>
                Yritys:
              </Typography>
            </div>
            <Typography display={smallScreen ? "block" : "inline"} >
              {companyDetails.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogContentLabelContainer}>
              <Typography className={classes.dialogContentLabel}>
                Taksityyppi:
              </Typography>
            </div>
            <Typography display={smallScreen ? "block" : "inline"} gutterBottom>
              {companyDetails.kela_areas && companyDetails.kela_areas.length > 0
                ?
                  "Kela taksi"
                :
                  "Normaali taksi"
              }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogContentLabelContainer}>
              <Typography className={classes.dialogContentLabel}>
                Kela-alueet:
              </Typography>
            </div>
              <Typography display={smallScreen ? "block" : "inline"} gutterBottom>
                {companyDetails.kela_areas && companyDetails.kela_areas.length > 0
                  ?
                    companyDetails.kela_areas.map((item, index) => {
                      if(index === 0)
                      {
                        return item.name;
                      }
                      else {
                        return `, ${item.name}`;
                      }
                    })
                  :
                    null
                }
              </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogContentLabelContainer}>
              <Typography className={classes.dialogContentLabel}>
                Paikkakunta:
              </Typography>
            </div>
              <Typography display={smallScreen ? "block" : "inline"} gutterBottom>
                {companyDetails.main_city && companyDetails.main_city.name}
              </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogContentLabelContainer}>
              <Typography className={classes.dialogContentLabel}>
                Muut alueet:
              </Typography>
            </div>
              <Typography display={smallScreen ? "block" : "inline"} gutterBottom>
                {companyDetails.secondary_cities && companyDetails.secondary_cities.length > 0
                  ?
                    companyDetails.secondary_cities.map((item, index) => {
                      if(index > 0) {
                        return(`, ${item.name}`);
                      }
                      return(item.name);
                    })
                  :
                    null
                }
              </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.dialogContactDetailsContainer}>
              <Grid item xs={12}>
                <Grid container>
                  <div className={classes.dialogContentLabelContainer}>
                    <Typography className={classes.dialogContentLabel}>
                      Puhelin:
                    </Typography>
                  </div>
                  <Grid item xs={smallScreen ? 12 : false}>
                    {showNumber === companyDetails.id
                      ?
                        companyDetails.contact_phone 
                          ? 
                          companyDetails.contact_phone && companyDetails.contact_phone.split(',').map((phoneNumber) => {
                            let  filteredContactPhone = phoneNumber.replace(/\s/gi, '');
                            let endIndexOfFilteredContactPhone = filteredContactPhone.search(/[(]/g);
                            if(endIndexOfFilteredContactPhone != -1){
                              filteredContactPhone = filteredContactPhone.slice(0, endIndexOfFilteredContactPhone);
                            }

                            if(filteredContactPhone.match(/[+0-9]/gi))
                            {
                              return(
                                <Typography display="block" gutterBottom>
                                  <Link href={`tel:${filteredContactPhone}`}>
                                    {phoneNumber}
                                  </Link>
                                </Typography>
                              )
                            }
                          })
                          : null
                      :
                        <Typography display="block" gutterBottom>
                          {`${companyDetails.contact_phone && companyDetails.contact_phone[0] ? companyDetails.contact_phone[0] : null}${companyDetails.contact_phone && companyDetails.contact_phone[1] ? companyDetails.contact_phone[1] : null}${companyDetails.contact_phone && companyDetails.contact_phone[2] ? companyDetails.contact_phone[2] : null}${companyDetails.contact_phone && companyDetails.contact_phone[3] ? companyDetails.contact_phone[3] : null}...`}
                          <span onClick={() => handleShowNumber(companyDetails)} className={classes.link}>Näytä numero</span>
                        </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.dialogContentLabelContainer}>
                  <Typography className={classes.dialogContentLabel}>
                    www-sivut
                  </Typography>
                </div>
                <Typography component={Link} href={
                  companyDetails.web_url && companyDetails.web_url.match(/^http/g)
                    ?
                      companyDetails.web_url
                    :
                      `http://${companyDetails.web_url}`}
                    target="_blank"
                    display={smallScreen ? "block" : "inline"}
                    gutterBottom
                > 
                  {companyDetails.web_url
                    ?
                      <>
                        {companyDetails.web_url.match(/^http/g)
                          ?
                            companyDetails.web_url
                          :
                            `http://${companyDetails.web_url}`
                        }
                      </>
                    :
                      null
                  }

                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.dialogContentLabelContainer}>
                  <Typography  className={classes.dialogContentLabel}>
                    Sähköposti:
                  </Typography>
                </div>
                <Typography display={smallScreen ? "block" : "inline"} gutterBottom component={Link} href={`mailto:${companyDetails.contact_email}`}>
                {companyDetails.contact_email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.dialogContentLabelContainer}>
                  <Typography className={classes.dialogContentLabel}>
                    Yhteyshenkilö:
                  </Typography>
                </div>
                <Typography display={smallScreen ? "block" : "inline"} gutterBottom>
                  {companyDetails.contact_name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.bottomDialogActions}>

                <Grid container>
                  <Grid item xs={8}>
                    <Typography className={classes.infoText}>
                      Onko tiedoissa virheitä?
                    </Typography>
                    <Typography className={classes.infoText}>
                      Lähetä meille korjatut tiedot <Link href="https://www.opentaxi.fi/laheta-yrityksesi-tiedot/" target="_blank">tästä</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Button variant="contained" onClick={() => setOpenDetails(false)} color="primary" className={classes.closeButton}>
                      Sulje
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => {setShowNumbers(false); setCompanyDetails({})}}
        aria-labelledby="customized-dialog-title"
        open={showNumbers}
        PaperProps={{ classes: {root: classes.dialogPaper } }}
      >
        <Grid container className={classes.dialogContainer}>
          <Grid item xs={12} className={classes.dialogTitle}>
            <Typography variant="h2" gutterBottom>
              {companyDetails.name}
            </Typography>
          </Grid>
            {companyDetails.contact_phone && companyDetails.contact_phone.split(',').map((phoneNumber) => {
                let  filteredContactPhone = phoneNumber.replace(/\s/gi, '');
                let endIndexOfFilteredContactPhone = filteredContactPhone.search(/[(]/g);
                if(endIndexOfFilteredContactPhone != -1){
                  filteredContactPhone = filteredContactPhone.slice(0, endIndexOfFilteredContactPhone);
                }

                if(filteredContactPhone.match(/[+0-9]/gi))
                  return(
                    <Grid item xs={12}>
                      <Link href={`tel:${filteredContactPhone}`}>
                        {phoneNumber}
                      </Link>
                    </Grid>
                  )
            })}   
          <Grid item xs={12} align="right">
            <Button variant="contained" onClick={() => setShowNumbers(false)} color="primary" className={classes.closeButton}>
              Sulje
            </Button>
          </Grid>
        </Grid>

      </Dialog>

    </Grid>
  )
}

export default Taxi;