import { makeStyles } from '@material-ui/styles';
import BackgroundImage from 'assets/TilaaTaksi_099.jpg'

export default makeStyles(theme => ({
  navContainer: {
    
  },
  logo: {
    height: 50,
    margin: 24,
  },
  logoMobile: {
    height: 50,
    padding: 8,
  },
  introContainer: {
    minHeight: '100vh',
    paddingTop: 32,
    paddingBottom: 32,
    //padding: 64,
    
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundImage: " linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + BackgroundImage + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
  },
  introItem: {
    //paddingTop: 32,
    //paddingBottom: 32,
  }

}));