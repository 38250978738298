import { makeStyles } from '@material-ui/styles';
import BackgroundImage from 'assets/TilaaTaksi_099.jpg'

const drawerWidth = 240;

export default makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      outline: '1px solid slategrey'
    }
  },
  
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.white,
    padding: 16,
    color: theme.palette.background.dark,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    
  },
  drawer:{
    backgroundColor: theme.palette.background.dark, 
    fontWeight: 'bold',
  },
  drawerOpen: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerClose: {
    width: drawerWidth,
    flexShrink: 1,
  },
  drawerPaper: {
    marginTop: 96,
    paddingBottom: 96,
    width: drawerWidth,
    background: theme.palette.background.dark,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 4,
  },
  listItemTypography: {
    color: theme.palette.text.light,

  },
  listNavActive: {
    '& p': {
      color: theme.palette.primary.light,
    }
  },
  listMainIcon: {
    color: theme.palette.text.light,
    width:16
  },
  listIconContainer: 
  {
    paddingLeft:24
  },
  listIcon: 
  {
    color: theme.palette.text.light,
    width:12
  },
  badgeMainListItem: {
    top: "24px",
    right: "-8px",
  },
  badgeListItem: {
    top: "16px",
    right: "-8px",
  },
  badgeList: {
    position: "relative",
    left: "32px"
  },
  content: {
    
    flexGrow: 1,
    backgroundColor: theme.palette.background.light, 
    minHeight: '100vh',
    paddingTop: 96,
    paddingBottom: 0,
    paddingLeft:0,
    paddingRight:0,
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundImage: " linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + BackgroundImage + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
  },
  toolbar: theme.mixins.toolbar,
  logoContainer: {
    padding: '16px',
  },
  logo: {
    maxWidth: '200px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  
}));