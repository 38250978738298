export default theme => ({
  gridContainer: {
    color: theme.palette.common.white,
    height: '100%',
  },
  boxContainer: {
    position: 'relative'
  },
  titleTextContainer: {
     textAlign: 'center'
  },
  icon: {
    fontSize: '3rem'
  },
  statusContainer: {
    color: theme.palette.common.white,
    position: 'absolute',
    right: '12px',
    top: '8px'
  },
  statusOk: {
    color: theme.palette.common.green,
  },
  statusWarning: {
    color: theme.palette.secondary.main,
  },
})