import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import UsersList from './UsersList'

const mapStateToProps = state => ({
  myCompany: state.companies?.data?.me,
  myUser: state.users?.data?.me,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsersList)
)
