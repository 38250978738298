import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {getUserMe} from '~/state/users'
import {getMyCompany} from '~/state/companies'
import MapView from './MapView';

const mapStateToProps = state => ({
  myUser: state.users?.data?.me,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getUserMe,
    getMyCompany,
  }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MapView);