export default {
  primary: {
    main: "#6a31dc",
    light: "#b498ed",
    dark: "#1f0e42",
    contrastText: "#fff"
  },
  success: {
    main: "#40B600",
    light: "#C1FF9E",
    dark: "#318700",
    contrastText: "#fff"
  },
  error: {
    main: "#D80000",
    light: "#D88484",
    dark: "#910000",
    contrastText: "#fff"
  },
  text: {
    light: "#fff",
    dark: "#000",
    grey: "#ccc",
    primary: "#000",
    green: "#9EDF75",
  },
  background: {
    white: "#fff",
    light: "#F0F4F5",
    dark: "#303635",
    panel: "#F0F0F0",
  },
  button: {
    default: {
      text: '#000',
      background: '#D0D0D0'
    }
  },
  table: {
    
    border: "#EAEAEA",
    thead: {
      text: "#5D5D5D",
      background: "#F5F6FA",
    }
  }
}
