import http from '~/services/http/httpInstance'
import { authHeader } from '~/services/http/AuthService'

const url = '/companies/'

export const createCompany = (request) =>
  http({
    ...request,
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
  })
  
export const getCompanies = (request) =>
  http({
    ...request,
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
  
export const httpGetMyCompany = () =>
  http({
    url: url + "me/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
  
export const saveCompany = (request) =>
  http({
    url: request?.data?.id ? url + request?.data?.id + "/" : url,
    method: request?.data?.id ? 'PATCH' : 'POST',
    headers: {
      ...authHeader(),
    },
    data: request.data
  })
  
export const httpInviteUser = (request) =>
  http({
    url: url + "me/invite_user/",
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data: request?.data
  })
  
export const httpGetInvitedUsers = (request, companyId) =>
  http({
    url: url + "me/company_user_role_invites/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpInviteResend = (id) =>
  http({
    url: url + `me/company_user_role_invites/${id}/resend_invitation/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
  })
  
export const httpAcceptInviteToken = (companyId, token) =>
  http({
    url: url + companyId + "/accept_invitation/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params: {
      token: token
    }
  })
  
export const httpCompleteStripeAccount = (code) => {
    const header = authHeader();
    let token = header.authorization;
    token = token.replace("JWT ","");

    return http({
      url: url + "me/complete_stripe_account_connection/",
      method: 'GET',
      headers: {
        ...authHeader(),
      },
      params: {
        token: token,
        code,
      }
    })
  }
  
export const httpRetriveStripeAccount = () => {
    return http({
      url: url + "me/retrieve_stripe_account/",
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
  }
  
  
export const httpGetExcelReceipts = (params) =>
  http({
    url: url + "me/get_receipt_summary_xlsx/",
    method: 'GET',
    responseType: 'blob',
    headers: {
      ...authHeader(),
      'content-type': 'octet/stream',
    },
    params,
  })
  
export const httpGetExcelServiceCharges = (params) =>
  http({
    url: url + "me/get_service_charge_summary_xlsx/",
    method: 'GET',
    responseType: 'blob',
    headers: {
      ...authHeader(),
      'content-type': 'octet/stream',
    },
    params,
  })
