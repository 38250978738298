import palette from '../palette';

export default {
  root: {
    color: palette.text.light,
        
    MuiTypography: {
      root: {
        color: palette.text.light,
      }
    }
  },
}