import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AuthService from 'services/http/AuthService'
const auth = new AuthService();

export default function withAuth(ComponentToProtect) {
  
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    componentDidMount() {
      
      auth.loggedIn().then((response) => {
        if (response && response == true)
        {
          this.setState({ loading: false, redirect: false });
        }
        else
        {
          this.setState({ loading: false, redirect: true });
        }
      });
    }


    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      
      if (redirect) {
        return (
          <Redirect to="/login" />
        );
      }
      else
      {
        return (
          <React.Fragment>
            <ComponentToProtect {...this.props} />
          </React.Fragment>
        );
      }
      
    }
  }
}



