import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VehicleSelect from "./VehicleSelect";

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSelect);
