import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VehicleColorSelect from "./VehicleColorSelect";

const mapStateToProps = state => ({
  vehicleColors: state.enums?.data?.vehicles?.car?.color,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleColorSelect);
