import 'date-fns';
import moment from 'moment'
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

// https://material-ui-pickers.dev/api/KeyboardDatePicker
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
//import MomentUtils from "@date-io/moment";
//import "moment/locale/fi";
import fiLocale from "date-fns/locale/fi";

// moment.locale("fi");
// import {es} from 'date-fns/esm/locale'
// registerLocale('es', es)
// https://github.com/Hacker0x01/react-datepicker/issues/1815

const DateInput = (props) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState() // React.useState(new Date('2014-08-18T21:11:54'));

  useEffect(() => {
    if (props.value)
    {
      setSelectedDate(new Date(props.value))
    }
  }, [props.value])
  
  const handleDateChange = date => {
    setSelectedDate(date);
    props.updateValues({ [props.name]: date })
  };
  
  const handleFormChange = ({ target }) => {
    
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          cancelLabel={t("Peruuta")}
          okLabel={t("OK")}
          fullWidth
          margin="dense"
          id={props.id || "date_input"}
          label={props.label}
          format="dd.MM.yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputVariant="outlined"
          invalidLabel={t("Väärässä muodossa")}
          
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DateInput;