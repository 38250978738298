import { actionTypes } from "redux-resource";
import { getVehicles } from 'services/http/vehicles'
import { getDrivers } from 'services/http/drivers'

const resourceType = "analytics";

export const getMyAnalytics = () => {
  
  const requestKey = "getMyAnalytics";

  return dispatch => {
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey
    });
    
    let analytics = {}
    
    return new Promise((resolve, reject) => {
      getVehicles({}).then((response) => {
        analytics.vehicles = response.length;
        
        getDrivers({params:{drivers:true}}).then((response) => {
          analytics.drivers = response.length;
          
          dispatch({
            data: {
              me: {
                ...analytics,
              }
            },
            mergeListIds: false,
            resourceType,
            requestKey,
            resources: [],
            type: actionTypes.READ_RESOURCES_SUCCEEDED
          });
          
          return resolve(analytics);
          
        });
        
      });
      
      /*
      dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: error.status
          }
        });
        return Promise.reject(error);*/
      
      
    })
    
  };
};
