import React from 'react';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';

import styles from './orderButton.css';

const OrderButton = (props) => {

  const classes = styles();
  
  return (
    <Link to="/taxi">
      <Button variant="contained" className={`${classes.button} ${props.reverseColor ? classes.buttonColorReverse : classes.buttonColor} ${props.centerAlign ? classes.centerAlign : null}`}>
        <Grid container alignItems="center">
          {props.content}
          <ArrowRightAlt className={classes.icon}/>
        </Grid>
      </Button>
    </Link>
  )
}

export default OrderButton;
