import React, { Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import MyTextField from "components/Inputs/MyTextField"
import VehicleTypeSelect from 'components/Inputs/VehicleTypeSelect'

const useStyles = makeStyles(theme => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1
  },

}));

const CompanyForm = (props) =>
{
  // hooks
  const [loading, setLoading] = useState(false);
  const [validFields, setValidFields] = useState({
    receiverPhone:false,
    receiverEmail:false,
  });
  const [driverType, setDriverType] = useState(0);
  
  // props
  const { values, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  useEffect(() => {
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  const handleDriverType = (event, newType) => {
    setDriverType(newType);
  };
  
  const handleFormChange = ({ target }) => {
    let { value, name } = target;
    
    if (["contact_email"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    
    updateValues({ [target.name]: value })
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: {id:target.value} })
  }
  
  return (
    <Fragment>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={12} md={6}>
          
            <Grid container direction="row" spacing={1}>
              
              <Grid item xs={12}>
                <MyTextField
                  autoFocus
                  customLabel={t('Yrityksen nimi')}
                  required
                  id="company-name"
                  name="name"
                  value={values.name || ""}
                  onChange={handleFormChange}
                  inputProps={{
                    readOnly: values?.checked ? false : true,
                  }}
                />
              </Grid>
              
              <Grid item xs={12}>
                
                <MyTextField
                  autoFocus
                  customLabel={t('Y-tunnus')}
                  required
                  id="company-business_id"
                  name="business_id"
                  value={values.business_id || ""}
                  onChange={handleFormChange}
                  inputProps={{
                    readOnly: values?.checked ? false : true,
                  }}
                />
                
              </Grid>
              
              <Grid item xs={12}>
                
                <Typography variant="subtitle1">
                  {t('Yhteyshenkilön sähköposti')}
                </Typography>
                
                <TextValidator
                  className={classes.textField}
                  errorMessages={['Tarkista sähköpostiosoitteen muoto']}
                  id="company-form-contact_email"
                  inputProps={{
                    readOnly: values?.checked ? false : true,
                  }}
                  fullWidth
                  margin="dense"
                  name="contact_email"
                  onChange={handleFormChange}
                  required={true}
                  shrink="true"
                  type="email"
                  validators={['isEmail']}
                  validatorListener={(isValid) => {
                      if (isValid && values.contact_email && values.contact_email.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          contact_email: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          contact_email: false,
                        });
                      }
                    }
                  }
                  value={values.contact_email || ""}
                  variant="outlined"
                  
                />
                
                
                {/*
                <MyTextField
                  autoFocus
                  customLabel={t('Yhteyshenkilön sähköposti')}
                  required
                  errorMessages={['Tarkista sähköpostiosoitteen muoto']}
                  id="company-form-contact_email"
                  name="contact_email"
                  type="email"
                  validators={['isEmail']}
                  validatorListener={(isValid) => {
                      if (isValid && values.contact_email && values.contact_email.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          contact_email: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          contact_email: false,
                        });
                      }
                    }
                  }
                  value={values.contact_email || ""}
                  onChange={handleFormChange}
                  inputProps={{
                    readOnly: values?.checked ? false : true,
                  }}
                />
                */}
                
              </Grid>
              
              <Grid item xs={12}>
                
                <Typography variant="subtitle1">
                  Yhteyshenkilön puhelin
                </Typography>
                
                <TextValidator
                  className={classes.textField}
                  errorMessages={['Puhelinnumeron on oltava kansainvälisessä muodossa',]}
                  fullWidth
                  helperText="Käytä vain kansainvälistä muotoa"
                  id="company-form-company-phone"
                  inputProps={{
                    readOnly: values?.checked ? false : true,
                  }}
                  margin="dense"
                  name="contact_phone"
                  onChange={handleFormChange}
                  required={true}
                  shrink="true"
                  validators={['matchRegexp:(([+][(]?[0-9]{1,3}[)]?)([(]?[0-9]{1,4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$']}
                  validatorListener={(isValid) => {
                      if (isValid && values.contact_phone && values.contact_phone.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          contact_phone: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          contact_phone: false,
                        });
                      }
                    }
                  }
                  value={values.contact_phone || ""}
                  variant="outlined"
                />

              </Grid>

            </Grid>
          
          </Grid>
        
          
        
        </Grid>


    </Fragment>
  )
  
}

export default CompanyForm;
