import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import ButtonPrimary from '~/components/Buttons/ButtonPrimary'
import { getUsers } from 'services/http/users'
import { httpGetInvitedUsers } from 'services/http/companies'

const useStyles = makeStyles(theme => ({
  buttonLink: {
    textDecoration:'none',
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: theme.palette.table.thead.background,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },

}))

const UsersList = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myCompany, myUser } = props;
  
  const [rows, setRows] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    handleGetUsers();
    handleGetUserInvites();
  },[]);
  
  const handleGetUsers = () => {
    setLoading(true);
    const request = {
        params: {
          admins:true,
          drivers: true,
          company: myCompany?.id,
        }
    };
    
    getUsers(request).then((response) => {
        let _users = [];
        response.map((user) => {
          if (user.status == 100)
          {
            _users.push(user);
          }
        })
        setRows(_users);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  }
  
  const handleGetUserInvites = () => {
    setLoading(true);
    const request = {};
    
    httpGetInvitedUsers(request).then((response) => {
        let _users = [];
        response.map((user) => {
          if (user?.status == 100)
          {
            _users.push(user);
          }
        })
        setInvitedUsers(_users);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  }
  
  
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          <Typography variant="h4">
            {t('Käyttäjät')}
          </Typography>
        </Grid>
        
        <Grid item>
          {myUser?.status == 100 ? (
            <Link
              className={classes.buttonLink}
              to={'/dashboard/users/invite'}
            >
              <ButtonPrimary>
                {t('Kutsu admin käyttäjä')}
              </ButtonPrimary>
            </Link>
          ) : null }
        </Grid>
        
      </Grid>
    
      <Box marginBottom={4}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Nimi')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Käyttäjätunnus')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Sähköposti')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Puhelin')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        
        { loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan="5">
                <SpinnerCentered />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">{row.first_name} {row.last_name}</Typography>

                </TableCell>
                <TableCell>
                  {row.username}
                </TableCell>
                <TableCell>
                  {row.email}
                </TableCell>
                <TableCell>
                  {row.phone}
                </TableCell>
                <TableCell align="right">

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      </Box>
      
      <Box marginBottom={2}>
        <Typography variant="h4">
          {t('Käyttäjäkutsut')}
        </Typography>
      </Box>
      
      <Box marginBottom={4}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Sähköposti')}</TableCell>
          </TableRow>
        </TableHead>
        
        { loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan="5">
                <SpinnerCentered />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {invitedUsers.map(row => (
              <TableRow key={row.name}>
                <TableCell>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      </Box>
          
    </Paper>
  )
}

export default UsersList;