import React, { Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import MyTextField from "components/Inputs/MyTextField"
import VehicleTypeSelect from 'components/Inputs/VehicleTypeSelect'

const useStyles = makeStyles(theme => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1
  },

}));

const CompanyPaymentForm = (props) =>
{
  // hooks
  const [loading, setLoading] = useState(false);
  const [validFields, setValidFields] = useState({
    receiverPhone:false,
    receiverEmail:false,
  });
  
  // props
  const { values, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  useEffect(() => {
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: {id:target.value} })
  }
  
  return (
    <Fragment>
        <Grid container spacing={1}>
          
          <Grid item>
          
            <Box marginBottom="32px">

              <Grid container direction="row" spacing={1}>
                
                <Grid item xs={12}>
                  <MyTextField
                    disabled={true}
                    autoFocus
                    customLabel={t('Yrityksen nimi')}
                    id="business_profile_name"
                    name="business_profile_name"
                    value={values?.business_profile?.name || ""}
                    onChange={handleFormChange}
                    inputProps={{
                      readOnly: values?.checked ? false : true,
                    }}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  
                  <MyTextField
                    disabled={true}
                    customLabel={t('Osoite')}
                    id="street_address"
                    name="street_address"
                    value={values?.business_profile?.support_address?.line1 || ""}
                    onChange={handleFormChange}
                    inputProps={{
                      readOnly: values?.checked ? false : true,
                    }}
                  />
                  
                </Grid>
                
                <Grid item xs={12}>
                  
                  <MyTextField
                    disabled={true}
                    customLabel={t('Paikkakunta')}
                    id="company-city"
                    name="city"
                    value={values?.business_profile?.support_address?.city || ""}
                    onChange={handleFormChange}
                    inputProps={{
                      readOnly: values?.checked ? false : true,
                    }}
                  />
                  
                </Grid>
                
                <Grid item xs={12}>
                  
                  <MyTextField
                    disabled={true}
                    customLabel={t('Puhelin')}
                    id="business_profile_support_phone"
                    name="business_profile_support_phone"
                    value={values?.business_profile?.support_phone || ""}
                    onChange={handleFormChange}
                    inputProps={{
                      readOnly: values?.checked ? false : true,
                    }}
                  />
                  
                </Grid>
                
                <Grid item xs={12}>
                  
                  <MyTextField
                    disabled={true}
                    customLabel={t('Sähköposti')}
                    id="email"
                    name="email"
                    value={values?.email || ""}
                    onChange={handleFormChange}
                    inputProps={{
                      readOnly: values?.checked ? false : true,
                    }}
                  />
                  
                </Grid>
                
              </Grid>
            
            </Box>
            
            <Box marginBottom="16px">
            
              <Grid container direction="row" spacing={1}>
                
                { values?.external_accounts?.data && values?.external_accounts?.data.length > 0 ? (
                <Fragment>
                  { values?.external_accounts?.data.map((account, i) => (
                    <Fragment>
                      
                      <Grid item xs={12}>
                        <MyTextField
                          disabled={true}
                          customLabel={t('Tili')}
                          id="account"
                          name="account"
                          value={account.account || ""}
                          onChange={handleFormChange}
                          inputProps={{
                            readOnly: values?.checked ? false : true,
                          }}
                        />
                      </Grid>
                      
                      <Grid item xs={12}>
                        <MyTextField
                          disabled={true}
                          customLabel={t('Pankki')}
                          id="bank_name"
                          name="bank_name"
                          value={account.bank_name || ""}
                          onChange={handleFormChange}
                          inputProps={{
                            readOnly: values?.checked ? false : true,
                          }}
                        />
                      </Grid>
                      
                      <Grid item xs={12}>
                        <MyTextField
                          disabled={true}
                          customLabel={t('Maa')}
                          id="country"
                          name="country"
                          value={account.country || ""}
                          onChange={handleFormChange}
                          inputProps={{
                            readOnly: values?.checked ? false : true,
                          }}
                        />
                      </Grid>
                      
                      <Grid item xs={12}>
                        <MyTextField
                          disabled={true}
                          customLabel={t('Valuutta')}
                          id="currency"
                          name="currency"
                          value={account.currency || ""}
                          onChange={handleFormChange}
                          inputProps={{
                            readOnly: values?.checked ? false : true,
                          }}
                        />
                      </Grid>
                      
                      
                    </Fragment>
                  ))}
                
                </Fragment>
                ) : null }
                
                
              </Grid>
              
            </Box>
          
          </Grid>
        
          
        
        </Grid>


    </Fragment>
  )
  
}

export default CompanyPaymentForm;
