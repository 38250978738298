import React, { Fragment, useState, useEffect } from 'react';
import queryString from 'query-string'
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonPrimary from 'components/Buttons/ButtonPrimary'

import DriverForm from 'components/DriverForm/DriverForm';
import { httpAcceptInviteToken } from 'services/http/companies'

import AuthService from 'services/http/AuthService'
const auth = new AuthService();

const styles = theme => ({
  fieldGrid: {
    marginBottom: 12
  },
  toggleButton: {
    
  },
  toggleButtonSelected: {
    color: '#fff' + ' !important',
    backgroundColor: theme.palette.primary.main + ' !important',
  },
});

const RegisterUser = (props) => {
  
  const [values, setValues] = useState({
    username: '',
    password: '',
  })
  
  const [invitationToken, setInvitationToken] = useState(null);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  const [terms, setTerms] = useState(false);
  
  const [registerState, setRegisterState] = useState(0);

  // props
  const { t } = useTranslation();
  const { classes, login, history, location, enqueueSnackbar } = props;
  const { search } = location;
  
  useEffect(() => {
    if (search)
    {
      const parsed = queryString.parse(search);
      
      if (parsed.token && parsed.token.length < 64)
      {
        // Token is most likely partial
        enqueueSnackbar(t("Kutsulinkki on virheellinen. Tarkista, että sähköpostissa saatu linkki on täydellinen."), { variant: "error" });
        history.push('/');
      }
      
      if (parsed.token && parsed.company)
      {
        setInvitationToken(parsed.token);
        handleAcceptInviteToken(parsed.company, parsed.token);
      }
    }
  }, [search])
  
  const handleAcceptInviteToken = (companyId, token) => {
    // Fetch prefilled user data
    setSaving(true);
    httpAcceptInviteToken(companyId, token).then((response) => {
      setValues({
        ...response
      });
      setSaving(false);
    }, (error) => {
      setSaving(false);
    });
  }
  
  const handleTerms = (event, newTerms) => {
    if (newTerms == null) return; 
    setTerms(newTerms);
  };
  
  const handleInputChange = (event) => {
    let { value, name } = event.target;
    
    if (["email", "username"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    
    setValues({
      ...values,
      [name]: value
    });
  }
  
  const onSubmit = (event) => {
    event.preventDefault();
    
    validateForm().then((response) => {
      console.log("handleConfirmSend good");
      handleRegister();
    }, (errors) => {
      console.log("handleConfirmSend no good");
    })
    
  }

  const handleRegister = () => {
    setSaving(true);
    const request = {
      data: {
        ...values,
        username: values.email.toLowerCase(),
        email: values.email.toLowerCase(),
        accept_terms: true,
        name: values.first_name + " " + values.last_name,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password1,
      },
      params: {
        token: invitationToken,
      }
    }
    auth.register(request).then((response) => {
      auth.login(values.email.toLowerCase(), values.password1).then((response) => {
        setRegisterState(1);
        setSaving(false);
      }, (errors) => {
        handleErrors(errors);
        setSaving(false);
      });
    }, (errors) => {
      handleErrors(errors);
      setSaving(false);
    });
    
  }
  
  const handleErrors = (errors) => {
    let message = t('Tarkista lomakkeen kentät. Jos ongelma jatkuu ota yhteyttä tukeemme support@opentaxi.fi');
    if (errors?.data?.user || errors?.data?.username)
    {
      // message = errors?.data?.user[0]
      message = t('Sähköposti on jo rekisteröity palveluun. Asiakastilin voi poistaa menemällä mobiilisovelluksessa -> Omat tiedot -> Poista profiili. Ongelmatilanteissa ota yhteys tukeemme support@opentaxi.fi')
    }
    setErrors({
      message: message,
    });
  }
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const goBack = () => {
    setRegisterState(0);
  }
  
  const goDashboard = () => {
    history.push('/dashboard/start');
  }
  
  const validationSchema = yup.object().shape({
    password2: yup
        .string()
        .required(t("Pakollinen kenttä"))
        .min(8, t("Salasanan on oltava vähintään 8 merkkiä"))
        .oneOf([yup.ref('password1'), null], t("Salasanan on täsmättävä uusi salasana kentän kanssa"))
        ,
    password1: yup
        .string()
        .required(t("Pakollinen kenttä"))
        .min(8, t("Salasanan on oltava vähintään 8 merkkiä"))
        ,
  });
  
  const validateForm = () => {
    return new Promise((resolve, reject) => {
      
      validationSchema.validate(values, {abortEarly: true}).then(() => {
        // GOOD
        setErrors({});
        resolve(true);
      }).catch((err) => {
        // INVALID
        setErrors({
          [err.path]:t(err?.message),
          inValid: true,
        })
        reject("NO_GOOD");
      });
    })
  }

  return (
      <Fragment>
      { registerState == 0 ? (
        <ViewContainer title={t('Rekisteröidy')}>
              
              <ValidatorForm onSubmit={onSubmit}>
              
                <Grid 
                  container
                  className={classes.container}
                  >
                  
                  <Grid item xs={12} className={classes.fieldGrid}>
                    <Typography variant="subtitle1">{t('Etunimi')}</Typography>
                    <TextField
                      fullWidth
                      id="register-first_name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleInputChange}
                      margin="dense"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} className={classes.fieldGrid}>
                    <Typography variant="subtitle1">{t('Sukunimi')}</Typography>
                    <TextField
                      fullWidth
                      id="register-last_name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleInputChange}
                      margin="dense"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} className={classes.fieldGrid}>
                    <Typography variant="subtitle1">{t('Sähköposti / käyttäjätunnus')}</Typography>
                    <TextField
                      fullWidth
                      id="register-email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      type="email"
                      margin="dense"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} className={classes.fieldGrid}>
                    <Typography variant="subtitle1">{t('Salasana')}</Typography>
                    <TextField
                      fullWidth
                      id="register-password1"
                      name="password1"
                      type="password"
                      value={values.password1}
                      onChange={handleInputChange}
                      margin="dense"
                      variant="outlined"
                      required
                      error={errors?.password1}
                      helperText={errors?.password1}
                    />
                  </Grid>
                  
                  <Grid item xs={12} className={classes.fieldGrid}>
                    <Typography variant="subtitle1">{t('Salasana uudelleen')}</Typography>
                  
                    <TextField
                      fullWidth
                      id="register-password2"
                      name="password2"
                      type="password"
                      value={values.password2}
                      onChange={handleInputChange}
                      margin="dense"
                      variant="outlined"
                      required
                      error={errors?.password2}
                      helperText={errors?.password2}
                    />
                  </Grid>
                  
                  <Box marginBottom="16px">
                    <DriverForm values={values} updateValues={updateValues} fields={['phone']} />
                  </Box>
                  
                  
                  
                  <Grid item xs={12}>
                    <Box marginBottom="16px">
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <a href="https://opentaxi.fi/legal" target="_blank">
                            <Typography variant="subtitle1">{t('Käyttöehdot')}</Typography>
                          </a>
                        </Grid>
                        
                        <Grid item>
                          <ToggleButtonGroup
                            value={terms}
                            exclusive
                            onChange={handleTerms}
                            aria-label="text alignment"
                          >
                            <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={true} aria-label="left aligned">
                              {t('Hyväksyn')}
                            </ToggleButton>
                            <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={false} aria-label="right aligned">
                              {t('En')}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  

                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          {t('Haluatko palvelun käyttöön?')}
                          <a href="https://www.opentaxi.fi/laheta-yrityksesi-tiedot/" target="_blank">
                            <Typography variant="subtitle2">{t('Ota yhteyttä')}</Typography>
                          </a>
                        </Typography>
                        
                      </Grid>
                      <Grid item>
                        <ButtonPrimary type="submit" loading={saving} disabled={terms==false}>
                            {t('Rekisteröidy')}
                        </ButtonPrimary>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  { errors ? (
                  <Fragment>
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      {t('Rekisteröinti epäonnistui.')}
                    </Typography>
                    
                    <Box>
                    {
                      Object.keys(errors).map((key, index) => (
                        <Box><Typography key={index} variant="body" color="error" gutterBottom>
                          {errors[key]}
                        </Typography></Box>
                      ))
                    }
                    </Box>
                    
                  </Fragment>
                  ) : null }
                  
                  </Grid>
              </ValidatorForm>
        
        </ViewContainer> ) : null}
            
        { registerState == 1 ? (
        <ViewContainer title={t('Olet rekisteröitynyt onnistuneesti!')}>
          <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
            <Grid item>
              <Typography variant="subtitle1">
                
              </Typography>
            </Grid>
            <Grid item>
              <ButtonPrimary onClick={() => goDashboard()}>
                  {t('Jatka sisään palveluun')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </ViewContainer> ) : null}
        
        { registerState == 2 ? (
        <ViewContainer title={t('Rekisteröinti epäonnistui!')} type="error">
        
          <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
            <Grid item>
              <Typography variant="subtitle1">
                {t('Palvelu on käytössä vain kutsutuille yrityksille. Ota yhteyttä, jos haluat palvelun käyttöön!')}
              </Typography>
            </Grid>
            
            <Grid item>
              <ButtonPrimary onClick={() => goBack()}>
                  {t('Takaisin')}
              </ButtonPrimary>
            </Grid>
          </Grid>
          
        </ViewContainer> ) : null}

    </Fragment>
    );
  
}

export default withStyles(styles)(RegisterUser);