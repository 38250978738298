import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  iconCardContainer: {
    padding: 8,
  },
  icon: {
    fontSize: 48,
    display: 'block',
    color: "#7a7a7a",
    marginBottom: 8,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      marginRight: 8,
    },
  },
  iconText: {
    maxWidth: 'calc(100% - 56px)',
  },
}));