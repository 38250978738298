import http from 'services/http/httpInstance'
import { authHeader } from 'services/http/AuthService'

const url = '/orders/'

export const httpGetOrders = (request) =>
  http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpGetOrder = (request, orderId) =>
  http({
    url: url + orderId + "/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpGetReceipt = (orderId) =>
  http({
    url: url + orderId + "/get_driver_receipt/",
    method: 'GET',
    responseType: 'blob',
    headers: {
      ...authHeader(),
      'content-type': 'octet/stream',
    },
  })
  
export const httpGetOrdersAnalytics = () =>
  http({
    url: url + 'analytics/',
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
 