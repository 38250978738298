import { actionTypes } from "redux-resource";
import moment from "moment";
import { httpGetEnums } from "~/services/http/enums";
const resourceType = "enums";

export const getEnums = () => {
  const requestKey = "getEnums";

  return dispatch => {
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey
    });

    return httpGetEnums()
      .then(data => {
        console.log("httpGetEnums", data);
        dispatch({
          data: {
            ...data
          },
          mergeListIds: false,
          resourceType,
          requestKey,
          resources: [],
          type: actionTypes.READ_RESOURCES_SUCCEEDED
        });

        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: error.status
          }
        });
        return Promise.reject(error);
      });
  };
};
