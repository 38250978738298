import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';

import img1 from 'assets/img1.png';
import img2 from 'assets/img2.png';
import Navigation from 'components/Navigation/navigation';
import OrderButton from 'components/Buttons/orderButton';
import IconCard from 'components/Cards/iconCard';
import KpiCard from 'components/Cards/kpiCard';
import styles from './Home.css';

const Home = (props) => {
  const smallScreen = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation();
  const classes = styles();

  const [analytics, setAnalytics] = useState({});
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const [analyticsError, setAnalyticsError] = useState(false);

  useEffect(() => {
    axios({
      method: 'get',
      baseURL: 'https://app.opentaxi.fi/api/',
      url: '/analytics/',
      headers: { 'content-type': 'application/json' },
    })
    .then(response => {
      setAnalytics(response.data);
      setAnalyticsLoaded(true);

    })
    .catch(error => {
      setAnalyticsError(true);
      setAnalyticsLoaded(true);
    });
  }, [])
  
  return (
    <Fragment>
      <Grid container direction="column" className={classes.homePageContainer}>
        <Navigation value={0}/>
        <Grid item xs={12}>
          <Grid container alignContent="center" alignItems="center" className={classes.introContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" className={classes.titleText}>
                Yksi Alusta
              </Typography>
              <Typography variant="h3" align="center" className={classes.skinnyText} gutterBottom className={classes.titleText}>
                Kaikki Kuljettajat
              </Typography>
              <Typography align="center" className={classes.titleText}>
                Taksit Hangosta Nuorgamiin
              </Typography>
              <OrderButton content="Tilaa taksi" centerAlign={true}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.aboutContainer}>
          <Grid container alignItems="center" direction="row">

            <Grid item xs={12} lg={6}>
              <Typography variant="h2" gutterBottom>
                Uuden sukupolven liikkumispalvelu
              </Typography>
              <Typography gutterBottom>
                Olemme rakentamassa digitaalista palvelua, joka tekee liikkumisesta täysin uudenlaista.
              </Typography>
              <Typography>
                Opentaxin avulla löydät taksin kaikkialta Suomesta, varmistat ennakkotilauksen suoraan kuljettajalta ja voit sopia hinnan etukäteen. 
              </Typography>
              <OrderButton content="Tilaa taksi" />
            </Grid>

            <Grid item xs={12} lg={6} className={classes.animatedImageContainer}>
              <Slide direction="left" in={true} timeout={500}>
              <img src={img1} alt="logo" className={classes.image}/>
              </Slide>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row">

                <Grid item xs={12} md={6} lg={3}>
                  <IconCard icon={0} content="Avoin ja ilmainen palvelu kaikille kuljetusyrityksille." />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <IconCard icon={1} content="Yksi alusta ja sadat kuljettajat, jotta saisit aina kyydin." />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <IconCard icon={2} content="Ennakkotilaus suoraan kuljettajalta ja varmistat kyydin haluttuun aikaan." />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <IconCard icon={3} content="Pro-Palvelut takseille ja kuljetusyrityksille." />
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.moreContainer}>
          <Typography variant="h2" align="center" className={classes.moreText} gutterBottom>
            Haluatko Tietää Lisää? 
          </Typography>
          <Typography variant="h3" align="center" className={`${classes.skinnyText} ${classes.moreText}`} gutterBottom>
            Ota Yhteyttä
          </Typography>
          <Typography align="center" className={classes.moreText} gutterBottom>
            Tarkista ja täydennä tietosi
          </Typography>
          <Typography align="center" className={classes.moreText} gutterBottom>
            Haluatko mukaan palveluun?
          </Typography>
          <div className={classes.linkContainer}>
            <Link href="mailto:info@opentaxi.fi" className={classes.moreLink}>
              info@opentaxi.fi
            </Link>
          </div>
          <OrderButton content="Tilaa taksi" centerAlign={true} reverseColor={true}/>
        </Grid>

        <Grid item xs={12} className={classes.proContainer}>
          <Typography variant="h2" gutterBottom align="center">
            Pro-Palvelut Yrityksille
          </Typography>
          <Grid container className={classes.proValueContainer}>
            <Grid id="kpiCounterStart" item xs={12}>
              <Grid container className={classes.proValueContainerLimiter}>
                <Grid item xs={12} md={4} align="center">
                  {!analyticsError
                    ?
                      <>
                        {analyticsLoaded
                          ?
                            <KpiCard value={analytics && analytics.city ? analytics.city.count : 0 } title="Paikkakuntaa"/>
                          :
                            <CircularProgress />
                        }
                      </>
                    :
                      <Typography>
                        Tietoja ei saatavilla
                      </Typography>
                  }
                </Grid>
                {smallScreen
                  ?
                    <Grid item className={classes.dividerContainer}>
                      <Divider />
                    </Grid>
                  :
                    null
                }
                <Grid item xs={12} md={4} align="center">
                  {!analyticsError
                    ?
                      <>
                        {analyticsLoaded
                          ?
                            <KpiCard value={analytics && analytics.city ? analytics.company.count : 0} title="Kuljetusyritystä"/>
                          :
                            <CircularProgress />
                        }
                      </>
                    :
                      <Typography>
                        Tietoja ei saatavilla
                      </Typography>
                  }
                </Grid>
                {smallScreen
                  ?
                    <Grid item className={classes.dividerContainer}>
                      <Divider />
                    </Grid>
                  :
                    null
                }
                <Grid item xs={12} md={4} align="center">
                  {!analyticsError
                    ?
                      <>
                        {analyticsLoaded
                          ?
                            <KpiCard value={analytics && analytics.orders ? analytics.orders.day.count : 0} title="Yhteydenotot"/>
                          :
                            <CircularProgress />
                        }
                      </>
                      
                    :
                      <Typography>
                        Tietoja ei saatavilla
                      </Typography>
                  }
                </Grid>
                {smallScreen
                  ?
                    <Grid item className={classes.dividerContainer}>
                      <Divider />
                    </Grid>
                  :
                    null
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.applicationContainer}>
          <Grid container alignItems="center">

            <Grid item xs={12} lg={6}>
              <img src={img2} alt="logo" className={classes.image}/> 
            </Grid>

            <Grid item xs={12} lg={6} className={classes.applicationTextContainer}>
              <Grid container align="center">

                <Grid item xs={12}>
                  <Typography variant="h2" gutterBottom>
                    OpenTaxi
                  </Typography>
                  <Typography gutterBottom>
                    Pian tulossa sovelluskauppoihin!
                  </Typography>
                  <Typography gutterBottom>
                    Sovellus, jolla tilaat taksin kaikkialta Suomesta.
                  </Typography>
                  <Typography>
                    Seuraa uutisiamme verkossa.
                  </Typography>
                </Grid>

                <Grid item className={classes.dividerContainer}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <svg className={classes.socialMediaIcon} viewBox="0 0 32 32">
                    <path fill="#000000" d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
                  </svg>
                  <svg className={classes.socialMediaIcon} viewBox="0 0 32 32">
                    <path fill="#000000" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                  </svg>
                  <svg className={classes.socialMediaIcon} viewBox="0 0 32 32">
                    <path fill="#000000" d="M23,11H21V9H19V11H17V13H19V15H21V13H23M8,11V13.4H12C11.8,14.4 10.8,16.4 8,16.4C5.6,16.4 3.7,14.4 3.7,12C3.7,9.6 5.6,7.6 8,7.6C9.4,7.6 10.3,8.2 10.8,8.7L12.7,6.9C11.5,5.7 9.9,5 8,5C4.1,5 1,8.1 1,12C1,15.9 4.1,19 8,19C12,19 14.7,16.2 14.7,12.2C14.7,11.7 14.7,11.4 14.6,11H8Z" />
                  </svg>
                  <svg className={classes.socialMediaIcon} viewBox="0 0 32 32">
                    <path fill="#000000" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                    </svg> 
                    <svg className={classes.socialMediaIcon} viewBox="0 0 32 32">
                      <path fill="#000000" d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
                    </svg>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} >
            <Grid container direction="row" alignItems="center" className={classes.footer}>
              <Grid item xs={12}>
                <Typography className={classes.footerText}>
                  &copy; 2019 Opentaxi. All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
    </Fragment>
  )
}

export default Home;