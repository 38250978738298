import http from 'services/http/httpInstance'
import { authHeader } from 'services/http/AuthService'

const url = '/users/'

export const getDrivers = (request) =>
  http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const getDriver = (request) =>
{
  console.log("getDriver", request);
  return http({
    url: url + request?.data?.id + "/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
}
  
export const saveDriver = (request) =>
  http({
    url: request?.data?.id ? url + request?.data?.id + "/" : url,
    method: request?.data?.id ? 'PATCH' : 'POST',
    headers: {
      ...authHeader(),
    },
    ...request,
  })

export const deleteDriver = (id, companyId) =>
  http({
    url: url + id + "/",
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
    params: {
      drivers: true,
      company: companyId,
    }
  })