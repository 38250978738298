import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';

import ButtonPrimary from '~/components/Buttons/ButtonPrimary'
import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import { httpGetOrders } from 'services/http/orders'
import { httpGetExcelReceipts, httpGetExcelServiceCharges } from 'services/http/companies'
import DateInput from '~/components/Inputs/DateInput'
import VehicleSelect from '~/components/Inputs/VehicleSelect'
import DriverSelect from '~/components/Inputs/DriverSelect'

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: theme.palette.table.thead.background,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },
  filter: {
    padding: '16px'
  }

}))

const OrdersList = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myUser } = props;
  
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    car:-1,
    driver:-1,
    start_date: moment().subtract(1,"months").toDate(), // new Date('2014-08-18T21:11:54'),
    end_date: moment().toDate(),
  });
  const [summary, setSummary] = useState();
  const [loading, setLoading] = useState(false);
  
  // Pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // END
  
  const updateFilter = (updatedVal) =>  {
    
    if (updatedVal == null)
    {
      return;
    }
    
    setFilters({
      ...filters,
      ...updatedVal
    });
  }
  
  useEffect(() => {
    getOrders();
  },[page, rowsPerPage, filters]);
 
  const getOrders = () => {
    setLoading(true);
    let params_filters = {}
    if (filters.car && filters.car > -1)
    {
      params_filters.car = filters.car
    }
    
    if (filters.driver && filters.driver > -1)
    {
      params_filters.driver = filters.driver
    }
    
    try {
      if (filters.start_date)
      {
        let _start_time = moment(filters.start_date).toISOString();
        if (_start_time) {
          params_filters["start_time__gte"] = _start_time.split('T')[0] +" 00:00";
        }
      }
    }
    catch(e) {
      console.log(e);
    }
    
    try {
      if (filters.end_date)
      {
        let _end_time = moment(filters.end_date).toISOString();
        if (_end_time) {
          params_filters["start_time__lte"] = _end_time.split('T')[0] +" 23:59";
        }
      }
    }
    catch(e) {
      console.log(e);
    }
    
    const request = {
        params: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          ordering: "-start_time",
          completed: true,
          summary: true,
          ...params_filters,
        }
    };
    
    httpGetOrders(request).then((response) => {
        setRows(response?.results);
        setCount(response?.count);
        setSummary(response?.summary);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  }
  
  function round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
  }
  
  const isReceipts = (row) => {
    return row?.receipts && row?.receipts.length > 0 ? true : false
  }
  
  const getReceipt = (row, fieldName) => {
    if (row?.receipts && row?.receipts.length > 0)
    {
      return round(row?.receipts[0][fieldName], 2)
    }
    return ""
  }
  
  const downloadExcelReceipts = () => {
    let params;
    try {
      params = {
        start_time: moment(filters.start_date).toISOString().split('T')[0] +" 00:00",
        end_time: moment(filters.end_date).toISOString().split('T')[0] +" 23:59",
      }
    }
    catch(err) {
      return;
    }
    
    httpGetExcelReceipts(params).then((response) => {
      const data = new Blob([response], {type: 'octet/stream'});
      const url = window.URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'opentaxi_receipts.xlsx');
      tempLink.click();
    })
  }
  
  const downloadExcelServiceCharges = () => {
    let params;
    try {
      params = {
        start_time: moment(filters.start_date).toISOString().split('T')[0] +" 00:00",
        end_time: moment(filters.end_date).toISOString().split('T')[0] +" 23:59",
      }
    }
    catch(err) {
      return;
    }
    httpGetExcelServiceCharges(params).then((response) => {
      const data = new Blob([response], {type: 'octet/stream'});
      const url = window.URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'opentaxi_service_charges.xlsx');
      tempLink.click();
    })
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
      <Grid container spacing={3} direction="row" justify="space-between" alignItems="start">
        <Grid item></Grid>
         <Grid item>
          <Grid container spacing={3} direction="row" justify="space-between" alignItems="start">
            <Grid item>
              <ButtonPrimary onClick={downloadExcelReceipts}>
                {t('Vie Excel (Kuitit)')}
              </ButtonPrimary>
            </Grid>
            
            <Grid item>
              <ButtonPrimary onClick={downloadExcelServiceCharges}>
                {t('Vie Excel (Palvelumaksut)')}
              </ButtonPrimary>
            </Grid>
          </Grid>
          
         </Grid>
      </Grid>
      
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="start">
        
        <Grid item xs={12} sm={12} md={3} className={classes.filter}>
          <Typography variant="h4">
            {t('Ajot')}
          </Typography>
        </Grid>
        
        <Grid item xs={12} sm={12} md={3} className={classes.filter}>
          <Typography>Aikaväli</Typography>
          <DateInput
                  required={false}
                  id="filter_start_date"
                  name="start_date"
                  value={filters?.start_date || ""}
                  updateValues={updateFilter}
                />
          <DateInput
                  required={false}
                  id="filter_end_date"
                  name="end_date"
                  value={filters?.end_date || ""}
                  updateValues={updateFilter}
                />
        </Grid>
        
        <Grid item xs={12} sm={12} md={3} className={classes.filter}>
          <Typography>Valitse auto</Typography>
          <VehicleSelect updateValues={updateFilter} value={filters?.car} />
        </Grid>
        
        <Grid item xs={12} sm={12} md={3} className={classes.filter}>
          <Typography>Valitse kuljettaja</Typography>
          <DriverSelect myUser={myUser} updateValues={updateFilter} value={filters?.driver} />
        </Grid>
        
      </Grid>
    
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Aika')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Auto')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Kuljettaja')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">
              {t('Summa')}
              <Typography variant="caption" display="block" gutterBottom>
                { round(summary?.total_charges, 2) }€
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">
              {t('Veron osuus')}
              <Typography variant="caption" display="block" gutterBottom>
                { round(summary?.total_vat, 2) }€
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">
              {t('Matka')}
              <Typography variant="caption" display="block" gutterBottom>
                { round(summary?.total_distance, 2) }km
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        
        { loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan="5">
                <SpinnerCentered />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <Button component={Link} to={"/dashboard/orders/"+row.id+"/detail"}>
                  <Typography color="primary" variant="subtitle2">{row.start_time ? moment(row.start_time).format('DD.M.YYYY H:mm') : null}</Typography>
                </Button>
              </TableCell>
              <TableCell>
                {row.license_number}
              </TableCell>
              <TableCell>
                {row.driver_first_name} {row.driver_last_name}
              </TableCell>
              <TableCell>
                {isReceipts(row) ? (
                  <Fragment>{getReceipt(row, "payment")}€</Fragment>
                ) : null }
              </TableCell>
              <TableCell>
                {isReceipts(row) ? (
                  <Fragment>{getReceipt(row, "vat_in_cash")}€ (alv {getReceipt(row, "driver_tax_percentage")}%)</Fragment>
                ) : null }
              </TableCell>
              <TableCell>
                {isReceipts(row) ? (
                  <Fragment>{round(row?.distance)}km</Fragment>
                ) : null }
              </TableCell>
              {/*
              <TableCell>
                {row.start_street_address ? row.start_street_address + ", " : null}
                {row.start_postal_code}&nbsp;
                {row.start_city?.name}
              </TableCell>
              */}
              <TableCell align="right">
                <Button component={Link} to={"/dashboard/orders/"+row.id+"/detail"}>
                  <Typography color="primary" variant="subtitle2">{t('Avaa tiedot')}</Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        )}
        
      </Table>
      
      <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t('Rivejä per sivu')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count}`}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        
    </Paper>
  )
}

export default OrdersList;