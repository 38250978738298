import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import { httpGetOrder } from '~/services/http/orders'
import OrderDetail from '~/components/OrderDetail'

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: theme.palette.table.thead.background,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },
  filter: {
    padding: '16px'
  }

}))

const OrderDetailView = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, match } = props;
  const { orderId } = match.params;
  
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  
 
  useEffect(() => {
    getOrder();
  },[]);
  
  const getOrder = () => {
    setLoading(true);
    const request = {
        params: {
          completed:true,
        }
    };

    httpGetOrder(request, orderId).then((response) => {
      setOrder(response)
      setLoading(false);
    })
    
    
    
    /*
    getDrivers(request).then((response) => {
        setRows(response);
      }, (error) => {
        
      });
      */
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
      { loading ? (
        <SpinnerCentered />
      ) : (
        <OrderDetail order={order} />
      )}
    </Paper>
  )
}

export default OrderDetailView;