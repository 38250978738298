import React, { Fragment, useState, useEffect } from 'react';

import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import ButtonPrimary from '~/components/Buttons/ButtonPrimary'
import { httpInviteUser } from 'services/http/companies'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
}))

const UserInviteView = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history } = props;
  
  const [values, setValues] = useState({
    
  });
  
  const [validFields, setValidFields] = useState({
    email:false,
  });
  
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  
  useEffect(() => {
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  const handleFormChange = ({ target }) => {
    let { value, name } = target;
    
    if (["email"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    
    setValues({
      ...values,
      [name]: value
    })
  }
  
  const handleSubmit = () => {
    setSaving(true);
    const request = {
        data: {
          ...values,
          email: values.email.toLowerCase(),
          status: 100, // admin
        }
    };
    
    httpInviteUser(request).then((response) => {
        props.enqueueSnackbar('Kutsu lähetetty', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
        setErrors(null);
        setSaving(false);
        history.push("/dashboard/users");
      }, (error) => {
        
        props.enqueueSnackbar(t('Kutsun lähetys epäonnistui'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          // persist: true,
          autoHideDuration: 5000,
        })
        
        let errorMsg = "Kutsun lähetys epäonnistui. Tarkista sähköpostiosoite.";
        if (error?.data?.errorCode === "ADMIN_EXISTS")
        {
          errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo muita rooleja. Roolin vaihtamiseksi ota yhteyttä tukeen support@opentaxi.fi.";
        }
        else if (error?.data?.errorCode === "DRIVER_EXISTS")
        {
          errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo kuljettajarooli. Roolin vaihtamiseksi ota yhteyttä tukeen support@opentaxi.fi.";
        }
        else if (error?.data?.errorCode === "CUSTOMER_EXISTS")
        {
          errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo asiakastunnus. Käytä toista sähköpostiosoitetta tai pyydä käyttäjää poistamaan tilinsä palvelusta (mobiilisovellus -> omat tiedot -> Poista profiili)";
        }
        
        setErrors({
          '400': errorMsg,
        });
        setSaving(false);
      });
  }
  
  const handleErrors = (errors) => {
    
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={0} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          <Typography variant="h4">
            {t('Kutsu admin käyttäjä')}
          </Typography>
        </Grid>
        
      </Grid>
      
      <Grid container spacing={0}>
        <ValidatorForm onSubmit={handleSubmit} onError={handleErrors}>
          <Grid item>
            <Typography variant="subtitle1">
            {t('Sähköpostiosoite')}
            </Typography>
                      
            <TextValidator
              className={classes.textField}
              errorMessages={['Tarkista sähköpostiosoitteen muoto']}
              id="invite-form-email"
              inputProps={{
                
              }}
              fullWidth
              margin="dense"
              name="email"
              onChange={handleFormChange}
              required={true}
              shrink="true"
              type="email"
              validators={['isEmail']}
              validatorListener={(isValid) => {
                  if (isValid && values.email && values.email.length > 0)
                  {
                    setValidFields({
                      ...validFields,
                      email: true,
                    });
                  }
                  else
                  {
                    setValidFields({
                      ...validFields,
                      email: false,
                    });
                  }
                }
              }
              value={values.email || ""}
              variant="outlined"
              
            />
            
            <ButtonPrimary type="submit" disabled={validFields.email == false} loading={saving}>
              {t('Kutsu')}
            </ButtonPrimary>
            
            { errors ? (
            <Fragment>
              <Box>
              {
                Object.keys(errors).map((key, index) => (
                  <Box><Typography key={index} variant="body" color="error" gutterBottom>
                    {errors[key]}
                  </Typography></Box>
                ))
              }
              </Box>
            </Fragment>
          ) : null}
          
        </Grid>
        
        </ValidatorForm>
      </Grid>
    
    </Paper>
  )
}

export default withSnackbar(UserInviteView);