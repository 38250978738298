import React, { useState, useEffect } from 'react';
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewContainer from 'components/ViewContainer/ViewContainer'
import { sendActivateUserToken } from 'services/http/users'

const styles = theme => ({
  fieldGrid: {
    marginBottom: 12
  }
});

const ActivateUserToken = (props) => {

  const [errors, setErrors] = useState(null);

  // props
  const { classes, history, location, enqueueSnackbar } = props;
  const { search } = location;
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(true);
  const [responseText, setResponseText] = useState();
  
  useEffect(() => {
    if (search)
    {
      const parsed = queryString.parse(search);
      if (parsed.token)
      {
        handleSendToken(parsed.token);
      }
    }
  }, [search])
  
  const handleSendToken = (token) => {
    const request = {
      data: {
        token: token
      }
    };
    
    sendActivateUserToken(request).then((response) => {
      enqueueSnackbar(t("Onnistui"), { variant: "success" });
      setResponseText(t("Käyttäjätili on aktivoitu"))
      setLoading(false)
    }, (error) => {
      setErrors(true);
      enqueueSnackbar(t("Epäonnistui"), { variant: "error" });
      setResponseText(t("Tilin aktivointi epäonnistui"))
      setLoading(false)
    });
  }
  

    return (
      <ViewContainer title={t('Käyttäjätilin aktivointi')}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            { loading ? (
            <CircularProgress />
          ) : (
            <Typography>{responseText}</Typography>
          )}
          </Grid>
        </Grid>
      </ViewContainer>


    );
  
}

export default withStyles(styles)(ActivateUserToken);