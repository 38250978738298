import { createMuiTheme } from '@material-ui/core/styles';
import spacing from './spacing';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';

export default createMuiTheme({
  typography,
  palette,
  spacing,
  overrides,
  
});