import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import Code from '@material-ui/icons/Code';
import Commute from '@material-ui/icons/Commute';
import Extension from '@material-ui/icons/Extension';

import styles from './iconCard.css';

const IconCard = (props) => {
  const smallScreen = useMediaQuery('(max-width:600px)');
  const classes = styles();

  const renderIcon = (icon) => {
    switch(icon) {
      case 0:
        return(<AccessibilityNew className={classes.icon} />);
      case 1:
        return(<Code className={classes.icon} />);
      case 2:
        return(<Commute className={classes.icon} />);
      case 3: 
        return(<Extension className={classes.icon} />);
      default:
        console.log("Icon not defined");
    }
  };
  
  return (
    <Grid container className={classes.iconCardContainer} alignItems="center">
      {renderIcon(props.icon)}
      <Typography className={smallScreen ? classes.iconText : null}>
        {props.content}
      </Typography>
    </Grid>
  )
}

export default IconCard;