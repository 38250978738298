import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// import {vehicleTrunkTypes} from 'services/constants';
import MySelectField from "components/Inputs/MySelectField"

const useStyles = makeStyles(theme => ({
  
}));

const VehicleTrunkTypeSelect = (props) =>
{
  const { enums, value, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const [vehicleTrunkTypes, setVehicleTrunkTypes] = useState();
  
  useEffect(() => {
    if (enums)
    {
      setVehicleTrunkTypes(enums?.data?.vehicles?.car?.trunk_type)
    }
  },[enums])
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  return (
    <Fragment>
    
      <MySelectField
        id="select_order_type"
        name="trunk_type"
        value={value ? value : 0}
        onChange={handleSelectChange}
      >
        {vehicleTrunkTypes ? vehicleTrunkTypes.map(option => (
          <MenuItem key={option.key} value={option.key}>
            {t(option.value)}
          </MenuItem>
        )) : null}
      </MySelectField>
    </Fragment>
  )
}

export default VehicleTrunkTypeSelect;
