import http from 'services/http/httpInstance'
import { authHeader } from 'services/http/AuthService'

const url = '/cars/'

export const getVehicles = (request) =>
  http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpGetVehiclesPublic = (request) =>
  http({
    url: url,
    method: 'GET',
    ...request,
  })
  
export const getVehicle = (request) =>
  http({
    url: url + request?.data?.id + "/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const saveVehicle = (request) =>
  http({
    url: request?.data?.id ? url + request?.data?.id + "/" : url,
    method: request?.data?.id ? 'PATCH' : 'POST',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const deleteVehicle = (id) =>
  http({
    url: url + id + "/",
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
