import http from '~/services/http/httpInstance'
import { authHeader } from '~/services/http/AuthService'

const url = '/companies/'

export const httpGetPrices = (request) =>
  http({
    url: url + "me/price_list_items/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
  
export const httpGetPrice = (id) =>
  http({
    url: url + "me/price_list_items/" + id + "/",
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
  
export const httpCreatePrice = (data) =>
  http({
    url: url + "me/price_list_items/",
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data
  })
  
export const httpUpdatePrice = (id, data) =>
  http({
    url: url + "me/price_list_items/" + id + "/",
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
  
export const httpDeletePrice = (id) =>
  http({
    url: url + "me/price_list_items/" + id + "/",
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
  
