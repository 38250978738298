import React, { Fragment, useState, useEffect } from 'react'
import moment from "moment";
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { spacing } from '@material-ui/system';
import { withSnackbar } from 'notistack';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import DateInput from '~/components/Inputs/DateInput'
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import img1 from '~/assets/img1.png'

import MyTextField from "components/Inputs/MyTextField"
import { saveDriver } from '~/services/http/drivers'

const useStyles = makeStyles(theme => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1
  },
  avatar: {
    margin:16,
    color:'#bbb',
    textAlign: 'center',
    width: 160,
    height: 160,
    backgroundColor: '#eee',
    borderWidth:'2px',
    borderStyle:'dashed',
    borderColor:'#bbb',
  },
  toggleButtonSelected: {
    color: '#fff' + ' !important',
    backgroundColor: theme.palette.primary.main + ' !important',
  }

}));

const DriverForm = (props) =>
{
  // hooks
  const [loading, setLoading] = useState(false);
  const [validFields, setValidFields] = useState({
    receiverPhone:false,
    receiverEmail:false,
  });
  const [driverType, setDriverType] = useState(1);
  
  // props
  const { enqueueSnackbar, history, values, updateValues, fields, myCompany, myUser } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  useEffect(() => {
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  
  
  const handleDriverType = (event, newType) => {
    // setDriverType(newType);
    updateValues({ status: newType })
  };
  
  const handleFormChange = ({ target }) => {
    let { value, name } = target;
    
    if (["email", "email2"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    updateValues({ [target.name]: value })
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: {id:target.value} })
  }
  
  const handleConfirmUser = () => {
    let data = values;
    
    const request = {
      params: {
        drivers:true,
        company:myCompany?.id
      },
      data: {
        id: values.id,
        //verified: moment().milliseconds(0).toISOString()
        verified: moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')
        //verified: new Date().toISOString()
      }
    };
    
    saveDriver(request).then((response) => {
      updateValues(response);
      enqueueSnackbar('Tallennettu', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })

    }, (errors) => {
      enqueueSnackbar('Tallennus epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })

    });
  }
  
  return (
    <Fragment>
        <Grid container spacing={1}>
          
          <Grid item xs={12}>
          
            <Grid container direction="row" spacing={1}>
              
              { !fields || fields.includes("name") ? (
              <Fragment>
                <Grid item xs={12} sm={5}>
                  <MyTextField
                    autoFocus
                    customLabel={t('Etunimi')}
                    id="driver-first_name"
                    name="first_name"
                    value={values.first_name || ""}
                    onChange={handleFormChange}
                    required={false}
                  />
                </Grid>
                
                {/* label="Etunimi" */}
              
              
              <Grid item xs={12} sm={1}></Grid>
              
              <Grid item xs={12} sm={6}>
                <MyTextField
                    customLabel={t('Sukunimi')}
                    id="driver-last_name"
                    name="last_name"
                    value={values.last_name || ""}
                    onChange={handleFormChange}
                    required={false}
                  />
                  {/* label="Sukunimi" */}
                  
                
              </Grid>
              
              </Fragment> ) : null }
              
              { !fields || fields.includes("email") ? (
              <Fragment>
              <Grid item xs={12}>
                
                <Typography variant="subtitle1">
                  Sähköposti
                </Typography>
                
                <TextValidator
                  className={classes.textField}
                  errorMessages={['Tarkista sähköpostiosoitteen muoto']}
                  id="driver-email"
                  fullWidth
                  margin="dense"
                  name="email"
                  onChange={handleFormChange}
                  required={!values.id}
                  disabled={values.id}
                  shrink="true"
                  type="email"
                  validators={['isEmail']}
                  validatorListener={(isValid) => {
                      if (isValid && values.email && values.email.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          email: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          email: false,
                        });
                      }
                    }
                  }
                  value={values.email || ""}
                  variant="outlined"
                />
                
              </Grid>
              
              { !values.id ? (
              <Grid item xs={12}>
                
                <Typography variant="subtitle1">
                  Sähköposti uudelleen
                </Typography>
                
                <TextValidator
                  className={classes.textField}
                  errorMessages={['Tarkista sähköpostiosoitteen muoto']}
                  id="driver-email2"
                  fullWidth
                  margin="dense"
                  name="email2"
                  onChange={handleFormChange}
                  required={!values.id}
                  disabled={values.id}
                  shrink="true"
                  type="email"
                  validators={['isEmail']}
                  validatorListener={(isValid) => {
                      if (isValid && values.email2 && values.email2.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          email2: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          email2: false,
                        });
                      }
                    }
                  }
                  value={values.email2 || ""}
                  variant="outlined"
                />
                
              </Grid> ) : null }
              </Fragment> ) : null }
              
              <Grid item xs={12}>
                
                <Typography variant="subtitle1">
                  Puhelinnumero
                </Typography>
                
                <TextValidator
                  className={classes.textField}
                  errorMessages={['Puhelinnumeron on oltava kansainvälisessä muodossa +3585012345',]}
                  fullWidth
                  helperText="Käytä vain kansainvälistä muotoa"
                  id="driver-phone"
                  margin="dense"
                  name="phone"
                  onChange={handleFormChange}
                  required={false}
                  shrink="true"
                  placeholder="+358"
                  validators={['matchRegexp:(([+][(]?[0-9]{1,3}[)]?)([(]?[0-9]{1,4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$']}
                  validatorListener={(isValid) => {
                      if (isValid && values.phone && values.phone.length > 0)
                      {
                        setValidFields({
                          ...validFields,
                          phone: true,
                        });
                      }
                      else
                      {
                        setValidFields({
                          ...validFields,
                          phone: false,
                        });
                      }
                    }
                  }
                  value={values.phone || ""}
                  variant="outlined"
                />
                
                
                
              </Grid>
              
              {/*
              <Grid item xs={12}>
                <MyTextField
                  customLabel={t('Henkilötunnus')}
                  id="driver-social_security_number"
                  name="social_security_number"
                  value={values.social_security_number || ""}
                  onChange={handleFormChange}
                  required={false}
                  placeholder="1234567-123M"
                />
              

              </Grid>
              */}
              
              {/* hidden card-30
              <Grid item xs={12}>
                <MyTextField
                  customLabel={t('Hallintotunnus')}
                  id="driver-administrative_identifier"
                  name="administrative_identifier"
                  value={values.administrative_identifier || ""}
                  onChange={handleFormChange}
                  required={false}
                  placeholder="459405453242"
                />
                
              </Grid>
              */}
              
              {/*
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('Taksinkuljettajan ajolupa voimassa')}
                </Typography>
              </Grid>
             

              <Grid item xs={12}>
                
                <DateInput
                  required={false}
                  id="driver-driver_licence"
                  name="driver_licence"
                  value={values.driver_licence || ""}
                  updateValues={updateValues}
                />
                
                
              </Grid>
               */}
              
              
              { (!fields || fields.includes("status")) && myUser?.status == 100 ? (
                <Fragment>
                <Grid item xs={12}>
                  <Box marginTop="16px">
                    <ToggleButtonGroup
                      value={values.status || 1}
                      exclusive
                      onChange={handleDriverType}
                      aria-label="text alignment"
                    >
                      <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={1} aria-label="left aligned">
                        Peruskuljettaja
                      </ToggleButton>
                      <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={10} aria-label="right aligned">
                        Superkuljettaja
                      </ToggleButton>
                      <ToggleButton classes={{ selected: classes.toggleButtonSelected }} className={classes.toggleButton} value={100} aria-label="right aligned">
                        Admin
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
                </Fragment>
              ) : null }
              
              { (!fields || fields.includes("verified")) && values.id && myUser?.status == 100 ? (
              <Fragment>
                { values.verified ? (
                  <Grid item xs={12}>
                    <Box marginTop="16px">
                      <Typography variant="subtitle1">
                        Käyttäjätili on vahvistettu
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                <Fragment>
                  <Grid item xs={12}>
                    <Box marginTop="16px">
                      <Typography variant="subtitle1">
                        Käyttäjätiliä ei ole vielä vahvistettu
                      </Typography>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12}>
                      <ButtonPrimary onClick={handleConfirmUser}>
                        {t('Vahvista käyttäjätili tästä')}
                      </ButtonPrimary>
                  </Grid>
                </Fragment> )}
              </Fragment>) : null }

            
            </Grid>
          
          </Grid>
          
          {/*
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid item>
                <Grid
                  container justify="center" alignItems="center"
                >
                  <Avatar src={null} className={classes.avatar}>Kuvaa ei ole lisätty</Avatar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          */}
        
          
        
        </Grid>


    </Fragment>
  )
  
}

DriverForm.defaultProps = {
  fields: null
}

export default withSnackbar(DriverForm);
