import { makeStyles } from '@material-ui/styles';
import BackgroundImage from 'assets/TilaaTaksi_099.jpg'

export default makeStyles(theme => ({
  homePageContainer: {
    overflow: 'hidden',
  },
  testDiv: {
    marginTop: 100,
  },
  introContainer: {
    height: '58vh',
    padding: 64,
    marginTop: 100,
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundImage: " linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + BackgroundImage + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
  },
  titleText: {
    color: '#fff'
  },
  aboutContainer: {
    maxWidth: 600,
    margin: `0 auto`,
    padding: `64px 8px`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1200,
    },
  },
  animatedImageContainer: {
    height: 600,
    [theme.breakpoints.down('md')]: {
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 300,
    },
  },
  image: {
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: `0 auto`,
      maxWidth: 400,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  moreContainer: {
    backgroundColor: '#6a31dc', 
    backgroundImage: `linear-gradient(to bottom right, #6c31dc, #6a31fc)`,
    padding: 64,
  },
  moreText: {
    color: '#fff',
  },
  skinnyText: {
    fontWeight: 100,
  },
  moreLink: {
    color: '#1bb0ce',
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: 8,
  },
  proContainer: {
    padding: `64px 0`,
  },
  proValueContainer: {
    backgroundColor: '#f4f4f4',
    padding: `32px 0`,
  },
  proValueContainerLimiter: {
    maxWidth: 1200,
    margin: `0 auto`,
  },
  applicationContainer: {
    maxWidth: 600,
    margin: `0 auto`,
    padding: `64px 0`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1200,
    },
  },
  applicationTextContainer: {
    paddingLeft: 16,
  },
  dividerContainer: {
    width: 200,
    padding: `16px 0`,
    margin: `0 auto`,
  },
  socialMediaIcon: {
    width: 32,
    height: 32,
    paddingRight: 8,
  },
  footer: {
    backgroundColor: '#1a1a1a',
    height: 100,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 42,
    },
  },
  footerText: {
    display: 'block',
    maxWidth: 1200,
    color: '#aaa',
    margin: `0 auto`,
  }
}));