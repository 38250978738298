import React, { Fragment, useState, useEffect } from 'react';
import queryString from 'query-string'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';


import SpinnerCentered from 'components/Loader/SpinnerCentered';
import { saveCompany, httpCompleteStripeAccount, httpRetriveStripeAccount } from 'services/http/companies';
import stripeConnectImage from 'assets/stripe-blue-on-light.png'
import { baseURL } from 'services/http/httpInstance'
import CompanyPaymentForm from 'components/CompanyPaymentForm/CompanyPaymentForm'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 48,
  },
  title: {
    paddingBottom: 32,
  },
  chip: {
    backgroundColor: "#F0F0F0",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: "8px",
  },
  chipActive: {
    color: theme.palette.success.main,
  },
  chipInactive: {
    color: theme.palette.error.main,
  },
  buttonLink: {
    textDecoration: 'none',
  },

}))

const CompanyPayment = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myCompany, getMyCompany, users, ...rest } = props;
  
  
  const [values, setValues] = useState({
    
  });
  const [stripeConnectUrl, setStripeConnectUrl] = useState("");
  const [loadingStripe, setLoadingStripe] = useState(false);
  
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  
  useEffect(() => {
    handleGetMyCompany();
  },[])
  
  useEffect(() => {
    if (users?.data?.me?.status && users?.data?.me?.status < 100)
    {
      history.push("/dashboard/company/payment?error");
    }
  }, [users])
  
  useEffect(() => {
    const keys = queryString.parse(props?.location?.search)
    
    if (keys.state)
    {
      
    }
    
    if (keys.code)
    {
      handleCompleteStripe(keys.code);
    }
    
    if (keys.scope)
    {
      
    }

  }, []);
  
  const handleGetMyCompany = () => {
    setLoading(true);
    getMyCompany().then((response) => {
      
      // Stripe connect url
      let client_id = "";
      if (process?.env?.REACT_APP_ENV == "production")
      {
        // Production client_key
        console.log("Production client_key");
        client_id = "ca_GitlA9I6Q3J2D3yROUszLlZeAyvzeWVL";
      }
      else
      {
        // Test client_key
        console.log("Test client_key");
        client_id = "ca_GitlkbafxCRiSHJnjJjVlrwk6V5Qoc1z";
      }
      
      const stripe_state = "fweofjwefojiwf"; // TODO => random + localStorageen
      const stripe_user_email = users?.data?.me?.email;
      let stripe_user_phone = "";
      if (users?.data?.me?.phone)
      {
        stripe_user_phone = users?.data?.me?.phone;
        stripe_user_phone = stripe_user_phone.replace("+358","");
      }
      const stripe_user_first_name = users?.data?.me?.first_name;
      const stripe_user_last_name = users?.data?.me?.last_name;
      
      const stripe_user_business_name = response?.name
      const stripe_user_tax_id = response?.business_id
      
      let redirect_uri = "";
      if (process?.env?.REACT_APP_ENV == "production")
      {
        redirect_uri = "https://app.opentaxi.fi/dashboard/company/payment";
      }
      else if (process?.env?.REACT_APP_ENV == "dev")
      {
        redirect_uri = "https://dev.opentaxi.fi/dashboard/company/payment";
      }
      else
      {
        redirect_uri = "http://localhost:3001/dashboard/company/payment"
      }
      
      setStripeConnectUrl("https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id="+client_id+"&scope=read_write&redirect_uri="+redirect_uri+"&stripe_user[email]="+stripe_user_email+"&stripe_user[phone_number]="+stripe_user_phone+"&stripe_user[business_name]="+stripe_user_business_name+"&stripe_user[tax_id]="+stripe_user_tax_id+"&stripe_user[first_name]="+stripe_user_first_name+"&stripe_user[last_name]="+stripe_user_last_name+"&suggested_capabilities[]=card_payments&assert_capabilities[]=card_payments")
      
      if (response.stripe_user_id)
      {
        retriveStripeAccount()
      }
      setLoading(false);
    }, (errors) => {
      setLoading(false);
    })
  }
  
  const retriveStripeAccount = () => {
    setLoadingStripe(true)
    httpRetriveStripeAccount().then((response) => {
      setValues(response);
      setLoadingStripe(false)
    }, (errors) => {
      setLoadingStripe(false)
    })
  }
  
  const formatValues = (_values) => {
    
    return {
      ..._values,
    }
  }
  
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (myCompany?.stripe_user_id == null)
    {
      return;
    }
    
    setSaving(true);
    
    const request = {
      data: {
        id: values.id,
        name: values.name,
        business_id: values.business_id,
      }
    };
    
    saveCompany(request).then((response) => {
      setValues(formatValues(response));
      
      props.enqueueSnackbar('Tallennettu', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      setSaving(false);
      
    }, (errors) => {
      props.enqueueSnackbar('Tallennus epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      setSaving(false);
      
    });

  }
  
  const handleErrors = (errors) => {
    
  }
  
  const handleCancel = () => {
    history.push("/dashboard/start");
  }
  
  const handleCompleteStripe = (code) => {
    
    setLoadingStripe(true)
    httpCompleteStripeAccount(code).then((response) => {
      props.enqueueSnackbar('Maksutiedot liitetty onnistuneesti!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      
      setLoadingStripe(false)
      handleGetMyCompany();
      history.push("/dashboard/company/payment?success");
    }, (errors) => {
      props.enqueueSnackbar('Virhe maksutietojen liittämisessä, yritä uudelleen tai ota yhteys tukeen.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      
      setLoadingStripe(false)
      
      // refresh view
      history.push("/dashboard/company/payment?error");
    });

  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
        
        <Box marginBottom={2}>
          <Typography variant="h4">{t("Maksutiedot")}</Typography>
        </Box>
        
        {loading || loadingStripe ? (
            <SpinnerCentered />
        ) : (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="top"
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={7} lg={6}>
              
              { !myCompany?.stripe_user_id ? (
                <>
                  <Box marginBottom="64px">
                    <a href={stripeConnectUrl} style={{textDecoration:'none'}}>
                    <Box display="flex" flexDirection="row">
                      <Badge badgeContent={"!"} color="error">
                        <Box
                          color="error.main"
                          bgcolor="background.panel"
                          p={2}
                        >
                          {t('Tilisi vaatii lisätietoja')}
                        </Box>
                      </Badge>
                    </Box>
                    </a>
                    
                    <Typography>
                      {t('Siirry')}&nbsp;
                      <a href={stripeConnectUrl} className={classes.buttonLink}>{t('lisäämään')}</a>
                      &nbsp;{t('puuttuvat maksutiedot, jotta maksutilisi saadaan aktiiviseksi.')}
                    </Typography>
                  </Box>
                  
                  <Box marginBottom={2}>
                    <Typography variant="h4">{t("Katso tästä video ohje miten tili luodaan")}:</Typography>
                  </Box>
                  
                  <a href="https://www.youtube.com/watch?v=C_ohwKS2UDU" className={classes.buttonLink}>https://www.youtube.com/watch?v=C_ohwKS2UDU</a>
                  
                  
                </>
              
              ) : null }
              
              {/*
                <Box marginBottom="32px">
                  <a href={stripeConnectUrl} className={classes.buttonLink}>
                    <Badge badgeContent={"!"} color="error">
                      <ButtonPrimary type="button">
                          {!myCompany?.stripe_user_id ? t('AKTIVOI STRIPE MAKSUPALVELU') : t('MAKSUPALVELU ODOTTAA VAHVISTUSTA')}
                      </ButtonPrimary>
                    </Badge>
                  </a>
                </Box>
              */}

              
              { myCompany?.stripe_user_id && values?.charges_enabled === false ? (
                <Box marginBottom="32px">
                  <Typography>{t('Odottaa, että Stripe vahvistaa maksutilinne. Jos vahvistusta ei tule viimeistään muutaman päivän sisällä tarkistathan, että kaikki vaaditut tiedot on täytetty. Ongelmatilanteissa ota yhteyttä support@opentaxi.fi')}</Typography>
                </Box>
              ) : null }
              
              <ValidatorForm onSubmit={handleSubmit} onError={handleErrors}>
              
                { myCompany?.stripe_user_id ? (
                <Box marginBottom="32px">
                  <CompanyPaymentForm values={values} updateValues={updateValues} />
                </Box>
                ) : null }
                
                { myCompany?.stripe_user_id ? (
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <ButtonDefault onClick={handleCancel}>
                          {t('Peruuta')}
                        </ButtonDefault>
                      </Grid>
                      <Grid item>
                        <ButtonPrimary loading={saving} type="submit" disabled={true || !myCompany?.checked || users?.data?.me?.status < 100}>
                          {t('Tallenna ja päivitä')}
                        </ButtonPrimary>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null }
              
            </ValidatorForm>
        
            </Grid>
              
            { myCompany?.stripe_user_id && values?.charges_enabled ? (
              <Grid item xs={12} sm={12} md={5} lg={3} xl={2}>
                <Fragment>
                  <Box marginBottom={2}>
                    <Chip label={t("STRIPE TUNNUS AKTIIVINEN")} className={`${classes.chip} ${classes.chipActive}`} />
                  </Box>
                  
                  <Box marginBottom={2}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="subtitle">{t("Maksujen vastaanotto")}</Typography>
                      </Grid>
                      <Grid item>
                      { values?.charges_enabled ? (
                        <CheckIcon style={{ color: green[500] }} />
                      ) : (
                        <Fragment>
                          <CloseIcon color="error" />
                        </Fragment>
                        
                      )}
                      </Grid>

                    </Grid>
                  </Box>

                  <Box marginBottom={2}>
                  <Typography variant="subtitle">{t("Maksutoiminnot")}</Typography>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography>{t("Korttimaksut")}</Typography>
                      </Grid>
                      <Grid item>
                      { values?.capabilities?.card_payments === "active" ? (
                        <CheckIcon style={{ color: green[500] }} />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                      </Grid>
                      
                      {/*
                      <Grid item>
                        <Typography>{t("Tilisiirto")}</Typography>
                      </Grid>
                      <Grid item>
                      { values?.capabilities?.transfers === "active" ? (
                        <CheckIcon style={{ color: green[500] }} />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                      </Grid>
                      */}

                    </Grid>

                  </Box>
                  
                  {/*
                  <a href={stripeConnectUrl} className={classes.buttonLink}>
                    {t('Päivitä Stripe maksutietoja')}
                  </a>
                  */}
                  
                </Fragment>
              </Grid>
              ) : null}
        </Grid>
      )}
                    
      {/*
        <Fragment>
        { users?.data?.me?.status == 100 ? (
          <Fragment>
            <a href={stripeConnectUrl} className={classes.buttonLink}>
              <Badge badgeContent={"!"} color="error">
                <ButtonPrimary type="button">
                    {!myCompany?.stripe_user_id ? t('AKTIVOI STRIPE MAKSUPALVELU') : t('MAKSUPALVELU ODOTTAA VAHVISTUSTA')}
                </ButtonPrimary>
              </Badge>
            </a>
          </Fragment>
        ) : (
          <Typography>{t('Vain yrityksen admin voi lisätä maksutiedot')}</Typography>
        )}
        </Fragment>
      
      </Fragment>
      */}
    </Paper>
    
    
  )
}

export default withSnackbar(CompanyPayment);