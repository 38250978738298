import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import CompanyInfo from 'components/CompanyInfo/CompanyInfo'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3),
  }
}))

const CompanyDetail = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { myCompany } = props;
  
  useEffect(() => {
    
  },[]);

  return (
    <Paper className={classes.content}>
      <Box marginBottom={2}>
        <Typography variant="h4">
          {t('Yrityksen perustiedot')}
        </Typography>
      </Box>
      <CompanyInfo company={myCompany} />
    </Paper>
    
  )
}

export default CompanyDetail;