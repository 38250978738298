import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import OrderDetailView from './OrderDetailView'

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderDetailView)
)
