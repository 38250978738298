export const validateCompany = (company) => {
    if (!company?.stripe_user_id)
    {
        return false
    }
    else if (company?.stripe_account_authorized == false)
    {
      return false
    }
    /*
    else if (company?.analytics?.price_lists?.count == 0)
    {
        return false
    }
    */
    return true
}