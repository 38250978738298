import React, { Fragment, useState, useEffect } from 'react';
import moment from "moment";

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import ButtonSuccess from '~/components/Buttons/ButtonSuccess';
import PriceDialog from '~/components/PriceDialog'

import { httpGetPrices } from 'services/http/prices'
import { saveCompany } from 'services/http/companies';

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 900,
    backgroundColor: theme.palette.table.thead.background,
  },
  buttonLink: {
    textDecoration:'none'
  }

}))

const PricesList = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myUser, myCompany, getMyCompany } = props;
  
  const [rows, setRows] = useState([]);
  const [activeId, setActiveId] = useState(-1);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    getPrices();
    updateCompanySeenPriceList();
  },[]);
  
  const updateCompanySeenPriceList = () => {
    const request = {
      data: {
        id: myCompany?.id,
        seen_price_list: moment().toISOString(),
      }
    };
    
    saveCompany(request).then((response) => {
      getMyCompany();
    }, (errors) => {
      
    });
  }
  
  const importData = (data) => {
    // Convert from cents to euros
    let formattedData = {
      ...data,
      opening_price: data?.opening_price/100.0,
      price_per_unit: data?.price_per_unit/100.0,
      special_opening_price: data?.special_opening_price/100.0,
      special_price_per_unit: data?.special_price_per_unit/100.0,
    }
    return formattedData;
  }
  
  const getPrices = () => {
    
    setLoading(true);
    
    const request = {
        params: {
          // company__id: 1
        }
    };
    
    httpGetPrices(request).then((response) => {
      let _rows = []  
      if (response && response.length > 0)
        {
          
          response.map((row) => {
            _rows.push(importData(row))
          })
          
        }
        setRows(_rows);
        
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
    
  }
  
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
    getPrices();
  }
  
  const handleOpenPrice = (id) => {
    setActiveId(id);
    setOpen(true);
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          <Typography variant="h4">
            {t('Hinnasto')}
          </Typography>
        </Grid>
        
        {myUser?.status == 100 ? (
          <Grid item>

              <ButtonSuccess onClick={() => handleOpenPrice(-1)}>
                {t('Lisää hinnasto')}
              </ButtonSuccess>

          </Grid>
        ) : null }
        
      </Grid>
    
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Nimi')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Aloitushinta')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Hinta / km')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="left">{t('Käytössä')}</TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        
        { loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan="5">
                <SpinnerCentered />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" align="left">
                {row?.name}
              </TableCell>
              
              <TableCell align="left">
                {row?.opening_price}€
              </TableCell>
              
              <TableCell align="left">
                {row?.price_per_unit}€
              </TableCell>
              
              <TableCell align="left">
                {row?.car_count && row?.car_count > 0 ? row?.car_count + " " + t("autoa") : "-" }
              </TableCell>
              
              <TableCell align="right">
                <Button onClick={() => handleOpenPrice(row?.id)}>
                  <Typography color="primary" variant="body2">{t('Muokkaa')}</Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        )}
      </Table>
      
      <PriceDialog open={open} setOpen={setOpen} handleClose={handleClose} id={activeId} />
    </Paper>
  )
}

export default PricesList;