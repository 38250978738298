import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  button: {
    boxShadow: 'none',
    borderRadius: 50,
    display: 'block',
    padding: `15px 30px`,
    //marginTop: 40,
    //marginLeft: 'auto',
    //marginRight: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  buttonColor: {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid #fff`,
    color: '#fff',
    '&:hover':{
      backgroundColor: '#fff',
      border: `2px solid #6a31dc`,
      color: '#6a31dc',
    }, 
  },
  buttonColorReverse: {
    backgroundColor: '#fff',
    border: `2px solid #6a31dc`,
    color: '#6a31dc',
    '&:hover':{
      backgroundColor: '#6a31dc',
      border: `2px solid #fff`,
      color: '#fff',
    }, 
  },
  centerAlign: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  icon: {
    marginLeft: 15
  }
}));