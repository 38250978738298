import React, { Fragment, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    marginBottom: '12px',
  },
  inputStyles: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
  },
  selectRoot: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
  }
}));

const MySelectField = (props) =>
{
  const { customLabel, children, ...rest } = props;
  const classes = useStyles();
  
  return (
    <Fragment>
      { customLabel && customLabel.length > 0 ? (
        <Typography variant="subtitle1">{customLabel}</Typography>
      ) : null}
      
      <TextField
        fullWidth
        select
        className={classes.textFieldRoot}
        SelectProps={{
          classes:{root: classes.selectRoot },
          MenuProps: {
            className: classes.menu,
          },
        }}
        variant="outlined"
        {...rest}
      >
      {children}
      </TextField>
      
    </Fragment>
  )
}

export default MySelectField