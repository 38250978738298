import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { withSnackbar } from "notistack";

import BoxInfo from "./BoxInfo";
import styles from "./styles.css";

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      
    },
    dispatch
  );
};

export default withTranslation()(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(BoxInfo)
  )
);
