import { browserHistory } from 'react-router'
import { baseURL } from './httpInstance'
import http from './httpInstance'

const setToken = (idToken) => {
  // Saves user token to localStorage
  localStorage.setItem('token', idToken)
}

const getToken = () => {
  // Retrieves the user token from localStorage
  const token = localStorage.getItem('token',null);
  if (token)
  {
    return token;
  }
  return null;
}



export default class AuthService {
  constructor() {
    this.fetch = this.fetch.bind(this)
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
    
  }
  
  register(request) {
    const data = request.data;
    const params = request.params;
    
    return http('users/', {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        //'Authorization':'Basic ' + new Buffer(username + ':' + password).toString('base64'),
      },
      data,
      params
    }).then(res => {
      setToken(res.token);
      return Promise.resolve(res)
    });
  }

  login(username, password) {

    return http('token/', {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        //'Authorization':'Basic ' + new Buffer(username + ':' + password).toString('base64'),
      },
      data: {
        username: username,
        password: password
      }
    }).then(res => {
      setToken(res.token);
      // this.setProfile(res.user);
      return Promise.resolve(res)
    });
  }

  loggedIn(){
    
    return new Promise((resolve, reject) => {
      
      // Checks if there is a saved token and it's still valid
      const token = getToken();
      if (token)
      {
        return resolve(true);
      }
      
      const profile = this.getProfile();
      if (profile)
      {
        return resolve(true);
      }
      
      if (token)
      {
        this.fetch("users/me", {method: 'GET',}).then((response) => {
          this.setProfile(response);
          resolve(true);
        }, (errors) => {
          this.logout();
          return resolve(false);
          
        });
      }
      else
      {
        return resolve(false);
      }
      
        
    });
  }
  
  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    
    http('/admin/logout/', {
      method: 'GET',
      headers: {
        'content-type': 'text/html'
      },
    }).then(response => {
      console.log("logout");
    });
  }

  setProfile(profile){
    // Saves profile data to localStorage
    localStorage.setItem('profile', JSON.stringify(profile))
  }

  getProfile()
  {
    // Retrieves the profile data from localStorage
    const profile = localStorage.getItem('profile',null);
    if (profile)
    {
      return JSON.parse(profile);
    }
    return null;
  }

  

  

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw response;
    }
  }

  fetch(url, options){
    // performs api calls sending the required authentication headers
    const headers = {
      ...options.headers
      //'Accept': 'application/json',
      //'Content-Type': 'application/json'
    }
    
    return this.loggedIn().then((response) => {
      
      if (response)
      {
        headers['Authorization'] = 'Token ' + getToken()
      }
      return fetch(baseURL+url, {
        ...options,
        headers,
        
      })
      .then(this._checkStatus)
      .then(response => response.json())
      
    });
  }
}

export function authHeader() {
  const token = getToken()
  if (token) {
    return {
      authorization: `JWT ${token}`
    }
  }
}
