import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import MySelectField from "components/Inputs/MySelectField"
import { httpGetPrices } from 'services/http/prices'

const useStyles = makeStyles(theme => ({
  
}));

const PriceListSelect = (props) =>
{
  const { enums, value, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const [rows, setRows] = useState();
  
  useEffect(() => {
    getPrices()     
  }, [])
  
  const getPrices = () => {
    
    const request = {
        params: {
          // company__id: 1
        }
    };
    
    httpGetPrices(request).then((response) => {
      setRows(response);
    }, (error) => {
        
    });
    
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  return (
    <Fragment>
    
      <MySelectField
        id="select_price_list"
        name="price_list"
        value={value != null ? value : 0}
        onChange={handleSelectChange}
      >
        {rows ? rows.map(row => (
          <MenuItem key={row.id} value={row.id}>
            {row.name}
          </MenuItem>
        )) : null}
      </MySelectField>
    </Fragment>
  )
}

export default PriceListSelect;
