import palette from '../palette';

export default {
  h2: {
    fontWeight:"bold"
  },
  h3: {
    fontWeight:"bold"
  },
  h4: {
    fontWeight:"bold"
  },
  h6: {
    fontWeight:"bold"
  },
  subtitle2: {
    fontWeight:"bold"
  },
}