import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CompanyDetail from './CompanyDetail'

const mapStateToProps = state => ({
  myCompany: state.companies?.data?.me,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
}

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyDetail)
)
