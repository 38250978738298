import React from 'react';

import ButtonBase from './ButtonBase'
import styles from './ButtonPrimary.css';

const ButtonPrimary = (props) => {
  const {children, ...rest} = props;
  const classes = styles();
  
  return (
      <ButtonBase {...rest} variant="contained" className={`${classes.button} ${props.reverseColor ? classes.buttonColorReverse : classes.buttonColor} ${props.centerAlign ? classes.centerAlign : null}`}>
        {children}
      </ButtonBase>
  )
}

export default ButtonPrimary;
