import React, { Fragment, useState, useEffect } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonDelete from 'components/Buttons/ButtonDelete';

import { getVehicle, saveVehicle, deleteVehicle } from 'services/http/vehicles'
import { uploadImageFile } from 'services/http/upload'
import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import AlertDialog from 'components/AlertDialog'
import { validateCompany } from 'services/companies'

import VehicleForm from 'components/VehicleForm';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 48,
  },
  title: {
    paddingBottom: 32,
  },
}))

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const validationSchema = yup.object().shape({
  license_number: yup
      .string()
      .required()
      .max(10),
  /*
  carbon_dioxide_emission: yup
      .number()
      .integer()
      .max(5),
      */
  carbon_dioxide_emission: yup.number()
    .integer()
    .max(99999)
    .transform(emptyStringToNull).nullable(),
});

const VehicleEdit = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  const { history, match, myCompany, getMyCompany, myUser, ...rest } = props;
  const { vehicleId } = match.params;

  const [values, setValues] = useState({
    animals: false,
    rollator: false,
    wheelchair: false,
    taped: false,
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false)
  
  useEffect(() => {
    handleValidateCompany()
  }, []);

  useEffect(() => {
    if (vehicleId && vehicleId >= 0)
    {
      handleGetVehicle();
    }
  }, [vehicleId]);

  const handleValidateCompany = () => {
    if (validateCompany(myCompany) === false)
    {
      setAlertOpen(true)
    }
  }
  
  const handleGetVehicle = () => {
    setLoading(true);
    const request = {
      data: {
        id: vehicleId
      }
    };
    
    getVehicle(request).then((response) => {
      setValues(response);
      setLoading(false);
    }, (errors) => {
      setLoading(false);
    });
    
  }
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    
    const request = {
      data: {
        ...values,
        price_list: values?.price_list != null && values?.price_list > 0 ? values?.price_list : null,
      }
    };
    delete request.data.image; 
    
    if (myUser?.status != 100 || values?.id)
    {
      // Only admin can change vehicle license number
      delete request.data.license_number; 
    }
    
    
    saveVehicle(request).then((response) => {
      setValues(response);
      setSaving(false);
      setErrors({});
      props.enqueueSnackbar('Tallennettu', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      })
      
      if (values.imageFile)
      {
        uploadImageFile("/cars/" + response.id + "/","image", values.imageFile).then((response) => {
          getMyCompany();
          history.push("/dashboard/vehicles/list");
        }, (errors) => {
          props.enqueueSnackbar('Ajoneuvon kuvan tallennus epäonnistui', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            }
          })
        })
      }
      else
      {
        getMyCompany();
        history.push("/dashboard/vehicles/list");
      }
      
      
    }, (errors) => {
      setSaving(false);
      props.enqueueSnackbar('Tallennus epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      })
      setErrors(errors?.data)
    });

  }
  
  const handleErrors = (errors) => {
    
  }
  
  const handleDelete = (confirmed) => {
    
    if (confirmed == false)
    {
      setConfirmOpen(true)
    }
    else
    {
      deleteVehicle(values?.id).then((response) => {
        
        props.enqueueSnackbar('Poistettu', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
        history.push("/dashboard/vehicles/list");
      
      }, (error) => {
        props.enqueueSnackbar('Ei voi poistaa. Käytössä.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
      });
    }
    
      
    
  }
  
  const validateForm = () => {
    validationSchema.validate(values, {abortEarly: true}).then(() => {
      // GOOD
      setErrors({});
    }).catch((err) => {
      // INVALID
      setErrors({
        [err.path]:t(err?.message),
        inValid: true,
      })
    });
  }

  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          <Typography variant="h4">
          {t('Lisää uusi auto')}
          </Typography>
        </Grid>
        
      </Grid>
      
        <form onSubmit={handleSubmit} onError={handleErrors}>
          <Box marginBottom="32px">
            { loading ? (
              <SpinnerCentered />
            ) : (
              <VehicleForm values={values} updateValues={updateValues} errors={errors} validateForm={validateForm}  />
            )}
          </Box>
          
          { errors && errors.inValid ? (
            <Fragment>
              <Typography variant="subtitle2" color="error" gutterBottom>
                {t('Lomakkeella on virheitä')}
              </Typography>
              <Box>
              {
                Object.keys(errors).map((key, index) => (
                  <Box><Typography key={index} variant="body" color="error" gutterBottom>
                    {errors[key]}
                  </Typography></Box>
                ))
              }
              </Box>
            </Fragment>
          ) : null}
          
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ButtonDefault component={Link} to={"/dashboard/vehicles"}>
                    {t('Peruuta')}
                    </ButtonDefault>
                  </Grid>
                  { myUser?.status == 100 && values?.id && values?.id >= 0 ? (
                  <Grid item>
                    <ButtonDelete onClick={() => handleDelete(false)}>
                      {t('Poista auto')}
                    </ButtonDelete>
                  </Grid>
                  ) : null }
                </Grid>
              </Grid>
              { myUser?.status == 100 ? (
                <Grid item>
                { values?.id && values?.id >= 0 ? (
                  <ButtonPrimary type="submit" loading={saving} disabled={errors?.inValid}>
                      {t('Tallenna')}
                  </ButtonPrimary>
                  ) : (
                  <ButtonPrimary type="submit" loading={saving} disabled={errors?.inValid}>
                      {t('Lisää auto')}
                  </ButtonPrimary>)
                }
                </Grid>
              ) : null }
            </Grid>
          </Grid>
        </form>
      
      <AlertDialog 
        titleText={t('Huom!')}
        contentText={t('Sinun täytyy lisätä yrityksesi maksutiedot ja hinnasto, jotta voit lisätä kuljettajia ja autoja.')}
        open={alertOpen}
        setOpen={setAlertOpen}
        showCancel={false}
        confirmText={t('OK')}
        handleConfirm={() => history.push("/dashboard/start")}
        isModal={true}
      />

      <AlertDialog 
        titleText={t('Vahvista poisto')}
        contentText={t('Haluatko varmasti poistaa auton?')}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        cancelText={t('Peruuta')}
        confirmText={t('Poista')}
        handleConfirm={() => handleDelete(true)}
      />
      
    </Paper>
    
    
  )
}

export default withSnackbar(VehicleEdit);