import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack';

import RegisterUser from './RegisterUser'

const mapStateToProps = state => ({
  // authReducer: state.auth
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(RegisterUser))
)
