import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    flexGrow: 1,
    height: "100%",
    overflowX: 'auto',
    padding: 48,
  }
}));

const Service = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  return (
    <Fragment>
      <Paper className={classes.paper} elevation={0} square>
        <Box marginBottom={4}>
          <Typography variant="h4">{t('Palvelusopimukset ja tietosuoja')}</Typography>
        </Box>
        
        <Grid container spacing={3}>
          <Grid item xs>
            <Box marginBottom={2}>
              <Link
                to={'/dashboard/service/prices'}
              >
                {t("Hinnasto")}
              </Link>
            </Box>
            <Box marginBottom={4}>
              <a href="https://opentaxi.fi/legal" target="_blank">{t("Käyttöehdot ja tietosuoja")}</a>
            </Box>
          </Grid>
        </Grid>
          
      </Paper>
    </Fragment>
  );
  
}

export default Service;