import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    flexGrow: 1,
    height: "100%",
    overflowX: 'auto',
    padding: 48,
  }
}))

const Prices = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  return (
    <Paper elevation={0} square className={classes.paper}>
        <Grid 
          container
          direction="row"
          justify="left"
          alignItems="top"
          spacing={2}
          >
          
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <Typography variant="h2" color="primary" gutterBottom>{t('Hinnasto')}</Typography>
              <Typography>päivitetty 28.4.2020</Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h3" color="primary" gutterBottom>
              Välityspalkkio
            </Typography>
            
            <Typography gutterBottom>
              Easy Call Finland Oy:n (”Easy Call”) välityspalkkio Palvelun kautta solmittujen Kuljetussopimusten verottomasta Sopimusmaksusta on 10 %.
            </Typography>
            
            <Typography variant="h3" color="primary" gutterBottom>
              Muut palkkiot
            </Typography>
            
            <Typography gutterBottom>
              Sopimukseen perustuvista maksutapahtumista/rahavarojen siirroista peritään mahdollisen ulkopuolisen maksujen välittäjän kulloinkin perimä palkkio. Puutteellisten tai hylättyjen tilitystietojen selvittämisestä peritään tuntityönä 75 euroa/tunti (alv. 0%), kuitenkin vähintään 50,00 euroa (alv. 0%). Muiden tietojen selvittäminen tuntityönä 75 euroa/tunti (alv. 0%), kuitenkin vähintään 50,00 euroa (alv. 0%).
            </Typography>
            
            <Typography variant="h3" color="primary" gutterBottom>
              Palkkioiden määräytymisperusteet
            </Typography>
            
            <Typography gutterBottom>
              Välityspalkkio määräytyy Kuljetusyrityksen ja Asiakkaan väliseen Kuljetussopimukseen perustuvan Sopimusmaksun perusteella. Muiden palkkioiden määrä perustuu kustannusvastaavuuteen.
            </Typography>
            
            <Typography variant="h3" color="primary" gutterBottom>
              Ohjeet ja määräykset
            </Typography>
            
            <Typography gutterBottom>
              Sopimusmaksu on Asiakkaan Kuljetussopimuksen perusteella Palvelun kautta Easy Callille maksama maksu Kuljetuspalvelusta. Sopimusmaksu on maksu Kuljetuspalvelusta, jonka Asiakas on hyväksynyt hyväksyessään tarjouksen ja näin solmiessaan Kuljetussopimuksen Kuljetusyrityksen kanssa.Kuljetusyrityksen Palvelun kautta tarjoama Kuljetuspalvelun hinta on kokonaishinta, jonka Asiakas maksaa Sopimusmaksuna. Kuljetusyrityksen Palvelun kautta tarjoaman Kuljetuspalvelun kokonaishinnan tulee olla kustannuksia vastaava, sisältää kaikki verot, mahdolliset auttamis-, palvelu- ja muut lisät sekä muut maksut. Asiakkaan Palvelun kautta maksama Sopimusmaksu varataan asiakkaan maksukortilta Asiakkaan hyväksyessä Kuljetusyrityksen tarjouksen, jolloin Asiakas solmii Kuljetussopimuksen Kuljetusyrityksen kanssa. Asiakas on velvollinen luovuttamaan Palvelun kautta saamansa tunnistetiedon (PIN-koodin) Kuljetusyrityksen edustajalle (Kuljettajalle). Sopimusmaksu veloitetaan Asiakkaan maksukortiltaKuljetusyrityksen edustajan syötettyä tunnistetiedon (PIN-koodin) Palveluun.Vastaanotettuaan Palvelun kautta Sopimusmaksun Easy Call tilittää Tilitysmaksun Kuljetusyrityksen Palvelussa ilmoittamalle pankkitilille viipymättä, kuitenkin viimeistään seitsemän (7) pankkipäivän kuluessa Sopimusmaksun vastaanottamisesta. Puutteelliset ja epäselvät tiedot hidastavat Tilitysmaksujen tilitystä tai voivat johtaa tilityksen hylkäämiseen. Easy Call vähentää Asiakkaan maksamasta Sopimusmaksusta Hinnaston mukaisen Välityspalkkion. Easy Call ei peri Asiakkaalta Sovelluksen käytöstä maksua. Sovellus on maksuton. Muut palkkiot laskutetaan selvitystyöntilaajalta.
            </Typography>
            
            <Typography variant="h3" color="primary" gutterBottom>
            Muutokset ja päivitykset
            </Typography>
            
            <Typography gutterBottom>
            Easy Callilla on yksipuolinen oikeus tehdä muutoksia/päivityksiä Hinnaston sisältöön ilmoittamalla muutoksista/päivityksistä Palvelussa.
            </Typography>
            
          </Grid>
        </Grid>
    </Paper>
  );
}

export default Prices;