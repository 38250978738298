import React, { Fragment, useState, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { httpGetPrice, httpCreatePrice, httpUpdatePrice, httpDeletePrice } from '~/services/http/prices'

import MyTextField from "components/Inputs/MyTextField"
import ButtonSuccess from '~/components/Buttons/ButtonSuccess';
import ButtonDelete from '~/components/Buttons/ButtonDelete';
import ButtonDefault from '~/components/Buttons/ButtonDefault';
import SpinnerCentered from '~/components/Loader/SpinnerCentered';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const defaultValues = {
  id: -1,
  name:"",
  opening_price: "0",
  price_per_unit: "0",
  special_opening_price: "0",
  special_price_per_unit: "0",
  
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  paperRoot: {
    borderRadius: '25px'
  }
}))

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default function CustomizedDialogs(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {open, setOpen, handleClose, id, getMyCompany} = props;
  
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({inValid:true});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    
  },[]);
  
  useEffect(() => {
    if (id > -1)
    {
      getPrice();
    }
    else
    {
      setValues(defaultValues);
      setErrors({inValid:true});
    }
    
  },[id]);
  
  
  const validationSchema = yup.object().shape({
    special_price_per_unit: yup
        .number()
        .required(t("Pakollinen kenttä"))
        .transform(emptyStringToNull).nullable()
        ,
    special_opening_price: yup
        .number()
        .required(t("Pakollinen kenttä"))
        .transform(emptyStringToNull).nullable()
        ,
    price_per_unit: yup
        .number()
        .required(t("Pakollinen kenttä"))
        .transform(emptyStringToNull).nullable()
        ,
    opening_price: yup
        .number()
        .required(t("Pakollinen kenttä"))
        .transform(emptyStringToNull).nullable()
        ,
    name: yup
        .string()
        .required(t("Pakollinen kenttä"))
        ,
    
  });
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  const validateForm = () => {
    validationSchema.validate(values, {abortEarly: true}).then(() => {
      // GOOD
      setErrors({});
    }).catch((err) => {
      // INVALID
      setErrors({
        [err.path]:t(err?.message),
        inValid: true,
      })
    });
  }
  
  const importData = (data) => {
    // Convert from cents to euros
    let formattedData = {
      ...data,
      opening_price: data?.opening_price/100.0,
      price_per_unit: data?.price_per_unit/100.0,
      special_opening_price: data?.special_opening_price/100.0,
      special_price_per_unit: data?.special_price_per_unit/100.0,
    }
    return formattedData;
  }
  
  const exportData = (data) => {
    // Convert from euros to cents
    let formattedData = {
      ...data,
      opening_price: (data?.opening_price*100.0).toFixed(0),
      price_per_unit: (data?.price_per_unit*100.0).toFixed(0),
      special_opening_price: (data?.special_opening_price*100.0).toFixed(0),
      special_price_per_unit: (data?.special_price_per_unit*100.0).toFixed(0),
    }
    return formattedData;
  }
  
  
  const getPrice = () => {
    
    setLoading(true);
    
    httpGetPrice(id).then((response) => {
        setValues(importData(response));
        setErrors({});
        setLoading(false);
        
      }, (error) => {
        setLoading(false);
      });
    
  }
  
  const savePrice = () => {
    setLoading(true);
    
    let data = exportData(values)
    
    if (values?.id != null && values?.id > -1) // Update
    {
      httpUpdatePrice(id, data).then((response) => {
        setValues(importData(response));
        setLoading(false);
        getMyCompany()
        handleClose();
      }, (error) => {
        setLoading(false);
      });
    }
    else // create
    {
      httpCreatePrice(data).then((response) => {
        setValues(importData(response));
        setLoading(false);
        getMyCompany()
        handleClose();
      }, (error) => {
        setLoading(false);
      });
    }
  }
  
  const deletePrice = () => {
    setLoading(true);
    
    if (values?.id != null && values?.id > -1) // Delete
    {
      httpDeletePrice(id).then((response) => {
        setLoading(false);
        getMyCompany()
        handleClose();
      }, (error) => {
        setLoading(false);
      });
    }
  }

  return (
    <div>
      
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.paperRoot }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          { values?.id > -1 ? (
            <Fragment>{t("Muokkaa hinnastoa")}</Fragment>
          ) : (
            <Fragment>{t("Lisää uusi hinnasto")}</Fragment>
          )}
        </DialogTitle>
        <DialogContent>
        { loading ? (
          <SpinnerCentered />
        ) : (
          <Fragment>
            <MyTextField
              autoFocus
              disabled={values?.id === 0}
              customLabel={t('Hinnaston nimi')}
              required
              id="price_dialog_name"
              name="name"
              value={values.name || ""}
              onChange={handleFormChange}
              inputProps={{
                readOnly: false,
              }}
              onBlur={validateForm}
              error={errors?.name}
              helperText={errors?.name}
            />
            
            <Typography variant="subtitle2">{t("Perusmaksut (ma-la klo 06-18)")}</Typography>
            
            <MyTextField
              customLabel={t('Aloitusmaksu')}
              required
              id="price_dialog_opening_price"
              name="opening_price"
              value={values.opening_price || ""}
              onChange={handleFormChange}
              inputProps={{
                readOnly: false,
              }}
              onBlur={validateForm}
              error={errors?.opening_price}
              helperText={errors?.opening_price}
              type="number"
            />
            
            <MyTextField
              customLabel={t('Hinta/km')}
              required
              id="price_dialog_price_per_unit"
              name="price_per_unit"
              value={values.price_per_unit || ""}
              onChange={handleFormChange}
              inputProps={{
                readOnly: false,
              }}
              onBlur={validateForm}
              error={errors?.price_per_unit}
              helperText={errors?.price_per_unit}
              type="number"
            />
            
            <Typography variant="subtitle2">{t("Muut ajat (pyhät ja illat)")}</Typography>
            
            <MyTextField
              customLabel={t('Aloitusmaksu')}
              required
              id="price_dialog_special_opening_price"
              name="special_opening_price"
              value={values.special_opening_price || ""}
              onChange={handleFormChange}
              inputProps={{
                readOnly: false,
              }}
              onBlur={validateForm}
              error={errors?.special_opening_price}
              helperText={errors?.special_opening_price}
              type="number"
            />
            
            <MyTextField
              customLabel={t('Hinta/km')}
              required
              id="price_dialog_special_price_per_unit"
              name="special_price_per_unit"
              value={values.special_price_per_unit || ""}
              onChange={handleFormChange}
              inputProps={{
                readOnly: false,
              }}
              onBlur={validateForm}
              error={errors?.special_price_per_unit}
              helperText={errors?.special_price_per_unit}
              type="number"
            />
          </Fragment>
        )}
          
          
        </DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <ButtonDefault onClick={handleClose}>{t("Peruuta")}</ButtonDefault>
              { values?.id > -1 ? (
                <ButtonDelete onClick={deletePrice} disabled={values?.id === 0}>{t("Poista")}</ButtonDelete>
              ) : null }
            </Grid>
          </Grid>
          <Grid item>
            <ButtonSuccess disabled={errors.inValid || loading} onClick={savePrice}>{t("Tallenna")}</ButtonSuccess>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}