import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import MySelectField from "components/Inputs/MySelectField"
import { getDrivers } from '~/services/http/drivers'

const useStyles = makeStyles(theme => ({
  
}));

const DriverSelect = (props) =>
{
  const { enums, value, updateValues, companies, myUser } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const [drivers, setDrivers] = useState();
  
  useEffect(() => {
    const request = {
      params: {
        drivers: true,
        company: companies?.data?.me?.id
      }
    }
    getDrivers(request).then((response) => {
      if (myUser?.status < 100)
      {
        setDrivers([
          {...myUser}
        ]);
        
        updateValues({ ['driver']: myUser?.user_role_id })
      }
      else
      {
        setDrivers(
        [
          {user_role_id:-1,first_name:'Kaikki'},
          ...response
        ])
      }
    })      
  }, [])
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  return (
    <Fragment>
    
      <MySelectField
        id="select_driver"
        name="driver"
        value={value ? value : -1}
        onChange={handleSelectChange}
      >
        {drivers ? drivers.map(driver => (
          <MenuItem key={driver.user_role_id} value={driver.user_role_id}>
            {driver.first_name} {driver.last_name} 
          </MenuItem>
        )) : null}
      </MySelectField>
    </Fragment>
  )
}

export default DriverSelect;
