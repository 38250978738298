import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import { useCookies } from 'react-cookie';

import Grid from '@material-ui/core/Grid';

import ActivateUserToken from '../ActivateUserToken';
import Login from 'views/Login';
import RegisterAdmin from 'views/RegisterAdmin';
import RegisterUser from 'views/RegisterUser';
import PasswordReset from 'views/PasswordReset';

import styles from './Auth.css';
import logo from 'assets/logo.png'

import AuthService from 'services/http/AuthService'
const auth = new AuthService();


const Auth = (props) => {
  
  const { match } = props;
  // const [cookies, setCookie] = useCookies(['sessionid']);
  const classes = styles();

  useEffect(() => {
    // Remove existing token if exists
    auth.logout()
    // setCookie('sessionid', 'a', { path: '/' });
    //document.cookie = 'sessionid'+'=; Max-Age=-99999999;';
    
  }, [])
  
  return (
    <Fragment>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.navContainer}>
        <Grid item><img src={logo} alt="logo" className={classes.logo} /></Grid>
      </Grid>

      <Grid container alignContent="center" alignItems="center" className={classes.introContainer}>
        <Grid item xs={12} className={classes.introItem}>
          
            <Switch>
              <Route path={`${match.path}/login`} render={(props) => <Login {...props} />} />
              <Route path={`${match.path}/register`} exact component={RegisterAdmin} />
              <Route path={`${match.path}/register/user`} exact component={RegisterUser} />
              <Route path={`${match.path}/reset`} component={PasswordReset} />
              <Route path={`${match.path}/activate`} component={ActivateUserToken} />
              <Redirect to={`${match.path}/login`} />
            </Switch>
          
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Auth;