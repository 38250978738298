import React, { Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import VehicleTypeSelect from 'components/Inputs/VehicleTypeSelect'
import CitySelect from 'components/Inputs/CitySelect'
import CityMultiSelect from 'components/Inputs/CityMultiSelect'
import ProvinceMultiSelect from 'components/Inputs/ProvinceMultiSelect'

const useStyles = makeStyles(theme => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1
  },

}));

const CompanyAreaForm = (props) =>
{
  // hooks
  const [loading, setLoading] = useState(false);
  const [validFields, setValidFields] = useState({
    receiverPhone:false,
    receiverEmail:false,
  });
  const [driverType, setDriverType] = useState(0);
  
  // props
  const { values, updateValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  useEffect(() => {
    let unmountComponent = false;
    
    return () => {
      unmountComponent = true;
    };
    
  }, []);
  
  const handleDriverType = (event, newType) => {
    setDriverType(newType);
  };
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: {id:target.value} })
  }
  
  return (
    <Fragment>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={12} md={6}>
          
            <Grid container direction="row" spacing={1}>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                {t('Ensisijainen paikkakunta (viranomaistilastointi)')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CitySelect name="main_city" value={values?.main_city?.id || -1} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('Toissijaiset paikat (ei pakollinen)')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CityMultiSelect name="secondary_cities" value={values.secondary_cities} updateValues={updateValues} />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('Kela-alueet')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ProvinceMultiSelect name="kela_areas" value={values.kela_areas} updateValues={updateValues} />
              </Grid>
              
              

            
            </Grid>
          
          </Grid>
        
          
        
        </Grid>


    </Fragment>
  )
  
}

export default CompanyAreaForm;
