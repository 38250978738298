import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {getMyCompany} from '~/state/companies'
import CompanyEdit from './CompanyEdit'

const mapStateToProps = state => ({
  users: state.users,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getMyCompany,
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyEdit)
)
