import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import logo from 'assets/logo.png'
import BackgroundImage from 'assets/TilaaTaksi_099.jpg'
import Prices from "./Prices"
import ViewContainer from 'components/ViewContainer/ViewContainer'

const useStyles = makeStyles(theme => ({
  navContainer: {
    
  },
  logo: {
    height: 50,
    margin: 24,
  },
  logoMobile: {
    height: 50,
    padding: 8,
  },
  introContainer: {
    minHeight: '100vh',
    paddingTop: 32,
    paddingBottom: 32,
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundImage: " linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + BackgroundImage + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
  },
}));

const Legal = (props) => {
  
  const { match } = props;
  const classes = useStyles();
  
  return (
    <Fragment>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.navContainer}>
        <Grid item><img src={logo} alt="logo" className={classes.logo} /></Grid>
      </Grid>
      
      <Grid container alignContent="center" alignItems="center" className={classes.introContainer}>
        <Grid item xs={12} className={classes.introItem}>
          <ViewContainer>
            <Switch>
              <Route path={`${match.path}/prices`} render={(props) => <Prices {...props} />} />
              <Redirect to={`/auth/login`} />
            </Switch>
          </ViewContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Legal;