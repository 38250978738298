import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import AuthService from 'services/http/AuthService'
import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonPrimary from 'components/Buttons/ButtonPrimary'
import { sendPasswordResetConfirmation } from 'services/http/users'

const auth = new AuthService();

const styles = theme => ({
  fieldGrid: {
    marginBottom: 12
  }
});

const PasswordReset = (props) => {
  
  const { classes, login, history, enqueueSnackbar } = props;
  const { t } = useTranslation();
  
  const [values, setValues] = useState({
    email:''
  })
  
  const [errors, setErrors] = useState(null);
  
  const handleInputChange = (event) => {
    let { value, name } = event.target;
    
    if (["email"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    
    setValues({
      ...values,
      [name]: value
    });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const request = {
      data: {
        email: values.email
      }
    };
    
    sendPasswordResetConfirmation(request).then((response) => {
      enqueueSnackbar(t("Salasanan palautuslinkki on lähetetty sähköpostiisi"), { variant: "success" });
      history.push("/auth/login")
    }, (error) => {
      setErrors(true);
      enqueueSnackbar(t("Pyyntö epäonnistui. Yritä uudelleen myöhemmin."), { variant: "error" });
    });
    
  }

  return (
      <ViewContainer title={t('Palauta salasana')}>
        
        <form className={classes.root} onSubmit={onSubmit}>
          <Grid 
            container
            className={classes.container}
            >
              <Grid item xs={12} className={classes.fieldGrid}>
                <Typography variant="subtitle1">{t('Sähköposti / käyttäjätunnus')}</Typography>
                <TextField
                  required
                  fullWidth
                  id="password-reset-email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  type="email"
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <ButtonPrimary type="submit">
                        {t('Palauta salasana')}
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
              
              { errors ? (
              <Typography variant="subtitle2" color="error" gutterBottom>
                {t('Salasanan palauttaminen epäonnistui. Tarkista sähköpostiosoite/käyttäjätunnus')}
              </Typography>
              ) : null }
            
            </Grid>
        </form>
      </ViewContainer>


  );
  
}

export default withStyles(styles)(PasswordReset);