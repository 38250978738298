import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
    display: 'block',
  },
  th: {
    paddingLeft: 0,
  },
  cell: {
    borderBottomWidth: 0,
  },
  normalTypo: {
    fontWeight: 300,
  }
}));

const CompanyInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  const {company} = props;
  
  return (
    <Fragment>
      <Paper className={classes.root} elevation={0} square>
      
        <Table className={classes.table} size="small" fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Yritys')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.name}</Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Taksipalvelut')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}></Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Kela-alueet')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                { company?.kela_areas && company?.kela_areas.length > 0 ? (
                  company.kela_areas.map((kela_area, index) => {
                    return <Typography key={index} variant="h4" className={classes.normalTypo}>{kela_area.name}</Typography>
                  })
                ): null }
                
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Paikkakunta')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.city?.name}</Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Muut alueet')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                { company?.secondary_cities && company?.secondary_cities.length > 0 ? (
                  company.secondary_cities.map((s_city, index) => {
                    return <Typography key={index} variant="h4" className={classes.normalTypo}>{s_city.name}</Typography>
                  })
                ): null }
              
                
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Www-sivu')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.web_url}</Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Puhelin')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.phone}</Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Yhteyshenkilö')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.contact_name}</Typography>
              </TableCell>
            </TableRow>
            
            <TableRow >
              <TableCell component="th" scope="row" borderBottom={0} className={`${classes.cell} ${classes.th}`}>
                <Typography variant="h4">{t('Yhteyshenkilön puhelinnumero')}:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="h4" className={classes.normalTypo}>{company?.contact_phone}</Typography>
              </TableCell>
            </TableRow>
            
            

          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

export default CompanyInfo;

