import http from 'services/http/httpInstance'
import { authHeader } from 'services/http/AuthService'

export function uploadImageFile(url, field_name, file)
{
  return new Promise((resolve, reject) => {
    if (file == null)
    {
      return resolve(null);
    }
    
    let formData = new FormData();
    formData.append(field_name,file)
    
    const config = {
      headers: {
      ...authHeader(),
      'content-type': 'multipart/form-data'
      },
    }
    
    http.patch(url, formData, config).then((response) => {
        resolve(true);
        // reject('Error')
    }, (error) => {
      reject(error);
    });
    
  })
  
}

export function uploadMediaFile(url, file)
{
  return new Promise((resolve, reject) => {
    
    if (file == null)
    {
      return resolve(null);
    }
    
    let formData = new FormData();
    formData.append('media_type',1);
    formData.append('image',file);
    
    const requestOptions = {
      url: url,
      data: formData,
      method: "PATCH",
      headers: {
        ...authHeader(),
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => console.log(progressEvent.loaded),
    }
    
    http(requestOptions).then(data => {
      return resolve(data)
    }, (error) => {
      return reject(error);
    });
    
  });
  
}
