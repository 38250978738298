import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Taxi from './Taxi';

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Taxi);