import React, { Fragment, useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';

import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import ButtonSuccess from '~/components/Buttons/ButtonSuccess';
import { getDrivers } from '~/services/http/drivers'
import { httpGetInvitedUsers, httpInviteResend } from 'services/http/companies'
import { validateCompany } from 'services/companies'
import AlertDialog from 'components/AlertDialog'

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 'bold',
        backgroundColor: 'lightblue',
        "&:last-child": {
          paddingRight: 5
        }
      }
    },
  },
  content: {
    padding: 48,
    overflowX: 'auto',
  },
  title: {
    paddingBottom: 32,
  },
  table: {
    minWidth: 650,
    borderWidth: 1,
    borderColor: theme.palette.table.border,
    borderStyle: 'solid',
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  tableHeaderCell: {
    color: theme.palette.table.thead.text,
    fontSize: 16,
    fontWeight: 900,
    backgroundColor: theme.palette.table.thead.background,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },
  buttonLink: {
    textDecoration:'none'
  }

}))

const DriversList = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, myCompany, myUser } = props;
  
  const [rows, setRows] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false)
  
  useEffect(() => {
    handleValidateCompany();
    handleGetDrivers();
    handleGetUserInvites();
  },[]);

  const handleValidateCompany = () => {
    if (validateCompany(myCompany) === false)
    {
      setAlertOpen(true)
    }
  }
  
  const handleGetDrivers = () => {
    setLoading(true);
    const request = {
        params: {
          drivers:true,
          company: myCompany?.id
        }
    };
    
    getDrivers(request).then((response) => {
        let _users = [];
        response.map((user) => {
          // if you want to skip admins
          /*
          if (user.status < 100)
          {
            _users.push(user);
          }
          */
        })
        setRows(response);
        setLoading(false);
        
      }, (error) => {
        setLoading(false);
      });
  }
  
  const handleGetUserInvites = () => {
    setLoading(true);

    const request = {};

    
    httpGetInvitedUsers(request).then((response) => {
        let _users = [];
        response.map((user) => {
          if (user?.status < 100)
          {
            _users.push(user);
          }
        })
        setInvitedUsers(_users);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  }
  
  const handleInviteResend = (id) => {
    httpInviteResend(id).then((response) => {
        props.enqueueSnackbar('Kutsu lähetetty', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
      }, (error) => {
        props.enqueueSnackbar('Kutsun lähetys epäonnistui', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
      });
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            {t('Kuljettajat')}
          </Typography>
          {myUser?.status < 100 ? (
            <Typography>
            {t('Vain yrityksen ylläpitäjä pystyy lisäämään kuljettajia')}
            </Typography>
          ) : null }
        </Grid>
        
        {myUser?.status == 100 ? (
        <Grid item>
          <Link
            className={classes.buttonLink}
            to={'/dashboard/drivers/new'}
          >
            <ButtonSuccess>
              {t('Lisää kuljettaja')}
            </ButtonSuccess>
          </Link>
        </Grid>
        ) : null }
      </Grid>
      
      { loading ? (
        <SpinnerCentered />
      ) : (
      <Fragment>
        <Box marginBottom={4}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              { myUser?.status == 100 ? (
              <TableCell className={classes.tableHeaderCell} align="left">{t('Tarkastettu')}</TableCell>
              ) : null }
              <TableCell className={classes.tableHeaderCell} align="left">{t('Kuljettaja')}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="left">{t('Liitetty autoon')}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                { myUser?.status == 100 ? (
                <TableCell>
                  <CheckCircle fontSize="default" className={row.verified ?  classes.confirmed : classes.notConfirmed}/>
                </TableCell>
                ) : null }
                <TableCell component="th" scope="row">
                  { myUser?.status == 100 || myUser?.id == row.id ? (
                  <Link
                    className={classes.buttonLink}
                    to={"/dashboard/drivers/"+row.id+"/edit"}
                  >
                    <Typography color="primary" variant="subtitle2">{row.first_name} {row.last_name}</Typography>
                  </Link>
                  ) : (
                    <Typography variant="subtitle2">{row.first_name} {row.last_name}</Typography>
                  )}
                  <Typography>{row.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{ row?.car?.license_number }</Typography>
                </TableCell>
                <TableCell align="right">
                { myUser?.status == 100 || myUser?.id == row.id ? (
                  <Button component={Link} to={"/dashboard/drivers/"+row.id+"/edit"}>
                    <Typography color="primary" variant="subtitle2">{t('Avaa tiedot')}</Typography>
                  </Button>
                ) : null }
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Box>
        
        <Box marginBottom={2}>
          <Typography variant="h4">
            {t('Kuljettajakutsut')}
          </Typography>
        </Box>
        
        <Box marginBottom={4}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} align="left">{t('Sähköposti')}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          
          { loading ? (
            <TableBody>
              <TableRow>
                <TableCell colspan="2">
                  <SpinnerCentered />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {invitedUsers.map(row => (
                <TableRow key={row.name}>
                  <TableCell>
                    {row.email}
                  </TableCell>
                  <TableCell align="right">
                    { myUser?.status == 100 ? (
                      <Button onClick={() => handleInviteResend(row.id)}>
                        <Typography color="primary" variant="subtitle2">{t('Lähetä uudelleen')}</Typography>
                      </Button>
                    ) : null}
                  </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        </Box>
      </Fragment>
      )}

    <AlertDialog 
      titleText={t('Huom!')}
      contentText={t('Sinun täytyy lisätä yrityksesi maksutiedot ja hinnasto, jotta voit lisätä kuljettajia ja autoja.')}
      open={alertOpen}
      setOpen={setAlertOpen}
      showCancel={false}
      confirmText={t('OK')}
      handleConfirm={() => history.push("/dashboard/start")}
      isModal={true}
    />
    
    </Paper>
  )
}

export default withSnackbar(DriversList);