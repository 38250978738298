export default function dataPlugin(resourceType, options) {
  return function(state, action) {
    if (resourceType == action.resourceType && action.data) {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };
    }

    return {
      ...state
    };
  };
}
