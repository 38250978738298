import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ButtonPrimary from '~/components/Buttons/ButtonPrimary'
import { httpGetReceipt } from "~/services/http/orders"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    padding: 0,
  },
  table: {
    minWidth: 650,
    display: 'block',
  },
  cell: {
    //paddingTop: 32,
    borderBottomWidth: 0,
  },
  normalTypo: {
    fontWeight: 300,
  }
}));



const OrderDetail = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  const {order} = props;
  
  const exampleData = [
    {
      label: t('Kuljettaja'),
      content: "Danny Haslo"
    },
    {
      label: t('Auto'),
      content: "CFV-657"
    },
    {
      label: t('Lähtöpiste'),
      content: "Koulutie 9, Turku"
    },
    {
      label: t('Määränpää'),
      content: "Ei tunnistettu"
    },
    {
      label: t('Päivämäärä'),
      content: "13.05.2020 - 17:03 (päättyi: 19:03)"
    },
    {
      label: t('Henkilömäärä'),
      content: "2"
    },
    {
      label: t('Matkalaukut'),
      content: "0"
    },
    {
      label: t('Muut rajoitukset'),
      content: "Rollaattori"
    },
  ]

  const [dataRows, setDataRows] = useState();
  const [paymentData, setPaymentData] = useState({
    card: 'xxxx xxxx xxx5465',
    price_vat_excluded: '27,40€',
    price_vat: '6,34€',
    price_total: '33,74€',
  });

  useEffect(() => {
    // setDataRows(order)
  }, [order])
  
  const formatDistance = (dist) => {
    return (Math.round(dist * 1000) / 1000).toFixed(1)
  }
  
  const downloadReceipt = () => {
    httpGetReceipt(order.id).then((response) => {
      
      /*
      const blob = new Blob([response], {type: 'octet/stream'});
      const url  = window.URL.createObjectURL(blob);
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );
      */
      
      // window.location.assign(url);
      
      
      const data = new Blob([response], {type: 'octet/stream'});
      const url = window.URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'receipt.pdf');
      tempLink.click();
      
      
    })
  }
  
  return (
    <Fragment>
      <Paper className={classes.root} elevation={0} square>
        
        <Grid container spacing={1}>
        
          <Grid item xs={12} sm={12} md={8}>
          
          <Table className={classes.table} size="small" fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
            <TableBody>
              
              <TableRow >
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                   <Box marginBottom="16px">
                    <Typography variant="h3">{t('Ajo')} {order?.end_time ? moment(order?.end_time).format('DD.M.YYYY H:mm') : null}</Typography>
                   </Box>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Kuljettaja')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.driver_first_name} {order?.driver_last_name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Auto')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.license_number}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Lähtöpiste')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.start_street_address}, {order?.start_city?.name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Määränpää')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.end_street_address}, {order?.end_city?.name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Matkan pituus')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.distance ? (
                      <Fragment>
                        {formatDistance(order?.distance)}km ({t('laskennallinen')})
                      </Fragment>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">&nbsp;</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.completed_distance ? (
                      <Fragment>
                        {formatDistance(order?.completed_distance)}km ({t('toteutunut')})
                      </Fragment>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Päivämäärä')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.start_time ? moment(order?.start_time).format('DD.M.YYYY') : null} - {order?.start_time ? moment(order?.start_time).format('H:mm') : null}
                    &nbsp;
                    ({t('päättyi')}: {order?.start_time ? moment(order?.end_time).format('H:mm') : null})
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Henkilömäärä')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.passenger_count}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Matkalaukut')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.suitcases}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                  <Typography variant="h4">{t('Muut rajoitukset')}:</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.rollator ? (
                      <Fragment>{t('Rollaattori')}</Fragment>
                    ):null}
                  </Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.wheelchair ? (
                      <Fragment>{t('Pyörätuoli')}</Fragment>
                    ):null}
                  </Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="h4" className={classes.normalTypo}>
                    {order?.taped ? (
                      <Fragment>{t('Teipattu')}</Fragment>
                    ):null}
                  </Typography>
                </TableCell>
              </TableRow>
              


              { /* dataRows&& dataRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Typography variant="h4">{row.label}</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>
                      {row.content}
                    </Typography>
                  </TableCell>
                </TableRow>
              )) */}
              
            </TableBody>
            </Table>
            
            <Box marginBottom="32px"></Box>
            
            {order?.receipts && order?.receipts.length > 0 ? (
              <Table className={classes.table} size="small" fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
              <TableBody>
                
                <TableRow >
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Box marginBottom="16px">
                      <Typography variant="h3">{t('Maksutiedot')}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Typography variant="h4">{t('Kortti')}</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>
                      {order?.receipts[0].card}
                    </Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Typography variant="h4">{t('Hinta ilman alvia')}</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>
                      {order?.receipts[0].price_without_vat}€
                    </Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Typography variant="h4">{t('Alv')} ({order?.receipts[0].driver_tax_percentage})%</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>
                      {order?.receipts[0].vat_in_cash}€
                    </Typography>
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" borderBottom={0} className={classes.cell}>
                    <Typography variant="h4">{t('Yhteensä')}</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography variant="h4" className={classes.normalTypo}>
                    {order?.receipts[0].payment}€
                    </Typography>
                  </TableCell>
                </TableRow>
                  
              </TableBody>
            </Table>
          ) : null}

          </Grid>
          
          <Grid item xs={12} sm={12} md={4}>
            <ButtonPrimary onClick={downloadReceipt}>
            {t('Tulosta PDF kuitti')}
            </ButtonPrimary>
          </Grid>
        
        </Grid>
        
      </Paper>
    </Fragment>
  )
}

export default OrderDetail;

