import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonDelete from 'components/Buttons/ButtonDelete';
import SpinnerCentered from 'components/Loader/SpinnerCentered';

import { httpInviteUser } from '~/services/http/companies'
import { getDriver, saveDriver, deleteDriver } from 'services/http/drivers'
import DriverForm from 'components/DriverForm';
import AlertDialog from 'components/AlertDialog'
import { validateCompany } from 'services/companies'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(6),
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
}))

const DriverEdit = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, match, myCompany, myUser, users, ...rest } = props;
  const { driverId } = match.params;
  
  const [values, setValues] = useState({
    // phone: '+358',
    status: 1,
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false)

  useEffect(() => {
    handleValidateCompany()
  }, []);
  
  useEffect(() => {
    if (driverId && driverId >= -1)
    {
      handleGetDriver();
    }
  }, [driverId]);

  const handleValidateCompany = () => {
    if (validateCompany(myCompany) === false)
    {
      setAlertOpen(true)
    }
  }
  
  const handleGetDriver = () => {
    setLoading(true);
    const request = {
      params: {
        drivers:true,
        company: myCompany?.id,
      },
      data: {
        id: driverId
      }
    };
    
    getDriver(request).then((response) => {
      setValues(response);
      setLoading(false);
    });
    
  }
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (values.id) {
      handleSaveUser()
    }
    else
    {
      handleInviteUser()
    }
    
  }
  
  const handleSaveUser = () => {
    setSaving(true);
    let data = values;
    if (data.id == null)
    {
      data.username = data.email.toLowerCase();
      data.password = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    
    // Dont PATCH this existing data:
    
    if (data.profile_image)
    {
      delete data.profile_image
    }
    
    if (data.verified)
    {
      delete data.verified
    }
    // END
    
    let driver_licence = values.driver_licence;
    if (typeof driver_licence == "string")
    {
      driver_licence = driver_licence.replace(/\.[0-9]{3}/, '')
    }
    
    const request = {
      params: {
        drivers:true,
        company:myCompany?.id
      },
      data: {
        ...values,
        driver_licence: driver_licence ? driver_licence : undefined,
      }
        
    };
    
    saveDriver(request).then((response) => {
      setValues(response);
      props.enqueueSnackbar('Tallennettu', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      setErrors(null);
      setSaving(false);
    }, (errors) => {
      props.enqueueSnackbar('Tallennus epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      
      if (errors?.status == 500)
      {
        setErrors({
          '500':'Palvelin virhe'
        });
      }
      else if (errors?.data)
      {
        setErrors(errors?.data);
      }
      else
      {
        setErrors({
          '500':'Tuntematon virhe'
        });
      }
      setSaving(false);
      
    });
  }
  
  const handleInviteUser = () => {
    setSaving(true);
    const request = {
      data: {
        ...values,
        email: values.email.toLowerCase(),
      }
    }
    httpInviteUser(request).then((response) => {
      props.enqueueSnackbar('Kutsu lähetetty', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      })
      setSaving(false);
      history.push("/dashboard/drivers");
      
    }, (errors) => {
      props.enqueueSnackbar('Kutsun lähetys epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      })
      
      let errorMsg = "Tuntematon virhe";
      
      if (errors?.data?.errorCode === "ADMIN_EXISTS")
      {
        errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo muita rooleja. Roolin vaihtamiseksi ota yhteyttä tukeen support@opentaxi.fi.";
      }
      else if (errors?.data?.errorCode === "DRIVER_EXISTS")
      {
        errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo kuljettajarooli. Roolin vaihtamiseksi ota yhteyttä tukeen support@opentaxi.fi.";
      }
      else if (errors?.data?.errorCode === "CUSTOMER_EXISTS")
      {
        errorMsg = "Kutsua ei voi lähettää. Sähköpostiosoitteella on jo asiakastunnus. Käytä toista sähköpostiosoitetta tai pyydä käyttäjää poistamaan tilinsä palvelusta (mobiilisovellus -> omat tiedot -> Poista profiili)";
      }
      
      setErrors({
        '400': errorMsg,
      });
      setSaving(false);
    });
  }
  
  const handleErrors = (errors) => {
    
  }
  
  const handleCancel = () => {
    history.push("/dashboard/drivers");
  }
  
  const handleDelete = (confirmed) => {
    console.log("handleDelete");
    if (!confirmed)
    {
      setConfirmOpen(true)
    }
    else
    {
      console.log("httpDelete");
      deleteDriver(values?.id, myCompany?.id).then(() => {
        props.enqueueSnackbar('Poistettu', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
        history.push("/dashboard/drivers/list");
      }, (errors) => {
        props.enqueueSnackbar('Ei voi poistaa.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
      })
    }
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
    
    { loading ? (
      <SpinnerCentered />
    ) : (
    
    <Fragment>
    
      <Grid container className={classes.title} spacing={3} direction="row" justify="space-between" alignItems="center">
        
        <Grid item>
          { values.id && values.id > -1 ? (
            <Typography variant="h4">
              {t("Muokkaa kuljettajaa")}
            </Typography>
          ) : (
            <Typography variant="h4">
              {t("Lisää uusi kuljettaja")}
            </Typography>
          )}
        </Grid>
        
      </Grid>

        <ValidatorForm onSubmit={handleSubmit} onError={handleErrors}>
          <Box marginBottom="32px">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <DriverForm values={values} updateValues={updateValues} />
              </Grid>
            </Grid>
          </Box>
          
          { errors ? (
            <Fragment>
              <Typography variant="subtitle2" color="error" gutterBottom>
                {t('Tallennus epäonnistui')}
              </Typography>
              <Box>
              {
                Object.keys(errors).map((key, index) => (
                  <Box><Typography key={index} variant="body" color="error" gutterBottom>
                    {errors[key]}
                  </Typography></Box>
                ))
              }
              </Box>
            </Fragment>
          ) : null}
          
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ButtonDefault onClick={handleCancel}>
                      {t('Peruuta')}
                    </ButtonDefault>
                  </Grid>
                  { myUser?.status == 100 && values?.id && values?.id >= 0 ? (
                    <Grid item>
                      <ButtonDelete onClick={() => handleDelete(false)}>
                        {t('Poista kuljettaja')}
                      </ButtonDelete>
                    </Grid>
                  ) : null }
                </Grid>
              </Grid>
              
              { myUser?.status == 100 || myUser?.id == values.id ? (
                <Grid item>
                { values.id && values.id > -1 ? (
                  <ButtonPrimary type="submit">
                    {t('Tallenna')}
                  </ButtonPrimary>
                ) : (
                  <Fragment>
                  { myUser?.status == 100 ? (
                  <ButtonPrimary loading={saving} type="submit">
                      {t('Lähetä kutsu')}
                  </ButtonPrimary>
                  ) : null }
                  </Fragment>
                )}
                </Grid>
              ) : null }
            </Grid>
          </Grid>
        </ValidatorForm>
      
      </Fragment>
      
    )}
      
      <AlertDialog 
        titleText={t('Huom!')}
        contentText={t('Sinun täytyy lisätä yrityksesi maksutiedot ja hinnasto, jotta voit lisätä kuljettajia ja autoja.')}
        open={alertOpen}
        setOpen={setAlertOpen}
        showCancel={false}
        confirmText={t('OK')}
        handleConfirm={() => history.push("/dashboard/start")}
        isModal={true}
      />

      <AlertDialog 
        titleText={t('Vahvista poisto')}
        contentText={t('Kuljettaja ja sen tiedot poistetaan.')}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        cancelText={t('Peruuta')}
        confirmText={t('Poista')}
        handleConfirm={() => handleDelete(true)}
      />
      
    </Paper>
    
    
  )
}

export default withSnackbar(DriverEdit);