import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import DriverEdit from './DriverEdit'

const mapStateToProps = state => ({
  // companies: state.companies,
  myCompany: state.companies?.data?.me,
  myUser: state?.users?.data?.me,
  users: state.users
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DriverEdit)
)
