import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

// Services
import {registerServiceWorker, unregister} from './services/serviceWorker';
import './services/i18n'

// Local
import store from './state/store';
import theme from './styles/theme'
import App from './views/App';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline /> 
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'));

// Disable for now, new version cache problem
// registerServiceWorker();
unregister();

