import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  pageContainer: {
    overflowX: 'hidden',
    width: '100%'
  },
  searchTitleContainer: {
    marginTop: 100,
    padding: 32,
  },
  searchTitle: {
    textTransform: 'uppercase',
  },
   skinnyText: {
    fontWeight: 100,
  },
  buttonContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    '@media (min-width: 690px)':{
      paddingTop: 32,
      margin: '100px auto 0 auto',
    }
  },
  searchContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    '@media (min-width: 690px)':{
      paddingTop: 64,
      margin: '32 auto 0 auto',
    }
  },
  inputContainer: {
    paddingRight: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  tableContainer: {
    width: '100vw',
    overflowX: 'auto'
  },
  table: {
    minWidth: 1000,
  },

  container: {
    flexGrow: 1,
    position: 'relative',
    marginTop: 16,
    marginBottom: 8,
  },
  citySuggestionsContainer: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    maxHeight: 150,
    overflowY: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  tablePaginationToolbarBreak: {
    display: 'block',
    textAlign: 'center',
    paddingLeft: 0,
  },
  tabPaginationDiv: {
    width: '100%',
    height: 75,
  },
  notConfirmed: {
    color: '#B5B5B5',
  },
  confirmed: {
    color: '#87CC72',
  },
  coloredLabel: {
    color: theme.palette.primary.main
  },
  firstColumn: {
    padding: 14,
    paddingRight: 0,
  },
  companyNameColumn: {
    minWidth: 150,
    maxWidth: 250,
    paddingRight: 0,
    paddingLeft: 8,
  },
  cityColumn: {
    paddingLeft: 8,
    paddingRight: 0,
    minWidth: 100,
    maxWidth: 150,
  },
  phoneColumn: {
    paddingRight: 0,
    width: 160,
  },
  typeColumn: {
    paddingRight: 0,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  dialogPaper: {
    '@media (max-width: 600px)':{
      margin: 8,
    },
  },
  dialogContainer: {
    padding: 16,
    position: 'relative',
  },
  dialogTitle: {
    paddingRight: 100,
    '@media (max-width: 450px)':{
      paddingRight: 0,
    },
  },
  logoContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    '@media (max-width: 450px)':{
      position: 'relative',
      top: 0,
      right: 0,
      marginBottom: 8,
    },
  },
  inlineLogo: {
    display: 'inline-block',
    marginLeft: 8,
    '@media (max-width: 450px)':{
      marginLeft: 0,
      marginRight: 8,
    },
  },
  checkedContainer: {
    marginBottom: 16,
  },
  dialogContentLabelContainer: {
    display: 'inline-block',
    width: 100,
    textAlign: 'left',
    marginRight: 16,
  },
  dialogContentLabel: {
    fontWeight: 600,
  },
  logo: {
    width: 24,
    height: 24,
    marginLeft: 2,
  },
  dialogContactDetailsContainer: {
    marginTop: 16,
  },
  bottomDialogActions: {
    marginTop: 32,
  },
  closeButton: {
    padding: '6px 32px',
    borderRadius: 15
  },
  infoText: {
    fontSize: theme.typography.fontSize * 0.75
  },
}));