import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  value: {
    fontSize: 64,
    fontWeight: 100,
    color: '#6a31dc',
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: 32,
    }, 
  },
}));