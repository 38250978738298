let baseFontSize = 16;

if (window.innerWidth < 500) {
  baseFontSize = 14;
}

export default {
  useNextVariants: true,
  fontFamily: ['Source Sans Pro','roboto'],
  fontSize: baseFontSize,
  h1: {
    fontSize: baseFontSize * 3,
  },
  h2: {
    fontSize: baseFontSize * 2.5,
  },
  h3: {
    fontSize: baseFontSize * 2,
  },
  h4: {
    fontSize: baseFontSize * 1.5,
  },
  h5: {
    fontSize: baseFontSize * 1.25,
  },
  h6: {
    fontSize: baseFontSize * 1.125,
  },
  body1: {
    fontSize: baseFontSize,
  },
  body2: {
    fontSize: baseFontSize,
    fontWeight: 500,
  }
}
