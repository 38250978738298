import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { resourceReducer } from 'redux-resource';
import { getEnums } from './enums'
import dataPlugin from "~/state/redux-resource-custom-plugins";

const store = createStore(
  combineReducers({
   analytics: resourceReducer("analytics", { plugins: [dataPlugin] }),
   companies: resourceReducer("companies", { plugins: [dataPlugin] }),
   enums: resourceReducer("enums", { plugins: [dataPlugin] }),
   users: resourceReducer("users", { plugins: [dataPlugin] }),
  }),
  applyMiddleware(thunk)
 );

store.dispatch(getEnums());
export default store;