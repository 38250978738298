import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import DriverForm from './DriverForm'

const mapStateToProps = state => ({
  companies: state.companies,
  users: state.users,
  myCompany: state.companies?.data?.me,
  myUser: state.users?.data?.me,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DriverForm)
)
