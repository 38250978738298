import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Auth from './Auth';

const mapStateToProps = state => ({
  
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth);
