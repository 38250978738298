import React, { Fragment, useState, useEffect } from 'react';
import * as yup from 'yup';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonDefault from 'components/Buttons/ButtonDefault'
import ButtonPrimary from 'components/Buttons/ButtonPrimary'
import MyTextField from "components/Inputs/MyTextField"
import { httpUpdateMyUser } from "services/http/users"

const useStyles = makeStyles(theme => ({
  paper: {
    flexGrow: 1,
    height: "100%",
    overflowX: 'auto',
    padding: 48,
    
  }
}));

const defaultValues = {
  old_password:"",
  new_password_1:"",
  new_password_2:"",
}


const Settings = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  const { enqueueSnackbar, myCompany } = props;
  
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({inValid:true});
  const [loading, setLoading] = useState(false);
  
  const validationSchema = yup.object().shape({
    new_password_2: yup
        .string()
        .required(t("Pakollinen kenttä"))
        .min(8, t("Salasanan on oltava vähintään 8 merkkiä"))
        .oneOf([yup.ref('new_password_1'), null], t("Salasanan on täsmättävä uusi salasana kentän kanssa"))
        ,
    new_password_1: yup
        .string()
        .required(t("Pakollinen kenttä"))
        .min(8, t("Salasanan on oltava vähintään 8 merkkiä"))
        ,
    old_password: yup
        .string()
        .required(t("Pakollinen kenttä"))
        ,
  });
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  const validateForm = () => {
    validationSchema.validate(values, {abortEarly: true}).then(() => {
      // GOOD
      setErrors({});
    }).catch((err) => {
      // INVALID
      setErrors({
        [err.path]:t(err?.message),
        inValid: true,
      })
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = {
      password: values?.new_password_1,
      old_password: values?.old_password
    }
    
    setLoading(true)
    httpUpdateMyUser(data, myCompany?.id).then((response) => {
      enqueueSnackbar(t('Salasana vaihdettu'), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            }
          })
      setValues(defaultValues)
      setLoading(false)
    }, (errors) => {
      if (errors && errors?.data && errors?.data?.errorCode == "OLD_PASSWORD_WRONG")
      {
        enqueueSnackbar(t('Salasanan vaihto epäonnistui. Vanha salasana on väärin.'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            }
          })
      }
      else
      {
        enqueueSnackbar(t('Salasanan vaihto epäonnistui'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            }
          })
      }
      
      setLoading(false)
    })
  }
  
  
  return (
    <Fragment>
      <Paper className={classes.paper} elevation={0} square>
        <Box marginBottom={4}>
          <Typography variant="h4">{t('Asetukset')}</Typography>
        </Box>
        
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs>
              
              <MyTextField
                autoFocus
                customLabel={t('Nykyinen salasana')}
                required
                id="settings_old_password"
                name="old_password"
                value={values.old_password || ""}
                onChange={handleFormChange}
                inputProps={{
                  readOnly: false,
                }}
                type="password"
                onBlur={validateForm}
                error={errors?.old_password}
                helperText={errors?.old_password}
              />
              
              <MyTextField
                customLabel={t('Uusi salasana')}
                required
                id="settings_new_password_1"
                name="new_password_1"
                value={values.new_password_1 || ""}
                onChange={handleFormChange}
                inputProps={{
                  readOnly: false,
                }}
                type="password"
                onBlur={validateForm}
                error={errors?.new_password_1}
                helperText={errors?.new_password_1}
              />
              
              <MyTextField
                customLabel={t('Uusi salasana uudelleen')}
                required
                id="settings_new_password_2"
                name="new_password_2"
                value={values.new_password_2 || ""}
                onChange={handleFormChange}
                inputProps={{
                  readOnly: false,
                }}
                type="password"
                onBlur={validateForm}
                error={errors?.new_password_2}
                helperText={errors?.new_password_2}
              />
              
              <ButtonPrimary type="submit" disabled={errors.inValid || loading}>{t("Tallenna salasana")}</ButtonPrimary>
            </Grid>
            
            <Grid item xs></Grid>
           
          </Grid>
        </form>
          
      </Paper>
    </Fragment>
  );
  
}

export default withSnackbar(Settings);