import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Material-UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Material-UI icons
import View from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Copy from "@material-ui/icons/FilterNone";
import Delete from "@material-ui/icons/Delete";
import Archive from "@material-ui/icons/Archive";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Info from "@material-ui/icons/Info";

const boxStyleProps = {
  borderColor: "text.primary",
  m: 0,
  border: 0,
  style: { width: "100%", height: "12rem" },
  p: 3
};

const BoxInfo = props => {
  const { t, classes, theme, enqueueSnackbar, status, text, link } = props;
  const CustomIcon = React.cloneElement(props.icon, {
    className: classes.icon
  });

  const getContentti = () => {
    return (
      <Grid
        alignItems="center"
        className={classes.gridContainer}
        container
        direction="column"
        justify="center"
      >
        <Grid item>{CustomIcon}</Grid>

        <Grid item className={classes.titleTextContainer}>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Box
        borderRadius={24}
        className={classes.boxContainer}
        bgcolor={`${theme.palette.primary.main}`}
        {...boxStyleProps}
      >
        {status ? (
          <div className={classes.statusContainer}>
            {status == 1 ? <CheckCircle className={classes.statusOk} /> : null}

            {status == 2 ? <Info className={classes.statusWarning} /> : null}
          </div>
        ) : null}

        {link ? (
          <Fragment>
            <Link to={link} style={{ textDecoration: "none" }}>
              {getContentti()}
            </Link>
          </Fragment>
        ) : (
          <Fragment>{getContentti()}</Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default BoxInfo;
