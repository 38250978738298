import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './ButtonBase.css';

const ButtonBase = (props) => {
  const { children, loading, className, ...rest } = props;
  const classes = styles();
  
  return (
    <div className={classes.wrapper}>
      <Button {...rest} className={`${classes.button} ${className}`} disabled={props.disabled || loading} variant="contained" >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default ButtonBase;
