import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import AuthService from 'services/http/AuthService'
import ViewContainer from 'components/ViewContainer/ViewContainer'
import ButtonPrimary from 'components/Buttons/ButtonPrimary'
import MyTextField from "components/Inputs/MyTextField"

const auth = new AuthService();

const styles = theme => ({

});

const Login = (props) => {

  const [values, setValues] = useState({
    username: '',
    password: '',
  })
  
  const [errors, setErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  // props
  const { classes, history } = props;
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    let { value, name } = event.target;
    
    if (["username"].indexOf(name) > -1 )
    {
      value = value.toLowerCase();
    }
    
    setValues({
      ...values,
      [name]: value
    });
  }
  
  const validationSchema = yup.object().shape({
    username: yup
        .string()
        .required(t("Pakollinen kenttä")),
    password: yup
        .string()
        .required(t("Pakollinen kenttä")),
  });
  
  const validateForm = () => {
    return new Promise((resolve, reject) => {
      
      validationSchema.validate(values, {abortEarly: true}).then(() => {
        // GOOD
        setErrors({});
        resolve(true);
      }).catch((err) => {
        // INVALID
        setErrors(true)
        reject("NO_GOOD");
      });
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    
    validateForm().then((response) => {
      handleLogin();
    }, (errors) => {
      console.log("validateForm errors", errors);
    })
  }
  
  const handleLogin = () => {
    setSaving(true);
    const data = {
      username: values.username.toLowerCase(),
      password: values.password,
    }
    auth.login(data.username, data.password).then((response) => {
      setSaving(false);
      history.push('/dashboard/start');
    }, (error) => {
      setErrors(true);
      setSaving(false);
    });
  }

    return (
      <ViewContainer title={t('Kirjaudu sisään saamillasi tunnuksilla')}>
        <form className={classes.root} onSubmit={onSubmit}>
          <Grid 
            container
            className={classes.container}
            >
            <Grid item xs={12}>
              <MyTextField
                customLabel={t('Sähköposti / käyttäjätunnus')}
                id="login-username"
                name="username"
                value={values.username}
                onChange={handleInputChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <MyTextField
                autoComplete="current-password"
                customLabel={t('Salasana')}
                id="login-password"
                name="password"
                type="password"
                value={values.password}
                onChange={handleInputChange}
              />
              
            </Grid>

            <Grid item xs={12}>
              <Link to='/auth/reset' component={RouterLink}>
                <Typography variant="subtitle2">{t('Salasana unohtunut?')}</Typography>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item></Grid>
                <Grid item>
                  <ButtonPrimary loading={saving} type="submit">
                      {t('Kirjaudu')}
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Grid>
            
            { errors === true ? (
            <Typography variant="subtitle2" color="error" gutterBottom>
              {t('Kirjautuminen epäonnistui. Tarkista käyttäjätunnus ja salasana.')}
            </Typography>
            ) : null }
            
          </Grid>
        </form>
      </ViewContainer>
    );
}

export default withStyles(styles)(Login);