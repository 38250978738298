import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PaperView from 'components/PaperView/PaperView'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '32px'
  },
  titleError: {
    marginBottom: '32px',
    color: 'red'
  },
}));

const ViewContainer = (props) => {
  const {children, title, type} = props;
  const classes = useStyles();
  
  return (
    <Grid 
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.container}
        >
      
      <Grid item className={classes.item} xs={12} sm={8} md={8} lg={6} xl={4}>
        <PaperView className={classes.paper} square={false}>
          <Typography variant="h4" align="center" className={type == "error" ? classes.titleError : classes.title}>{title}</Typography>
          <Fragment>{children}</Fragment>
        </PaperView>
      </Grid>
    </Grid>
  )
}

ViewContainer.defaultProps = {
  type: ""
}

export default ViewContainer;

