import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {getMyCompany} from '~/state/companies'
import VehicleEdit from './VehicleEdit'

const mapStateToProps = state => ({
  myCompany: state.companies?.data?.me,
  myUser: state?.users?.data?.me
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getMyCompany,
    
  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VehicleEdit)
)
