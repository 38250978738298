import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const { open, setOpen, titleText, contentText, showCancel, isModal } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleConfirm = () => {
    props.handleConfirm();
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={isModal}
      disableEscapeKeyDown={isModal}
    >
      <DialogTitle id="alert-dialog-title">{props.titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        { showCancel ? (
          <Button onClick={handleClose} color="default">
            {props.cancelText}
          </Button>
        ) : null }
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>

  );
}

AlertDialog.defaultProps = {
  showCancel: true,
  isModal:false,
}
