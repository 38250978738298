import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Passanger car": "Passanger car",
    }
  },
  fi: {
    translation: {
      "carbon_dioxide_emission must be less than or equal to 99999": "Luku voi olla maksimissaan 99999",
      "carbon_dioxide_emission must be less than or equal to 5": "Luku voi olla maksimissaan 99999",
      "license_number is a required field": "rekisterinumero on pakollinen kenttä",
      "license_number must be at most 10 characters": "rekisterinumero voi olla maksimissaan 10 merkkiä pitkä",
      "Welcome to React": "Tervetuloa Reactiin",
      "Passanger car": "Henkilöauto",
      "Wagon": "Farmari",
      "Minivan": "Tila-auto",
      "Diesel": "Diesel",
      "Gasoline": "Bensa",
      "Natural gas": "Kaasu",
      "Electric": "Sähkö",
      "Hybrid": "Hybridi",
      "1 suitcase": "1 matkalaukku",
      "2 suitcases": "2 matkalaukkua",
      "3 suitcases": "3 matkalaukkua",
      "4 or more suitcases": "4+ matkalaukkua",
      "Tallennus epäonnitui": "Tallennus epäonnistui",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;