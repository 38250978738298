export const taxiTypes = [
  {
    value: 'all',
    label: 'Kaikki'
  },
  {
    value: 'normal',
    label: '1-4 matkustajaa'
  },
  {
    value: 'large',
    label: '5-8 matkustajaa'
  },
  {
    value: 'inva',
    label: 'Inva',
  },
  {
    value: 'kela',
    label: 'Kela',
  },
];

export const passangersTypes = [
  {
    value: 'all',
    label: 'Kaikki',
  },
  {
    value: 'normal',
    label: '1 - 4',
  },
    {
    value: 'large',
    label: '5 - 8',
  },
];

// Company

export const companyEmployeeCounts = [
  { key:0, value:"0"},
  { key:1, value:"1-2"},
  { key:2, value:"3-4"},
  { key:3, value:"5-8"},
  { key:4, value:"9-16"},
  { key:5, value:"16+"},
]

export const companyWeekdays = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
}

export const companyPaymentTypes = {
  0: "Debit card",
  1: "Contactless payment",
  2: "Wednesday",
  3: "Cash",
  4: "Bill",
  5: "Credit card",
  6: "Mobile payment",
  7: "App payment",
}

export const companyRoleStatuses = {
  1: "Basic user",
  10: "Company user",
  100: "Company admin",
}

// Vehicle/Car

/* using enums API
export const vehicleTypes = [
  { key:0, value:"Passanger car"},
  { key:1, value:"Wagon"},
  { key:2, value:"Minivan"},
]
*/

export const vehiclePassengers = [
  { key:0, value:'Tavarakuljetus'},
  { key:1, value:'1'},
  { key:2, value:'2'},
  { key:3, value:'3'},
  { key:4, value:'4'},
  { key:5, value:'5'},
  { key:6, value:'6'},
  { key:7, value:'7'},
  { key:8, value:'8'},
  { key:9, value:'9'},
  { key:10, value:'10'},
  { key:11, value:'11'},
  { key:12, value:'12'},
  { key:13, value:'13'},
  { key:14, value:'14'},
  { key:15, value:'15'},
  { key:16, value:'16'},
  { key:17, value:'17'},
  { key:18, value:'18'},
  { key:19, value:'19'},
  { key:20, value:'20'},
  { key:21, value:'21'},
  { key:22, value:'22'},
  { key:23, value:'23'},
  { key:24, value:'24'},
  { key:25, value:'25'},
  { key:26, value:'26'},
  { key:27, value:'27'},
  { key:28, value:'28'},
  { key:29, value:'29'},
  { key:30, value:'30'},
  { key:31, value:'31'},
  { key:32, value:'32'},
  { key:33, value:'33'},
  { key:34, value:'34'},
  { key:35, value:'35'},
  { key:36, value:'36'},
  { key:37, value:'37'},
  { key:38, value:'38'},
  { key:39, value:'39'},
  { key:40, value:'40'},
  { key:41, value:'41'},
  { key:42, value:'42'},
  { key:43, value:'43'},
  { key:44, value:'44'},
  { key:45, value:'45'},
  { key:46, value:'46'},
  { key:47, value:'47'},
  { key:48, value:'48'},
  { key:49, value:'49'},
  { key:50, value:'50'},
  { key:51, value:'51'},
  { key:52, value:'52'},
  { key:53, value:'53'},
  { key:54, value:'54'},
  { key:55, value:'55'},
  { key:56, value:'56'},
  { key:57, value:'57'},
]

/* using enums API
export const vehicleColors = [
  { key:'000000', value:'Musta'},
  { key:'FFFFFF', value:'Valkoinen'},
  { key:'FF0000', value:'Punainen'},
  { key:'0026FF', value:'Sininen'},
  { key:'00FF21', value:'Vihreä'},
  { key:'FFD800', value:'Keltainen'},
]
*/

/* using enums API
export const vehicleFuelTypes = [
  { key:0, value:"Diesel"},
  { key:1, value:"Gasoline"},
  { key:2, value:"Natural gas"},
  { key:3, value:"Electric"},
  { key:4, value:"Hybrid"},
]
*/

/* using enums API
export const vehicleTrunkTypes = [
  { key:0, value:"1 suitcase"},
  { key:1, value:"2 suitcases"},
  { key:2, value:"3 suitcases"},
  { key:3, value:"4 or more suitcases"},
]
*/

// User

export const userTypes = {
  0: "Buyer",
  1: "Driver",
  2: "Company admin",
}