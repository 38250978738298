import React, { Fragment, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    marginBottom: '12px',
  },
  inputStyles: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
  },
  multiText: {
    paddingTop: "0px !important",
  },
  label: {
    transform: "translate(14px, 12px) scale(1)",
  }
}));

const MyTextField = (props) =>
{
  const { customLabel, inputProps,...rest } = props;
  const classes = useStyles();
  
  return (
    <Fragment>
      { customLabel ? (
        <Typography variant="subtitle1">{customLabel == " " ? <span>&nbsp;</span> : customLabel}</Typography>
      ) : null}
      
      <TextField
        className={classes.textFieldRoot}
        fullWidth
        inputProps={{
          className: classes.inputStyles,
          ...inputProps
        }}
        InputProps={{
          classes:{multiline: classes.multiText }
        }}
        shrink="true"
        variant="outlined"
        {...rest}
      />
      
    </Fragment>
  )
}

export default MyTextField