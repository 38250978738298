import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const SpinnerCentered = (props) => {
  
  return (
  
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    
    </Grid>
  
  )
}

export default SpinnerCentered;