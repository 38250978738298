import React, { Fragment, useEffect, useState, useRef } from 'react';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import { fitBounds } from "google-map-react/utils";
import GoogleMap from "google-map-react";
import MapPin from "~/components/MapPin";

import OpenTaxiLogo from '~/assets/logo.png'
import { getVehicles } from '~/services/http/vehicles'
import { httpGetOrdersAnalytics } from '~/services/http/orders'
import MyToggleButton from "components/Inputs/MyToggleButton"

import useStyles from './Map.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
/*
<AnyReactComponent
  lat={70.302004}
  lng={15.588492}
  text="My Marker A"
/>,
*/

const Map = (props) => {
  const { updateIndex, myCompany, myUser } = props;
  const { t } = useTranslation();
  const smallScreen = useMediaQuery('(max-width:960px)');
  const classes = useStyles();
  
  const [filters, setFilters] = useState({
    active: true,
  });
  const [counts, setCounts] = useState({
    all: "",
    active: "",
  });
  const [updated, setUpdated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState();
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setFilters({
      ...filters,
      ...updated_values
    });
  }
  
  const handleFormChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  // Maps
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [mapView, setMapView] = useState({ center: null, zoom: null });
  const [mapMarkers, setMapMarkers] = useState([
    /*
    <MapPin
        lat={65.741948} // Kemi
        lng={24.567019}
        key="1"
        size={40}
        weight={3}
        title={null}
      />,
    */
  ]);
  const [mapMarkersOfficial, setMapMarkersOfficial] = useState([]);
  const mapViewRef = useRef(null);
  
  
  const createMapOptions = maps => {
    return {
      mapTypeControl: false,
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      fullscreenControl: false,
      maxZoom:11,
      minZoom:5
    };
  };
  
  const initMap = () => {
    const mapSize = {
      height: mapViewRef.current.clientHeight,
      width: mapViewRef.current.clientWidth
    };
    
    // const bounds = fitBounds(getMapBounds(), mapSize);
    
    // Finland
    const bounds = {
      nw: { lat: 70.302004, lng: 15.588492 },
      se: { lat: 60.748082, lng: 31.750109 }
    };
    
    setMapView(bounds);
  }
  
  const handleMapPinClick = (key, childProps) => {
    console.log("handleMapPinClick",key);
  };
  
  const handleMapChange = ({ zoom, bounds }) => {
    setZoom(zoom);
    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat
    ]);
  }
  
  useEffect(() => {
    initMap();
    handleGetOrdersAnalytics();
  }, [])
  
  useEffect(() => {
    handleGetVehicles();
  }, [filters, updateIndex]) // zoom, bounds
  
  useEffect(() => {
    const interval = setInterval(() => {
      handleGetVehicles();
    }, 30000);
    
    return () => {
      clearInterval(interval);
    };
  }, [filters]);
  
  const formatDateTime = (dt) => {
    if (!dt) return "";
    return moment(dt).format('DD.MM.YYYY HH:mm:ss')
  }
  
  
  const handleGetVehicles = () => {
    
    let params = {
      ...filters,
      // all_cars: 1,
    }
    
    if (myUser && myUser.is_staff === false)
    {
      params.company__id = myCompany?.id
    }
    
    /*
    if (zoom < 10)
    {
      params.grouped = true;
    }
    */
    
    let request = {
      params: params
    }
    
    setLoading(true);
    getVehicles(request).then((response) => {
      
      if (filters?.active === true)
      {
        setCounts({
          ...counts,
          active: response.length,
        });
      }
      else
      {
        setCounts({
          ...counts,
          all: response.length,
        });
      }
      
      setUpdated(moment());
      
      let vehicles = [];
      response.map((vehicle) => {
        
        if (vehicle?.coordinates) {
          vehicles.push(
            <MapPin
              key={vehicle?.id}
              lat={vehicle?.coordinates?.coordinates[0]}
              lng={vehicle?.coordinates?.coordinates[1]}
              size={20}
              weight={3}
              title={vehicle?.license_number + " / " + vehicle?.company?.name}
              backgroundColor={vehicle?.color === "#FFFFFF" ? "#6A31DC" : "#FFF"}
              color={vehicle?.color}
              borderColor={vehicle?.color}
              opacity={1}
              pinContent={vehicle?.license_number ? vehicle?.license_number : ""}
            />
          )
        }
          
      })
      
      setMapMarkers(vehicles);
      setLoading(false);
      
    }, (errors) => {
      setLoading(false);
    });
  }
  
  const handleGetOrdersAnalytics = () => {
    httpGetOrdersAnalytics().then(response => {
      setAnalytics(response);
    })
  }
  
  return (
    <Fragment>
    
    <div style={{position:'relative'}}>
      <div className={classes.topLeftBoxFixed}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="top"
        >
          <Grid item>
            <img src={OpenTaxiLogo} style={{width: '256px'}} />
              {/*<Typography variant="subtitle2">OpenTaxi</Typography>*/}
          </Grid>
          <Grid item>
          {/* {key:0, label: t("Kaikki"), value: null},  */}
            <MyToggleButton name="active" value={filters.active} onChange={handleFormChange} buttons={[{key:0, label: myUser?.is_staff ? t("Kaikki") : t("Kaikki omat"), value: null}, {key:1, label: `${myUser?.is_staff ? t("Aktiiviset autot") : t("Omat aktiiviset autot")} (${counts?.active})`, value: true}]} />
            <div>
              <Typography>{formatDateTime(updated)}</Typography>
              <Typography>{t('Suoritettuja tilauksia')}: {analytics?.orders_complete}</Typography>
              { loading && <SpinnerCentered /> }
            </div>
          </Grid>
        </Grid>
      </div>
      
      <div ref={mapViewRef} style={{width: '100%', height: '100vh'}}>
      
        <GoogleMap
            options={createMapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyA2_4q2PAhogZ0qqRFvsNHQlOrZe_fGAf4",
              v: "3.31"
            }}
            defaultCenter={{ lat: 65.162, lng: 23.947 }}
            center={mapView.center}
            defaultZoom={5}
            zoom={mapView.zoom}
            onChildClick={handleMapPinClick}
            /*
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat
              ]);
            }}
            */
            onChange={handleMapChange}
          >
            {mapMarkers}
            {mapMarkersOfficial}
          </GoogleMap>
      </div>
    </div>
    
    </Fragment>
  )
  

}

export default Map;