import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Map from './Map'

const mapStateToProps = state => ({
  myCompany: state.companies?.data?.me,
  myUser: state.users?.data?.me,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
    
  }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Map);
