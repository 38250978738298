import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import withAuth from 'services/withAuth';

import Dashboard from 'views/Dashboard';
import Home from 'views/Home';
import Auth from 'views/Auth';
import PasswordResetToken from 'views/PasswordResetToken';
import Taxi from 'views/Taxi';
import Legal from 'views/Legal';
import MapView from 'views/MapView';

const Router = (props) => {
  
  return (
    <SnackbarProvider>
      <BrowserRouter>
        <Switch>
          {/*<Route path="/" exact component={Home} /> */}
          <Route path="/taxi" component={Taxi} />
          <Route path="/legal" component={Legal} />
          <Route path="/auth" component={Auth} />
          <Route path="/reset" component={PasswordResetToken} />
          <Route path="/dashboard" component={withAuth(Dashboard)} />
          <Route path="/map" component={MapView} />
          <Route path='/api/admin' component={() => { 
              window.location.href = '/api/admin'; 
              return null;
          }}/>
          <Redirect path="/" exact to="/dashboard" />
          <Redirect path="/register" exact to="/auth/register" />
          <Redirect path="/register/user" exact to="/auth/register/user" />
          <Redirect path="/login" exact to="/auth/login" />
          <Redirect to="/login" />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default Router;