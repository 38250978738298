import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import MySelectField from "components/Inputs/MySelectField"

const useStyles = makeStyles(theme => ({
  
}));

const VehicleColorSelect = (props) =>
{
  const { value, updateValues, vehicleColors } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const handleSelectChange = ({ target }) => {
    updateValues({ [target.name]: target.value })
  }
  
  return (
    <Fragment>
    { vehicleColors && vehicleColors.length > 0 ? (
      <MySelectField
        id="select_order_type"
        name="color"
        value={value ? value : '#151715'}
        onChange={handleSelectChange}
      >
        {vehicleColors.map(option => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </MySelectField>
    ) : null }
    </Fragment>
  )
}

export default VehicleColorSelect;
