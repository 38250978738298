import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './MapView.css';
import OpenTaxiLogo from '~/assets/logo.png'

import Map from '~/components/Map'

import AuthService from 'services/http/AuthService'
const auth = new AuthService();

const MapView = ({ history, myUser, getMyCompany, getUserMe }) => {
  
  const classes = styles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  
  const hasPermission = useCallback(() => {
    return myUser && (myUser?.is_staff === true || myUser?.status === 100)
  }, [myUser])
  
  useEffect(() => {
    setLoading(true)
    getMyCompany().then((response) => {
      getUserMe(response?.id).then(() => {
        
        setLoading(false)

      }, (errors) => {
        setLoading(false)
        handleLogout();
      })
    }, (errors) => {
      setLoading(false)
      handleLogout();
    })
    
  },[])
  
  const handleLogout = () => {
    // Redirect to login
    auth.logout();
    history.push("/login");
  };
  
  return (
    <Fragment>
      {/*
        <div style={{zIndex: 1001, padding: '32px', position: 'absolute', left:'0px',top:'0px', }}>
          <img src={OpenTaxiLogo} style={{width: '256px'}} />
        </div>
      */}
      { hasPermission() && (
        <Map />
      )}
      
    </Fragment>
  )
}

export default MapView;