import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, NavLink, Route, Switch, Redirect, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';


// MUI icons
import Badge from '@material-ui/core/Badge';
import Business from '@material-ui/icons/Business';
import AirlineSeatReclineExtra from '@material-ui/icons/AirlineSeatReclineExtra';
import DriveEta from '@material-ui/icons/DriveEta';
import Room from '@material-ui/icons/Room';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Tune from '@material-ui/icons/Tune';
import ViewCompact from '@material-ui/icons/ViewCompact';
import DescriptionIcon from '@material-ui/icons/Description';
import MapIcon from '@material-ui/icons/Map';

import logo from 'assets/logo.png'
import Start from 'views/Start';
import UsersList from 'views/UsersList';
import UserInviteView from 'views/UserInviteView';
import DriversList from 'views/DriversList';
import DriverEdit from 'views/DriverEdit';
import CompanyDetail from 'views/CompanyDetail';
import CompanyEdit from 'views/CompanyEdit';
import CompanyPayment from 'views/CompanyPayment';
import VehiclesList from 'views/VehiclesList';
import VehicleEdit from 'views/VehicleEdit';
import VehicleView from 'views/VehicleView';
import PricesList from 'views/PricesList';
import OrdersList from 'views/OrdersList';
import OrderDetailView from 'views/OrderDetailView';
import Service from 'views/Service';
import Settings from 'views/Settings';
import Support from 'views/Support';
import Prices from 'views/Legal/Prices';
// import MapView from 'views/MapView'
// import Map from 'components/Map'
import SpinnerCentered from 'components/Loader/SpinnerCentered';

import styles from './Dashboard.css';
import AuthService from 'services/http/AuthService'
const auth = new AuthService();


const Dashboard = (props) => {
  const { t } = useTranslation();
  const classes = styles();
  
  const { history, match, location, users, getMyAnalytics, myAnalytics, myCompany, getMyCompany, getUserMe, myUser } = props;
  
  // hooks
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [codesListOpen, setCodesListOpen] = useState(true);
  const [navListOpen, setNavListOpen] = useState({
    company:false,
    drivers:false,
    cars:false,
    orders:false,
  })
  
  useEffect(() => {
    setLoading(true)
    getMyCompany().then((response) => {
      getUserMe(response?.id).then(() => {
        
        setLoading(false)
        setLoaded(true);
      }, (errors) => {
        setLoading(false)
        setLoaded(true);
      })
    }, (errors) => {
      setLoading(false)
        setLoaded(true);
    })
    
  },[])
  
  useEffect(() => {
    if (myCompany)
    {
      getMyAnalytics().then((response) => {
        
      });
    }
    
  },[myCompany])
  
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleNavListOpen = (name) => {
    setNavListOpen({
      ...navListOpen,
      [name]: !navListOpen[name]
    })
  }
  
  const logout = () =>
  {
    auth.logout();
    //history.push("/");
    window.open('https://www.opentaxi.fi/', '_self')
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Grid container
            direction="row"
            justify="space-between"
            alignItems="center">
          <Grid item className={classes.animatedImageContainer}>
            <Slide direction="left" in={true} timeout={500}>
              <img src={logo} alt="logo" className={classes.logo}/>
            </Slide>
          </Grid>
          <Grid item>
            <Button component={NavLink} onClick={() => logout()}>
              <Typography variant="subtitle2">{t('Kirjaudu ulos')}</Typography>
            </Button>
            
          </Grid>
        </Grid>
      </AppBar>
      
      <Drawer
        open={open}
        className={open ? classes.drawerOpen : classes.drawerClose}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
        
          <ListItem button to='/dashboard/start' component={NavLink} activeClassName={classes.listNavActive}>
            <ListItemIcon><ViewCompact className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Etusivu')}</Typography>
            </ListItemText>
          </ListItem>
          
          <Badge classes={{anchorOriginTopRightRectangle: classes.badgeMainListItem}} badgeContent={myUser?.status == 100 && loading == false && (myCompany?.valid == false || !myCompany?.stripe_user_id || !myCompany?.seen_price_list) ? "!" : null} color="error">
          <ListItem button onClick={() => toggleNavListOpen("company")}>
            <ListItemIcon><Business className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Yritys')}</Typography>
            </ListItemText>
          </ListItem>
          </Badge>

          <Collapse in={navListOpen.company} timeout="auto" unmountOnExit>
            <List>
              
              { myUser?.status == 100 ? (
              <Badge classes={{anchorOriginTopRightRectangle: classes.badgeListItem}} badgeContent={myUser?.status == 100 && loading == false && myCompany?.valid == false ? "!" : null} color="error">
              <ListItem button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/company/edit' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Perustiedot')}
                  </Typography>
                </ListItemText>
              </ListItem>
              </Badge>
              ) : (
              <ListItem button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/company/detail' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Perustiedot')}
                  </Typography>
                </ListItemText>
              </ListItem>
              )}
              
              { myUser?.status == 100 ? (
              <ListItem disabled={myUser?.status < 100} button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/users' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Käyttäjät')}</Typography>
                </ListItemText>
              </ListItem>
              ) : null }
              
              { myUser?.status == 100 ? (
              <Badge classes={{anchorOriginTopRightRectangle: classes.badgeListItem}} badgeContent={myUser?.status == 100 && loading == false && !myCompany?.stripe_user_id ? "!" : null} color="error">
              <ListItem disabled={myUser?.status < 100} button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/company/payment' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                
                <ListItemText inset style={{padding:0}}>
                    <Typography className={classes.listItemTypography}>{t('Maksutiedot')}</Typography>
                </ListItemText>
                
              </ListItem>
              </Badge>
              ) : null }
              
              { myUser?.status == 100 ? (
              <Badge classes={{anchorOriginTopRightRectangle: classes.badgeListItem}} badgeContent={myUser?.status == 100 && loading == false && !myCompany?.seen_price_list ? "!" : null } color="error">
              <ListItem disabled={false} button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/prices/list' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                
                <ListItemText inset style={{padding:0}}>
                    <Typography className={classes.listItemTypography}>{t('Hinnasto')}</Typography>
                </ListItemText>
              
              </ListItem>
              </Badge>
              ) : null }
              
              {/*
              <ListItem disabled={true} button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/company/settings' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Asetukset')}</Typography>
                </ListItemText>
              </ListItem>
              */}
              
            </List>
          </Collapse>
          
          <Badge classes={{anchorOriginTopRightRectangle: classes.badgeMainListItem}} badgeContent={myUser?.status == 100 && loading == false && myAnalytics?.drivers == 0 ? "!" : null} color="error">
          <ListItem button onClick={() => toggleNavListOpen("drivers")}>
            <ListItemIcon><AirlineSeatReclineExtra className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Kuljettajat')}</Typography>
            </ListItemText>
          </ListItem>
          </Badge>

          <Collapse in={navListOpen.drivers} timeout="auto" unmountOnExit>
            <List>
              
              <ListItem button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/drivers/list' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Kaikki')}</Typography>
                </ListItemText>
              </ListItem>
              
              { myUser?.status == 100 ? (
              <Badge classes={{anchorOriginTopRightRectangle: classes.badgeListItem}} badgeContent={myUser?.status == 100 && loading == false && myAnalytics?.drivers == 0 ? "!" : null} color="error">
              <ListItem button disabled={myUser?.status < 100} className={`${classes.listItem} ${classes.nested}`} to='/dashboard/drivers/new' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Lisää uusi')}</Typography>
                </ListItemText>
              </ListItem>
              </Badge>
              ) : null }
              
            </List>
          </Collapse>
          
          <Badge classes={{anchorOriginTopRightRectangle: classes.badgeMainListItem}} badgeContent={myUser?.status == 100 && loading == false && myAnalytics?.vehicles == 0 ? "!" : null} color="error">
          <ListItem button onClick={() => toggleNavListOpen("cars")}>
            <ListItemIcon><DriveEta className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Autot')}</Typography>
            </ListItemText>
          </ListItem>
          </Badge>

          <Collapse in={navListOpen.cars} timeout="auto" unmountOnExit>
            <List>
              
              <ListItem button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/vehicles/list' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Kaikki')}</Typography>
                </ListItemText>
              </ListItem>
              
              { myUser?.status == 100 ? (
              <Badge classes={{anchorOriginTopRightRectangle: classes.badgeListItem}} badgeContent={loading == false && myAnalytics?.vehicles == 0 ? "!" : null} color="error">
              <ListItem button disabled={myUser?.status < 100} className={`${classes.listItem} ${classes.nested}`} to='/dashboard/vehicles/new' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Lisää uusi')}</Typography>
                </ListItemText>
              </ListItem>
              </Badge>
              ) : null }
              
              { (myUser?.status == 100 || myUser?.is_staff === true) && (
              <ListItem button to='/map' target='_blank' component={Link} className={`${classes.listItem} ${classes.nested}`} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><MapIcon className={classes.listMainIcon} /></ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listItemTypography}>{t('Kartta')}</Typography>
                </ListItemText>
              </ListItem>
              )}
              
            </List>
          </Collapse>
      
		  { myUser?.status == 100 ? (
          <ListItem button onClick={() => toggleNavListOpen("orders")}>
            <ListItemIcon><Room className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Ajetut ajot')}</Typography>
            </ListItemText>
          </ListItem>
          ): null }
          <Collapse in={navListOpen.orders} timeout="auto" unmountOnExit>
            <List>
              
              <ListItem button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/orders/list' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Kaikki')}</Typography>
                </ListItemText>
              </ListItem>
              
              {/*
              <ListItem disabled={true} button className={`${classes.listItem} ${classes.nested}`} to='/dashboard/orders/new' component={NavLink} activeClassName={classes.listNavActive}>
                <ListItemIcon className={classes.listIconContainer}><FiberManualRecord className={classes.listIcon} /></ListItemIcon>
                <ListItemText inset style={{padding:0}}>
                  <Typography className={classes.listItemTypography}>{t('Lisää uusi')}</Typography>
                </ListItemText>
              </ListItem>
              */}
              
            </List>
          </Collapse>
          
          <Divider />
          
          <ListItem button to='/dashboard/settings' component={NavLink} activeClassName={classes.listNavActive}>
            <ListItemIcon><Tune className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Asetukset')}</Typography>
            </ListItemText>
          </ListItem>
          
          <ListItem button to='/dashboard/support' component={NavLink} activeClassName={classes.listNavActive}>
            <ListItemIcon><ContactSupport className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Tuki')}</Typography>
            </ListItemText>
          </ListItem>
          
          <ListItem button to='/dashboard/service' component={NavLink} activeClassName={classes.listNavActive}>
            <ListItemIcon><DescriptionIcon className={classes.listMainIcon} /></ListItemIcon>
            <ListItemText>
              <Typography className={classes.listItemTypography}>{t('Palvelusopimukset ja tietosuoja')}</Typography>
            </ListItemText>
          </ListItem>
          
          
          

        </List>
        
        <Divider />
      
      </Drawer>
      
      <main className={classes.content}>
        { loaded ? (
        <Switch>
          <Route path="/dashboard/start" exact component={Start} />
          <Route path="/dashboard/company/detail" exact component={CompanyDetail} />
          <Route path="/dashboard/company/edit" exact component={CompanyEdit} />
          <Route path="/dashboard/company/payment" exact component={CompanyPayment} />
          <Route path="/dashboard/users" exact component={UsersList} />
          <Route path="/dashboard/users/invite" exact component={UserInviteView} />
          <Route path="/dashboard/drivers/list" exact component={DriversList} />
          <Route key="driver_new" path="/dashboard/drivers/new" exact component={DriverEdit} />
          <Route key="driver_edit" path="/dashboard/drivers/:driverId/edit" exact component={DriverEdit} />
          <Route path="/dashboard/vehicles/list" exact component={VehiclesList} />
          <Route key="vehicle_new" path="/dashboard/vehicles/new" exact component={VehicleEdit} />
          <Route key="vehicle_edit" path="/dashboard/vehicles/:vehicleId/edit" exact component={VehicleEdit} />
          <Route key="vehicle_view" path="/dashboard/vehicles/:vehicleId/view" exact component={VehicleView} />
          <Route key="prices_list" path="/dashboard/prices/list" exact component={PricesList} />
          {/*
          <Route key="prices_new" path="/dashboard/prices/new" exact component={VehicleEdit} />
          <Route key="prices_edit" path="/dashboard/prices/:price/edit" exact component={VehicleEdit} />
          <Route path="/dashboard/map" exact component={Map} />
          */}
          <Route path="/dashboard/orders/list" exact component={OrdersList} />
          <Route path="/dashboard/orders/:orderId/detail" exact component={OrderDetailView} />
          <Route path="/dashboard/service" exact component={Service} />
          <Route path="/dashboard/service/prices" exact component={Prices} />
          <Route path="/dashboard/settings" exact component={Settings} />
          <Route path="/dashboard/support" exact component={Support} />
          
          <Redirect path="/dashboard/drivers" exact to="/dashboard/drivers/list" />
          <Redirect path="/dashboard/vehicles" exact to="/dashboard/vehicles/list" />
          <Redirect to="/dashboard/start" />
        </Switch>
        ) : null }
          

      </main>
    
    
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Dashboard;