import React, { Fragment, useState, useEffect } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import ButtonDefault from 'components/Buttons/ButtonDefault';

import { getVehicle } from 'services/http/vehicles'
import SpinnerCentered from '~/components/Loader/SpinnerCentered';
import VehicleDetails from 'components/VehicleDetails';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 48,
  },
  title: {
    paddingBottom: 32,
  },
}))

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const VehicleView = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  
  const { history, match, myCompany, myUser, ...rest } = props;
  const { vehicleId } = match.params;

  const [values, setValues] = useState({
    animals: false,
    rollator: false,
    wheelchair: false,
    taped: false,
  });
  
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (vehicleId && vehicleId >= 0)
    {
      handleGetVehicle();
    }
  }, [vehicleId]);
  
  const handleGetVehicle = () => {
    setLoading(true);
    const request = {
      data: {
        id: vehicleId
      }
    };
    
    getVehicle(request).then((response) => {
      setValues(response);
      setLoading(false);
    }, (errors) => {
      setLoading(false);
    });
    
  }
  
  return (
    <Paper className={classes.content} elevation={0} square>
      <Box marginBottom="32px">
        { loading ? (
          <SpinnerCentered />
        ) : (
          <VehicleDetails vehicle={values} />
        )}
      </Box>
      
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <ButtonDefault component={Link} to={"/dashboard/vehicles"}>
          {t('Takaisin')}
          </ButtonDefault>
        </Grid>
      </Grid>
    </Paper>
    
    
  )
}

export default withSnackbar(VehicleView);