import { actionTypes } from "redux-resource";
import moment from "moment";
import { httpGetMyCompany } from "~/services/http/companies";
const resourceType = "companies";

const validateCompany = (company) => {
  if (!company)
  {
    return false
  }
  else if (!company?.name || company?.name.length == 0)
  {
    return false
  }
  else if (!company?.business_id || company?.business_id.length == 0)
  {
    return false
  }
  else if (!company?.main_city || !company?.main_city?.id || company?.main_city?.id < 0)
  {
    return false
  }
  else if (company?.checked == false)
  {
    return false
  }
  
  
  return true
}

export const getMyCompany = () => {
  
  const requestKey = "getMyCompany";

  return dispatch => {
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey
    });

    return httpGetMyCompany()
      .then(data => {
        dispatch({
          data: {
            me: {
              ...data,
              valid: validateCompany(data),
            }
          },
          mergeListIds: false,
          resourceType,
          requestKey,
          resources: [],
          type: actionTypes.READ_RESOURCES_SUCCEEDED
        });

        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: error.status
          }
        });
        return Promise.reject(error);
      });
  };
};
