import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import withAnalytics from 'components/Analytics/withAnalytics'
import Start from './Start'

const mapStateToProps = state => ({
  myAnalytics: state.analytics.data?.me,
  myCompany: state.companies?.data?.me,
  users: state.users,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withAnalytics(Start))
)
