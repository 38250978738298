import { actionTypes } from "redux-resource";
import moment from "moment";
import { httpGetUserMe } from "~/services/http/users";
const resourceType = "users";

export const getUserMe = (company_id) => {
  const requestKey = "getUserMe";

  return dispatch => {
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey
    });

    return httpGetUserMe(company_id)
      .then(data => {
        dispatch({
          data: {
            me: {
              ...data
            }
          },
          mergeListIds: false,
          resourceType,
          requestKey,
          resources: [],
          type: actionTypes.READ_RESOURCES_SUCCEEDED
        });

        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: error.status
          }
        });
        return Promise.reject(error);
      });
  };
};
