import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import styles from './kpiCard.css.js';
  
const KpiCard = (props) => {
  // Declare a new state variable, which we'll call "count"
  // props
  const classes = styles();
  const [value, setValue] = React.useState(0);
  const [valueString, setValueString] = React.useState("")

  const animateValue = (start, end) => {
    let current = start;
    let timer = setInterval(() => {
      if(end - current > 100000) {
        current += 11111;
      }
      else if(end - current > 10000) {
        current += 1111;
      }
      else if(end - current > 1000) {
        current += 111;
      }
      else if(end - current > 100) {
        current += 11;
      }
      else if (end > current) {
        current += 1;
      }
      if(current === end) {
        clearInterval(timer);
      }
      setValue(current);
    }, 1)
  };

  useEffect(() => {

    const element = document.getElementById('kpiCounterStart');
    const elementHeight = element.clientHeight;

    function inView() {
      // get window height
      const windowHeight = window.innerHeight;
      // get number of pixels that the document is scrolled
      const scrollY = window.scrollY || window.pageYOffset;
    
      // get current scroll position (distance from the top of the page to the bottom of the current viewport)
      const scrollPosition = scrollY + windowHeight;
      // get element position (distance from the top of the page to the bottom of the element)
      const elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight/3;
      // is scroll position greater than element position? (is element in view?)
      if (scrollPosition > elementPosition) {
        return true;
      }
        return false;
    }

    if(inView() && value === 0)  {
      animateValue(0, props.value);
    }
    else{
      document.addEventListener('scroll', animate);


      function animate() {
        // is element in view?
        if (inView() && value === 0) {
          // element is in view, add class to element
          animateValue(0, props.value)
          document.removeEventListener('scroll', animate);
        }
      }
    }

  }, []);

  useEffect(() => {
    let valueString = value.toString();
    let output = "";

    if(valueString.length > 3) {
      let chars = valueString.split('');
      chars.splice((valueString.length - 3), 0, ',');
      output = chars.join('');
      setValueString(output);
    }
    else {
      setValueString(valueString);
    }
  }, [value]);
  

  return (
    <>
      <Typography className={classes.value}>
        {valueString}
      </Typography>
      <Typography className={classes.title}>
        {props.title}
      </Typography>
    </>
  )
}

export default KpiCard;