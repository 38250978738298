import React, { Fragment, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles(theme => ({
  toggleContainer: {
    margin: theme.spacing(1, 0),
  },
  toggleButtonGroup: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    },
  },
  toggleButton: {
    textTransform: 'none',
    minWidth: "64px",
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
      padding: 0,
      fontSize: "12px",
    },
  },
  toggleSelected: {
    color: theme.palette.primary.contrastText + " !important",
    backgroundColor: theme.palette.primary.main + " !important",
  },
}));

const MyToggleButton = (props) =>
{
  const { name, value, onChange, buttons, disabled, ...rest } = props;
  //const smallScreen = useMediaQuery('(max-width:960px)');
  
  const classes = useStyles(props);
  
  const handleChange = (event, newValue) => {
    onChange({target: {name: name, value: newValue}});
  };
  
  //useEffect(() => {
  //  console.log("smallScreen",smallScreen);
  //}, [smallScreen])
  
  return (
    <Fragment>
      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
        >
        { buttons && buttons.map ((btn, index) => (
          <ToggleButton key={index} value={btn.value} disabled={disabled || value == btn.value} classes={{selected:classes.toggleSelected, disabled:!disabled ? classes.toggleSelected : null}} className={classes.toggleButton}>
            {btn.label}
          </ToggleButton>
        ))}
        </ToggleButtonGroup>
      </div>
      
    </Fragment>
  )
}

export default MyToggleButton