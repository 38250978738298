import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Support from './Support'

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Support)
)
