import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';

const withAnalytics = Component => {
  
  const Analytics = (props) => {
    
    const [analytics, setAnalytics] = useState({});
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
    const [analyticsError, setAnalyticsError] = useState(false);

    useEffect(() => {
      axios({
        method: 'get',
        baseURL: 'https://app.opentaxi.fi/api/',
        url: '/analytics/',
        headers: { 'content-type': 'application/json' },
      })
      .then(response => {
        setAnalytics(response.data);
        setAnalyticsLoaded(true);

      })
      .catch(error => {
        setAnalyticsError(true);
        setAnalyticsLoaded(true);
      });
    }, [])
    
    return (
        <Component {...props}
        analytics={analytics}
        />
      );
  }
  Analytics.displayName = 'Analytics'
  return Analytics;
}

withAnalytics.defaultProps = {

}

withAnalytics.propTypes = {

};

export default withAnalytics;

