import React, { Fragment, useState, useEffect } from 'react';
import queryString from 'query-string'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

import SpinnerCentered from 'components/Loader/SpinnerCentered';
import { saveCompany } from 'services/http/companies';
import CompanyForm from 'components/CompanyForm/CompanyForm';
import CompanyAreaForm from 'components/CompanyAreaForm/CompanyAreaForm';

import { baseURL } from 'services/http/httpInstance'
const useStyles = makeStyles(theme => ({
  content: {
    padding: 48,
  },
  title: {
    paddingBottom: 32,
  },
  

}))

const CompanyEdit = (props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, users, getMyCompany, ...rest } = props;
  
  
  const [values, setValues] = useState({
    receiverLanguage: null,
    type: 1,
    send_phone: false,
    send_email: false,
  });
  const [stripeConnectUrl, setStripeConnectUrl] = useState("");
  const [loadingStripe, setLoadingStripe] = useState(false);
  
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  
  useEffect(() => {
    handleGetMyCompany();
  },[])
  
  const handleGetMyCompany = () => {
    setLoading(true);
    getMyCompany().then((response) => {
      setValues(formatValues(response));
      
      setLoading(false);
    }, (errors) => {
      setLoading(false);
    })
  }
  
  const formatValues = (_values) => {
    let secondary_cities = []
    _values.secondary_cities.map((city) => {
      secondary_cities.push({
        value: city.id,
        label: city.name,
      })
    })
    
    let kela_areas = []
    _values.kela_areas.map((kela_area) => {
      kela_areas.push({
        value: kela_area.id,
        label: kela_area.name,
      })
    })
    
    return {
      ..._values,
      secondary_cities: secondary_cities,
      kela_areas: kela_areas,
      
    }
  }
  
  
  const updateValues = (updated_values) =>  {
    
    if (updated_values == null)
    {
      return;
    }
    
    setValues({
      ...values,
      ...updated_values
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    setSaving(true);
    
    let secondary_cities = []
    if (values.secondary_cities)
    {
      values.secondary_cities.map((city) => {
        secondary_cities.push({
          id: city.value
        })
      })
    }
    
    let kela_areas = []
    if (values.kela_areas)
    {
      values.kela_areas.map((kela_area) => {
        kela_areas.push({
          id: kela_area.value
        })
      })
    }
      
    const request = {
      data: {
        id: values.id,
        name: values.name,
        business_id: values.business_id,
        contact_email: values.contact_email,
        contact_phone: values.contact_phone,
        main_city: values.main_city && values?.main_city?.id > -1 ? values.main_city : null,
        secondary_cities: secondary_cities,
        kela_areas: kela_areas,
      }
    };
    
    saveCompany(request).then((response) => {
      // setValues(formatValues(response));
      handleGetMyCompany();
      
      props.enqueueSnackbar('Tallennettu', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      setSaving(false);
      
    }, (errors) => {
      props.enqueueSnackbar('Tallennus epäonnistui', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 2000,
      })
      setSaving(false);
      
    });

  }
  
  const handleErrors = (errors) => {
    
  }
  
  const handleCancel = () => {
    history.push("/dashboard/start");
  }

  return (
    <Paper className={classes.content} elevation={0} square>

        {/*
        onError={errors => console.log(errors)}
        */}
        <ValidatorForm onSubmit={handleSubmit} onError={handleErrors}>
          
          {loading ? (
            <SpinnerCentered />
          ) : (
            <Fragment>
              
              <Typography variant="h4">
                {t('Yrityksen perustiedot')}
              </Typography>
              
              <CompanyForm values={values} updateValues={updateValues} />
              
              <Typography variant="h4">
              {t('Yrityksen toiminta-alueet')}
              </Typography>
              
              <Box marginBottom="32px">
                <CompanyAreaForm values={values} updateValues={updateValues} />
              </Box>
              
              { !values?.checked ? (
              <Box marginBottom="32px">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <ErrorIcon color="error" />
                  </Grid>
                  <Grid item>
                    {t('Yritys odottaa hyväksyntää. Ota yhteys tarvittaessa tukeen.')}
                  </Grid>
                </Grid>
                  
              </Box>) : null}
              
            </Fragment>
          )}
          
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <ButtonDefault onClick={handleCancel}>
                    {t('Peruuta')}
                </ButtonDefault>
              </Grid>
              <Grid item>
              {/* !values?.checked ||  */}
                <ButtonPrimary loading={saving} type="submit" disabled={users?.data?.me?.status < 100}>
                    {t('Tallenna')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>

    </Paper>
    
    
  )
}

export default withSnackbar(CompanyEdit);